import React, {useContext, useEffect, useState} from "react";
import Menu from "../../Components/Menu/Menu";
import Header from "../../Components/Header/Header";
import {FaCarAlt, FaRegUser} from 'react-icons/fa';
// import graficos from "../../assets/graficosTeste.png"
import styles from "../Home/Home.module.css";
import { NavLink } from "react-router-dom";
import axios from "axios";
import ListMotorista from "../../Contexts/ArrayMotoristas";
import { Chart } from "react-google-charts";
import {API_BASE_URL} from '../../services/Constantes';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ComposedChart,
    Area,
    Bar
  } from 'recharts';

const HomeGestor = () => {
const {motoristas, setMotoristas} = useContext(ListMotorista);
const [list, setList] = useState([]);
let criada = 0;
let cancelada = 0;
let arquivada = 0;
let andamento= 0;
let finalizada = 0;
let idGestor = localStorage.getItem("IdUsuario");
const [data, setData] = useState([]);
let vetor = [];

  useEffect(() => {
    window.scrollTo(0, 0);
    fetch(`${API_BASE_URL}/api/viagemAvulsa/gestor/${idGestor}`, {
        method: "GET",
        headers: {
            "Content-type": "application/json;charset=UTF-8",
            'token': localStorage.getItem("token"),
            'perfil': localStorage.getItem("tipo")
        }
        }).then((response) => {
            return response.json();
          })
          .then((data) => {
            setList(data);
            list.map((item, index) => {
              item.status == 'CRIADA' ? criada = criada + 1: 
              item.status == 'FINALIZADA' ? finalizada = finalizada + 1 :
              item.status == 'CANCELADA' ? cancelada = cancelada + 1 :
              item.status == 'EM_ANDAMENTO' ? andamento = andamento + 1 :
              arquivada = arquivada + 1
            });
            vetor.push({name: 'Aguardando', total: criada, percentual: (criada*100/list.length).toFixed(2)});
            vetor.push({name: 'Cancelada', total: cancelada, percentual: (cancelada*100/list.length).toFixed(2)});
            vetor.push({name: 'Em andamento', total: andamento, percentual: (andamento*100/list.length).toFixed(2)});
            vetor.push({name: 'Finalizada', total: finalizada, percentual: (finalizada*100/list.length).toFixed(2)});
            vetor.push({name: 'Arquivada', total: arquivada, percentual: (arquivada*100/list.length).toFixed(2)});
            setData(vetor)
          })
          .catch(() => {});

},[]);









    return (
       <section className={styles.home}>
              <Header titulo="ProGenTran" altura="150px"/>
                 <Menu /> 
            <main className={styles.main}>
            
               <section className={styles.mainContent}>
                
                        {/* <section className={styles.homeMain}>
                            <ul className={styles.homeList}>
                             <div className={styles.homeListaItems}> 
        
                                
                                <li>
                                        <NavLink className={styles.homeListItens} to="/viagem-avulsa">
        
                                                <p className={styles.homeListText}>Cadastro de Viagem</p>
                                            </NavLink>
                                </li>
                            </div>

                            <div className={styles.homeListaItems}> 

                                <li>
                                        <NavLink className={styles.homeListItens} to="/gestor/listarViagem">
                             
                                            
                                                <p className={styles.homeListText}>Listar Viagens</p>
                                            </NavLink>
                                </li>
                            </div>

                            </ul>
                        </section> */}
                      <section className={styles.homeMain}>
                      <h2>Gráfico com detalhes das viagens</h2>
                            <ComposedChart width={830} height={350} data={data}>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <CartesianGrid stroke="#f5f5f5" />
                                <Area type="monotone" dataKey="percentual" fill="#8884d8" stroke="#8884d8" />
                                <Bar dataKey="total" barSize={20} fill="#413ea0" />
                                <Line type="monotone" dataKey="uv" stroke="#ff7300" />
                            </ComposedChart>

                            <div className={styles.blocoGrafico}>
                                <p className={styles.blocoGraficoTitle}>Você possui {list.length} viagens registradas</p>
                            </div>
                      </section>
               </section>
            </main>
       </section>
    );
};


export default  HomeGestor;

