import React from "react";
import styles from "../Loader/Loader2.module.css";


const Loading = () => {
  return (
    <div className={styles.loaderContainer}>
    </div>
  ) 
};

export default Loading;