import React, { useState, useEffect, useContext } from "react";
import styles from "../../pages/Solicitante/ListarSolicitantes.module.css"
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { FormGroup, Form, Input, Row, Col, Button } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Table from "react-bootstrap/Table";
import { IMaskInput } from "react-imask";
import ButtonComp from "../../Components/Button/Button";
import {API_BASE_URL} from '../../services/Constantes';
import Loading from "../../Components/Loader/Loading";
import estilo from "../Solicitante/SetorTabel.module.scss";
import { useNavigate } from "react-router-dom";
import Error from "../../Components/Error/Error";
import EstadoMenu from "../../Components/Menu/EstadoMenu";

const ListarSolicitantes = () => { 

    useEffect(() => {
        document.title = 'Solicitantes';
        setAtivadoMenu(false);
      }, []);

      // Estado que vai guardar todos os solicitantes cadastrados
      const [list, setList] = useState([]);

      const [loading, setLoading] = useState(true);

         // Funcionalidade de Páginação
    const [currentPage, setCurrentPage] = useState(1);

    // Contexto para o estado do Menu
    // Contexto do Estado do Menu
    const { ativadoMenu, setAtivadoMenu } = useContext(EstadoMenu);

    const itemsPerPage = 5;
    const totalItems = list.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;

    // Rota para voltar a tela de Login
    let navigate = useNavigate();
    function handlePage(){
      setLoading(true);
      navigate('/');
       
    }

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

      // useEffect que pega os setores cadastrados
    useEffect(() => {
      setLoading(false);
      window.scrollTo(0, 0);

      const token = localStorage.getItem("token");

      if (token) {
        fetch(`${API_BASE_URL}/api/transporte/solicitante`, {
          method: "GET",
          headers: {
            "Content-type": "application/json;charset=UTF-8",
            'token': token,
            'perfil': localStorage.getItem("tipo"),
            'Authorization': `Bearer ${token}` 
          }
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Não foi possível realizar a requisição!");
            }
            return response.json();
          })
          .then((data) => {
            setList(data);
            setLoading(true);
          })
          .catch((error) => {
            console.log(error);
            setLoading(true);
          });
      } else {
        setLoading(true);
        handlePage();
      }
    }, []);




      const notify = (msg) => toast(msg);

      function pegaID(id) {
        nextPage(id);
      }
 
 
     // History 
      

    // eslint-disable-next-line no-unused-vars
    const [estadoID, setEstadoID] = useState('');

    let editar = useNavigate();
     function nextPage(id) {
        localStorage.setItem("idSolicitante", id)
        editar('/gestor/editar-solicitante');
        
       
     }
     list.sort((a, b) => a.id - b.id);

   return (
    <section className={styles.container}>
            <Header titulo="Solicitantes" altura="140px"/>
            <Menu />
            <ToastContainer />

            <main className={
              ativadoMenu === true ? styles.mainContent2 : styles.mainContent
            }>
                <form className={styles.form}>
                    <FormGroup className={styles.formBlock}>
                           { totalItems > 0 &&
                             <div>
                               <h2>Lista de Solicitantes</h2>
                             </div>    
                           }    
                      
                            <div className={styles.blocoSetor}>
                              {
                                <div>
                                  <ul className={estilo.responsiveTable}>
                                    <li className={estilo.tableHeader}>
                                      <div className={estilo.col} id={estilo.col1}>
                                        ID
                                      </div>
                                      <div className={estilo.col} id={estilo.col2}>
                                        Nome
                                      </div>
                            
                                      <div className={estilo.col} id={estilo.col3}>
                                        Opções
                                      </div>
                                    </li>
                                    {list.length > 0 ?
                                    list.slice(start, end).map((item, index) => (
                                        <li className={estilo.tableRow} key={index}>
                                          <div className={estilo.col} id={estilo.col1} data-label="ID">
                                            {item.id}
                                          </div>
                                          <div className={estilo.col} id={estilo.col2} data-label="Nome">
                                            {item.nome}
                                          </div>
                                          <div className={estilo.col} id={estilo.col3} data-label="Opções">
                                            <div className={styles.buttonContainer}>
                                              <ButtonComp
                                                acao={() => pegaID(item.id)}
                                                tipo="button"
                                                estilo={styles.buttonEdit}
                                                texto="Editar"
                                              />
                                            </div>
                                          </div>
                                        </li>
                                      ))
                                    
                                    :<li  className={estilo.tableRow} >
                                    <p className={estilo.col}>Não há Solicitantes cadastrados</p>
                                    </li>
                                    }
                                  </ul>

                                  <div className={styles.pagination}>
                                    <ul className={styles.paginationList}>
                                      {Array.from({ length: totalPages }, (_, i) => (
                                        <li
                                          key={i}
                                          className={`${styles.paginationItem} ${
                                            i + 1 === currentPage ? styles.active : ''
                                          }`}
                                          onClick={() => handlePageChange(i + 1)}
                                        >
                                          {i + 1}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              }
                            </div>
                    </FormGroup> 
                </form>
            </main>

            {loading === false ?
                
                <div className={styles.loader}>
                            <div className={styles.loaderInterno}>
                              <Loading />
                            </div>
                </div>
                :
                <div></div>
                }
    </section>
   )
};

export default ListarSolicitantes;