/* eslint-disable no-unused-vars */
import React, { useState, useContext} from "react";
import { FaBars, FaCarAlt, FaRegUser} from 'react-icons/fa'
import { NavLink } from "react-router-dom";
import styles from "../Menu/Menu.module.css";
import $ from "jquery";
import { SiderDataMotorista } from "./SiderDataMotorista";
import SubMenuMotorista from "./SubMenuMotorista";
import { useEffect } from "react";
import { FiMenu } from "react-icons/fi";
import { RiCloseFill } from "react-icons/ri";
import { RiMenu3Fill } from "react-icons/ri";
import EstadoMenu from "./EstadoMenu";

const MenuMotorista = () =>{
    let largura = window.screen.width;
    let larguraAtual = 60;
    // Contexto do Estado do Menu
   const {ativadoMenu, setAtivadoMenu } = useContext(EstadoMenu);
    // Criando estado para o evento de mouseOver
    const [isShow, setIsShow] = useState('');
    const [isTela, setIsTela] = useState(true);
    const [isClose, setIsClose] = useState(false);
    const [ativado, setAtivado] = useState(false);

    if (isShow === true && largura > 450) {
        document.querySelector('#siderContainer').style.width = "300px";
        document.querySelector('#siderContainer').style.height = "100vh";
        document.querySelector('#siderContainer').style.transition = "all ease-in 0.5s";
        larguraAtual = 300;
    } else if (isShow === false && largura > 450) {
        document.querySelector('#siderContainer').style.width = "60px";
        document.querySelector('#siderContainer').style.height = "100vh";
        document.querySelector('#siderContainer').style.transition = "all ease-in 0.5s";
        larguraAtual = 60;
    }

    useEffect(() => {
      let largura2 = window.screen.width; 
      if (isTela === false && largura2 < 451){
        document.querySelector('#siderContainer').style.width = "250px";
        document.querySelector('#siderContainer').style.height = "100vh";
        document.querySelector('#siderContainer').style.transition = "all ease-in 0.5s";
        larguraAtual = 250;
      } 

      if (isTela === true && isClose === false && largura2 < 451) {
        document.querySelector('#siderContainer').style.width = "65px";
        document.querySelector('#siderContainer').style.height = "65px";
        document.querySelector('#siderContainer').style.transition = "all ease-in 0.5s";
        larguraAtual = 65;
      }
    }, [isTela, isClose])

    function handleIsTela(){
      setIsTela(!isTela);
      setIsClose(!isClose);
      document.querySelector('#siderContainer').style.width = "300px";
      setAtivado(true);
      setAtivadoMenu(true);
    }

    function handleIsClose(){
      setIsTela(!isTela);
      setIsClose(!isClose);
      document.querySelector('#siderContainer').style.width = "60px";
      setAtivado(false);
      setAtivadoMenu(false);
    }

    function handleMouseOver(){
      setIsShow(true);
    }

    function handleMouseOut(){
      ativado === true ? setIsShow(true) : setIsShow(false);
    }
   

  return (


         <section onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} id="siderContainer" style={{overflowY: 'auto',  paddingBottom: '50px'}} className={styles.sideBar}>

            <div className={styles.menuListItem1}>
                <div id="containerMenu" className={styles.menuContainerHamb}>
                 {isTela &&
                  <FiMenu  id={styles.menuSVG} onClick={handleIsTela} />}
                  {isClose &&
                  <RiMenu3Fill id={styles.menuClose} onClick={handleIsClose}/>
                  }
                </div> 
            </div>

             {SiderDataMotorista.map((item, index) => {
              return <SubMenuMotorista item={item} key={index} l={larguraAtual} />;
             
            })}

         </section> 
  )
};

export default MenuMotorista;
