import React from "react";
import styles from "../Loader/Loading.module.css";
import loading from "../../assets/loading.png";

const Loading = () => {
  return (
    <div className={styles.loaderContainer}>
    </div>
  ) 
};

export default Loading;