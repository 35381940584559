import React from 'react';
import './App.css';
import Routes from "../src/Routes";
import CodigoDaViagem from './pages/Viagem/CodigoViagem';
import { useState} from 'react';
import ListViagens from './Contexts/ArrayViagens';
import ListMotorista from './Contexts/ArrayMotoristas';
import EstadoMenu from './Components/Menu/EstadoMenu';


function App() {

  const [codigo, setCodigo] = useState('');
  const [viagensMotorista, setViagensMotorista] = useState([]);
  const [motoristas, setMotoristas] = useState([]);
  const [dados, setDados] = useState([]);
  const [ativadoMenu, setAtivadoMenu] = useState(false); 


  return (

   <EstadoMenu.Provider value={{ativadoMenu, setAtivadoMenu}}>
         <CodigoDaViagem.Provider value={{codigo, setCodigo}}>
            <ListViagens.Provider value={{viagensMotorista, setViagensMotorista}}>
               <ListMotorista.Provider value={{motoristas, setMotoristas}}>
                  <Routes />
               </ListMotorista.Provider>
            </ListViagens.Provider>
         </CodigoDaViagem.Provider>
   </EstadoMenu.Provider>


    
)};

export default App;