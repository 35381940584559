import React from 'react';
import * as RiIcons from 'react-icons/ri';
import styles from "../Menu/Menu.module.css";
import {HiUserGroup} from "react-icons/hi";
import { FiUsers } from "react-icons/fi";
import { FiUserCheck } from "react-icons/fi"; 
import { FaUsers } from "react-icons/fa"; 
import { MdEmergencyShare } from "react-icons/md";
import { MdOutlinePlace } from "react-icons/md";
import { BsCardChecklist } from "react-icons/bs";
export const SiderData = [

    {
      title: 'Veículos',
      path: '',
      icon: <RiIcons.RiCarFill id={styles.iconVeiculo}></RiIcons.RiCarFill>,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,

      subNav: [ 
        {
          title: 'Cadastro',
          path: '/veiculo',
          icon: <BsCardChecklist id={styles.iconVeiculo} />
        },


      ]
      
    },
    {
      title: 'Motorista',
      path: '',
      icon: <FiUsers id={styles.iconMotorista}/>,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,

      
      subNav: [
        {
          title: 'Cadastro',
          path: '/motorista',
          icon: <BsCardChecklist id={styles.iconMotorista2} />
        },

        { 
          title: 'Motoristas',
          path: '/gestor/listar-motoristas',
          icon: <FiUsers id={styles.iconMotorista}/>
        },

      ]
    },

    {
      title: 'Solicitante',
      path: '',
      icon: <FiUserCheck id={styles.iconMotorista}/>,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,

      
      subNav: [
        {
          title: 'Cadastro',
          path: '/gestor/cadastro-solicitante',
          icon: <BsCardChecklist id={styles.iconMotorista2} />
        },

        {
          title: 'Setor',
          path: '/gestor/cadastro-setor',
          icon: <i id={styles.iconMotorista2} className="fa-sharp fa-solid fa-business-time"></i>
        },

        {
          title: 'Solicitantes',
          path: '/gestor/listar-solicitantes',
          icon: <i  id={styles.iconMotorista2} className="fa-sharp fa-solid fa-users-gear"></i>
  
        },

      ]
    },

    {
      title: 'Passageiro',
      path: '',
      icon: <FaUsers id={styles.iconMotorista}/>,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,

      
      subNav: [
        {
          title: 'Cadastro',
          path: '/gestor/cadastro-passageiro',
          icon: <BsCardChecklist id={styles.iconMotorista2} />
  

        },

        {
          title: 'Passageiros',
          path: '/gestor/listar-passageiros',
          icon:  <FaUsers id={styles.iconMotorista}/>
        },

      ]
    },
    

    { 
      title: 'Viagem Avulsa',
      path: '',
      icon: <MdEmergencyShare id={styles.iconMotorista}/>,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
  
      subNav: [
        {
          title: 'Criar',
          path: '/viagem-avulsa',
          icon: <i id={styles.iconAvulsa} className="fa-solid fa-photo-film"></i>
        },

        {
          title: 'Listar',
          path: '/gestor/listarViagem',
          icon: <i id={styles.iconGestor} className="fa-sharp fa-regular fa-map"></i>  
        }
      ]
    },



    { 
      title: 'Viagem Comum',
      path: '',
      icon: <MdOutlinePlace id={styles.iconMotorista}/>,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
  
      subNav: [

        {
          title: 'Solicitações',
          path: '/gestor/listar-solicitacao',
          icon: <i id={styles.iconGestor} className="fa-sharp fa-regular fa-map"></i>  
        },

        {
          title: 'Viagens',
          path: '/gestor/listar-viagem-comum',
          icon: <i id={styles.iconGestor} className="fa-sharp fa-regular fa-map"></i>  
        }
      ]
    },
  ]; 

