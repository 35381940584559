/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext} from "react";
import { FormGroup, Form, Input, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header"; 
import Menu from "../../Components/Menu/Menu";
import Title from "../../Components/Title";
import * as C from "@chakra-ui/react";
import { Switch } from '@chakra-ui/react'
import styles from "../Gestor/UnirSolicitacoes.module.css"; 
import iconCalender from "../../assets/calender.png";
import Table from "react-bootstrap/Table";
import Label from "../../Components/Label/Label";
// import Input from "../../Components/Input/Input";
import ButtonComp from "../../Components/Button/Button"; 
import axios from "axios";
import { useDisclosure } from "@chakra-ui/react";
import ModalComp from "../../Components/Modal/ModalComp";
import Modal from "react-bootstrap/Modal";
import { Button } from "reactstrap";
import ListMotorista from "../../Contexts/ArrayMotoristas";
import {API_BASE_URL} from '../../services/Constantes';
import Loading from "../../Components/Loader/Loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IMaskInput } from "react-imask";
import estilo from "../Solicitante/SetorTabel.module.scss";
const UnirSolicitacoes = () => {

  useEffect(() => {
    document.title = 'Viagem Avulsa';
  }, []);

  // Histoy
    let navigate = useNavigate();
    function handlePage(){
      navigate('/gestor/listar-solicitacao'); 
    } 

  

  // Estados para o Modal
  const { isOpen, onOpen, onClose} = useDisclosure();
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState({});

  // Estado para o segundo Modal
  const [show, setShow] = useState(false);
  const [showHour, setShowHour] = useState(false);
  const [showData, setShowData] = useState(false);
  const [showParadas, setShowParadas] =useState(false);
  const [showOut, setShowOut] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseHour = () => setShowHour(false);
  const handleShowHour = () => setShowHour(true);
  const handleCloseData = () => setShowData(false);
  const handleShowData = () => setShowData(true);
  const handleCloseParadas = () => setShowParadas(false);
  const handleShowParadas = () => setShowParadas(true);
  const handleShowOut = () => setShowOut(true);
  const handleCloseOut = () => setShowOut(false);

  // Estados para os veiculos e motoristas
  const [veiculo, setVeiculo] = useState([]);
  const [motorista, setMotorista] = useState([]);
  let vetorAtual = [];
  // Estados para os campos
  const [drive, setDrive] = useState('');
  const [car, setCar] = useState(''); 
  const [details, setDetails] = useState('');
  const [day, setDay] = useState('');
  const [hour, setHour] = useState('');
  const [paradas, setParadas] = useState('');
  const [origem, setOrigem] = useState('');
  const [dataParada, setDataParada] = useState('');
  const [horaParada, setHoraParada] = useState('');
  const [status, setStatus] = useState(true);
  const [editButton, setEditButton] = useState(true);
  const [closeButton, setCloseButton] = useState(true);
  const [cancelButton, setCancelButton] = useState(false);
  const [saveButton, setSaveButton] = useState(false); 
  const [paradaLocal, setParadaLocal] = useState('');
  const [localAlter, setLocalAlter] = useState('');
  const [dataAlter, setDataAlter] = useState('');
  const [horaAlter, setHoraAlter] = useState('');
  const [cont, setCont] = useState(2);
  const [idInformado, setIdInformado] = useState('');
  const [firtsData, setFirstData] = useState('');
  const [percursoID, setPercursoID] = useState({});
  const [buttonValue, setButtonValue] = useState('');
  const [loading, setLoading] = useState(true);
  let viagemID;
  let idTeste;
  let idSolicitacoes = localStorage.getItem("SolicitacoesJoin");
  const [arrayIDs, setArrayIDs] = useState([]);
  let arrayTest = [];
  // Estado par a listagem de paradas
  const [list, setList] = useState([]);
  const [paradasSolicitacao, setParadasSolicitacao] = useState([]);

  // Contexto
  const {motoristas, setMotoristas} = useContext(ListMotorista);
  const [valorAtual, setValorAtual] = useState('');
  let idGestor;

  const uniqueList = list.filter((item, index) => list.findIndex(obj => obj.id === item.id) === index);
  const uniqueList2 = paradasSolicitacao.filter((item, index) => paradasSolicitacao.findIndex(obj => obj.id === item.id) === index);
   // Funcionalidade de Páginação
   const [currentPage, setCurrentPage] = useState(1);
   const [currentPage2, setCurrentPage2] = useState(1);


   const itemsPerPage = 5;
   const totalItems = uniqueList.length;
   const totalItems2 = uniqueList2.length;

   const totalPages = Math.ceil(totalItems / itemsPerPage);
   const totalPages2 = Math.ceil(totalItems2 / itemsPerPage);

   const start = (currentPage - 1) * itemsPerPage;
   const start2 = (currentPage2 - 1) * itemsPerPage;

   const end = start + itemsPerPage;
   const end2 = start2 + itemsPerPage;

   const [applyFilter, setApplyFilter] = useState(false);
   const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
   const handlePageChange2 = (pageNumber) => setCurrentPage2(pageNumber);



  function detalhaParada(id){
    setEditButton(false);
    viagemID = id;
    setSaveButton(true);
  }

  // Pegando os ids das solicitações e salvando em um array

 
  // Função para alterar a lista de paradas
  function handleAlterList(id){
      let newList = [...list]; 
      newList.map((item, index) => (
        item.id === id ? (item.local = localAlter, item.data = formatData(dataAlter)) : console.log("Não é igual")
      ))
    setList(newList) 
  }

  function formatData(data) {
    var date = new Date(data+'T12:00:00.00-0200');
    let dataFinal = (new Intl.DateTimeFormat('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'}).format(date))
    return dataFinal
  }


  function handleSaveList(dado, dataLocal, origem, hora) {
    let newList = [...list];
    var date = new Date(dataLocal+'T12:00:00.00-0200');
    let formatData = (new Intl.DateTimeFormat('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'}).format(date))

    if(newList.indexOf(dado) === -1){
      if(dado !== '' && dataLocal !== ''){
        setCont(cont+1)
        newList.push({id: cont, local: dado, data: formatData, dataOriginal: dataLocal, localOrigem: origem, status: 'Não', hora: hora});
        setList(newList)
        setParadas('');
        setDataParada(day);
 
      } else{
        alert("Informe uma parada e uma data");
      }
     
    } else {
      alert("O local de parada já foi informado");
    }


  } 

  function handleRemoveList(dado){

    let newList = [...list];
    newList.splice(dado.index, 1)
    setList(newList);
   
  }

  function handleRemoveTotalList(dado){
    let newList = [...list];
    dado.map((item, index) => (
      newList.splice(item.index, 1)
    ));
    setList(newList);
  }

  // Pegar as solicitações que serão unidas
  useEffect(() => {
    setLoading(false);
    window.scrollTo(0, 0);
    fetch(`${API_BASE_URL}/api/transporte/solicitacao`, {
      method: "GET",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "token": localStorage.getItem("token"),
        "perfil": localStorage.getItem("tipo"),
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const vetorAtual = data.filter(item => idSolicitacoes.includes(item.id));
        setList(vetorAtual);
        const paradasArray = vetorAtual.filter(item => item.paradas.length > 0).map(item => item.paradas);
        const allParadas = [].concat(...paradasArray);
        setParadasSolicitacao(allParadas);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });
  }, [idSolicitacoes]);
  
  


  useEffect(() => {
    setLoading(false);
    window.scrollTo(0, 0);
    fetch(`${API_BASE_URL}/api/transporte/veiculo`, {
        method: "GET",
        headers: {
            "Content-type": "application/json;charset=UTF-8",
            'token': localStorage.getItem("token"),
            'perfil': localStorage.getItem("tipo")
        }
        }).then((response) => {
            return response.json();
          })
          .then((data) => {
            setVeiculo(data);
            setLoading(true);
          })
          .catch((error) => {
            console.log(error)
          });
    
},[])

  // // Requisição para API do Motorista
  useEffect(() => {
    fetch(`${API_BASE_URL}/api/transporte/motorista`, {
        method: "GET",
        headers: {
            "Content-type": "application/json;charset=UTF-8",
            'token': localStorage.getItem("token"),
            'perfil': localStorage.getItem("tipo")
        }
        }).then((response) => {
            return response.json();
          })
          .then((data) => {
            setMotorista(data);
          })
          .catch((error) => {
            console.log(error)
          });
    
},[])
  
  // Preparação para o metódo POST
  const validaDados = () =>{
    

    if (uniqueList.length > 0 && car !== '' && drive !== '' && origem !== '' && details !== '') {
      
   
       postJoin();
      // postViagem(obj); 
    } 
      else {
        notify("Informe todos os dados da viagem")
      }

   

   
    } 

       // Cadastro já está funcionando - Alteração feita com sucesso

    const postJoin = async () => {
      setLoading(false);
      window.scrollTo(0, 0);
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/transporte/solicitacao/unir`,
          {
            method: "POST",
            body: JSON.stringify({
              "gestor_id": localStorage.getItem("IdUsuario"),
              "motorista_id": drive,
              "veiculo_id": car,
              "observacao": details,
              "data_prevista": day,
              "hora_prevista": hour,
              "ponto_origem": origem, 
              "paradas": paradasSolicitacao,
              "solicitacoes": uniqueList
          }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              'token': localStorage.getItem("token"),
              'perfil': localStorage.getItem("tipo")
            },
          }
        ); 
        if(response.ok) {
          setLoading(true);
          const json = await response.json();
          notify("Solicitações unidas com sucesso");
        } else {
          setLoading(true);
          notify("Não foi possível unir as solicitações");
        }
       
      } catch (error) {
        console.log(error);
        notify("Não foi possível unir as solicitações");
     
      };
    
  }



  function validaData (data) {

      let dataInformada = data;
      let dataAtual = new Date();
      let dataAtualFormatada = dataAtual.toLocaleDateString('pt');
      let dataAtualFinal = dataAtualFormatada.split('/').reverse().join('-');
      dataInformada >= dataAtualFinal ? setDay(dataInformada) || setDataParada(dataInformada) || setDataAlter(dataInformada)  : notify("Informe uma data válida!")
  }

  function validaData2 (data) {

    let dataInformada = data;

    let dataAtual = new Date();
    dataAtual.setDate(dataAtual.getDate());
    let dateFormatado = dataAtual.toLocaleDateString('en-CA');
    let horaAtual = new Date().toLocaleTimeString();
    let horaAtualFormatada = horaAtual.slice(0, -3);
    dataInformada >= dateFormatado ? setDay(dataInformada) || setDataParada(dataInformada) || setDataAlter(dataInformada)  : handleShowData()
    setHour(horaAtualFormatada);
  
}



  function validaHora (hora){
    let dataAtual = new Date();
    dataAtual.setDate(dataAtual.getDate());
    let dateFormatado = dataAtual.toLocaleDateString('en-CA');
    let horaAtual = new Date().toLocaleTimeString();
    let horaAtualFormatada = horaAtual.slice(0, -3);
    let horaInformada = hora;
    (horaInformada > horaAtualFormatada || horaInformada === horaAtualFormatada) ? setHour(horaInformada) : day > dateFormatado ? setHour(horaInformada) : notify("Informe uma hora válida!")
    
  }

  function validaHora2 (hora){
    let dataAtual = new Date();
    dataAtual.setDate(dataAtual.getDate());
    let dateFormatado = dataAtual.toLocaleDateString('en-CA');
    let horaAtual = new Date().toLocaleTimeString();
    let horaAtualFormatada = horaAtual.slice(0, -3);
    let horaInformada = hora;
    (horaInformada > horaAtualFormatada || horaInformada === horaAtualFormatada) ? setHoraParada(horaInformada) : dataParada > dateFormatado ? setHoraParada(horaInformada) : notify("Informe uma hora válida!")
    
  }

  function validaHora3 (hora){
    let dataAtual = new Date();
    dataAtual.setDate(dataAtual.getDate());
    let dateFormatado = dataAtual.toLocaleDateString('en-CA');
    let horaAtual = new Date().toLocaleTimeString();
    let horaAtualFormatada = horaAtual.slice(0, -3);
    let horaInformada = hora;
    (horaInformada > horaAtualFormatada || horaInformada === horaAtualFormatada) ? setHoraAlter(horaInformada) : dataParada > dateFormatado ? setHoraAlter(horaInformada) : handleShowHour();
    
  }
  function validaParadas () {
    // paradas !== '' && origem !== '' ? 
    handleSaveList(paradas, dataParada, origem, horaParada)
    // :
    // handleShowParadas()
  }



 useEffect(() => {
   // Defautl Data
   let dataAtual = new Date();
   let dataAtualFormatada = dataAtual.toLocaleDateString('pt');
   let dataAtualFinal = dataAtualFormatada.split('/').reverse().join('-');
   let horaAtual = new Date().toLocaleTimeString();
   setHour(horaAtual.slice(0, -3))
   setDay(dataAtualFinal);
   setDataParada(dataAtualFinal);
   setFirstData(dataAtualFinal)
   setHoraParada(horaAtual.slice(0, -3))
  idGestor = localStorage.getItem("IdUsuario");
  setHoraAlter(horaParada);

 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);
 


 function botaoAction() {
  setEditButton(false);
 setSaveButton(true);
 setCancelButton(true);
 }

 function pegaID(id){
  setIdInformado(id);
  idTeste = id;
  setButtonValue(id);

  let datAlter;
  let datSplit;
  let datReverse;
  let datFinal;
  list.map((item, index) => (
    item.id === idTeste ?  ((setLocalAlter(item.local)), datAlter = (item.data).replace(/\//g, '-'), (datSplit = datAlter.split("-")), (datReverse = datSplit.reverse()), (datFinal = datReverse.join("-")), (setDataAlter(datFinal)))
     : <div></div>
  ))

  list.map((item, index) => (
    item.id === idTeste && (setEditButton(false), setSaveButton(true), setCancelButton(true), setCloseButton(false)) 
     
  ));
} 

const notify = (msg) => toast(msg);
console.log(paradasSolicitacao)
 
  return (
    <section className={styles.viagem}>
      <Header titulo="Solicitações" altura="240px"/>
      <Menu />

      <ToastContainer />
      {loading === false ?
            
            <div className={styles.loader}>
                        <div className={styles.loaderInterno}>
                          <Loading />
                        </div>
            </div>
            :
            <div></div>
       }

      <main className={styles.mainContent}>

 
        <FormGroup className={styles.mainForm}>
        <h2>Dados da Viagem</h2>
          <div className={styles.mainFormItemA}>
            <div className={styles.inputForm}>
              <div className={styles.formGroup2}>
                        <span>Data Prevista</span>
                        <IMaskInput className={styles.formField} type="date" onChange={(e) => validaData(e.target.value)} value={day}/>
              </div>
            </div>

            <div className={styles.inputForm}>
               <div className={styles.formGroup3}>
                          <span>Hora</span>
                          <IMaskInput className={styles.formField} type="time" onChange={(e) => validaHora(e.target.value)} value={hour}/>
                </div>
            </div> 

            <div className={styles.inputForm}>
               <div className={styles.formGroup4}>
                          <span>Origem</span>
                          <IMaskInput className={styles.formField} placeholder="Informe o local de Origem" type="text" onChange={(e) => setOrigem(e.target.value)} value={origem}/>
                </div>
            </div>
          </div>

          <div className={styles.mainFormItemB}>
            <div className={styles.inputForm}>
                    <div className={styles.formGroup}>
                                  <span>Veículo</span>
                                  <Input className={styles.formField}  type="select" onChange={(e) => setCar(e.target.value)} value={car}>
                                    <option value="" disabled>Selecione um carro</option>
                                    {Array.isArray(veiculo) ? (
                                      veiculo.map((carro, key) => (
                                        <option
                                          value={carro.id}
                                          className={styles.selectOption}
                                          key={key}
                                        >{`${carro.modelo} - ${carro.placa}`}</option>
                                      ))
                                    ) : (
                                      <option value="" disabled>
                                        Nenhum veículo encontrado.
                                      </option>
                                    )}

                                    </Input>
                    </div>
         </div>
 

          <div className={styles.inputForm}>
                <div className={styles.formGroup}>
                                <span>Motorista</span>
                                <Input className={styles.formField} type="select" onChange={(e) => setDrive(e.target.value)} value={drive}>
                                  <option value="" disabled>Selecione um motorista</option>
                                  {Array.isArray(motorista) ? (
                                    motorista.map((pessoa, index) => (
                                      <option value={pessoa.id} key={index}>{pessoa.nome}</option>
                                    ))
                                  ) : (
                                    <option value="" disabled>
                                      Nenhum motorista encontrado.
                                    </option>
                                  )}
                              </Input>
                </div>
            </div>


            
          </div> 

          
        </FormGroup>

        <section className={styles.mainTable}>
     
                <div className={styles.formBlock}>
                       <h2>Solicitações Selecionadas</h2>
                </div>   

                <div className={styles.blocoSetor} id="tabelaViagens">
                                {totalItems > 0 ? (
                                  <div>
                                    <ul className={estilo.responsiveTable}>
                                      <li className={estilo.tableHeader}>
                                        <div className={estilo.col} id={estilo.col1}>
                                          ID
                                        </div>
                                        <div className={estilo.col} id={estilo.col2}>
                                          Solicitação
                                        </div><div className={estilo.col} id={estilo.col2}>
                                          Origem
                                        </div>
                                        <div className={estilo.col} id={estilo.col3}>
                                          N* de Passageiros
                                        </div>
                                      </li>
                                      {uniqueList.slice(start, end).map((item, index) => (
                                        <li className={estilo.tableRow} key={index}>
                                          <div className={estilo.col} id={estilo.col1} data-label="ID">
                                            {item.id}
                                          </div>
                                          <div className={estilo.col} id={estilo.col2} data-label="Solicitação">
                                            <div className={styles.blocoParadas}>
                                              {item.finalidade}
                                              </div>
                                          </div>
                                          <div className={estilo.col} id={estilo.col2} data-label="Origem">
                                            {item.origem}
                                          </div>
                                          <div className={estilo.col} id={estilo.col3} data-label="Status">
                                            {window.innerWidth <= 551 ?
                                            item.passageiros.length :
                                            item.passageiros.length + ' passageiros'}
                                          </div>

                                        </li>
                                      ))}
                                    </ul>

                                    <div className={styles.pagination}>
                                      <ul className={styles.paginationList}>
                                        {list.length > 5 ?
                                        Array.from({ length: totalPages }, (_, i) => (
                                          <li
                                            key={i}
                                            className={`${styles.paginationItem} ${
                                              i + 1 === currentPage ? styles.active : ''
                                            }`}
                                            onClick={() => handlePageChange(i + 1)}
                                          >
                                            {
                                              list.length >= 5 ? i + 1 : ''
                                            }
                                          </li>
                                        ))
                                      : <div></div>
                                      }
                                      </ul>
                                    </div>
                                  </div>
                                ) : loading === true ? (
                                  <div className={styles.blocoSolicitacao}>
                                    <p className={styles.solicitacaoTitle}>Não há solicitações cadastradas</p>
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                </div>

                { paradasSolicitacao &&
                  <div className={styles.formBlock}>
                          <h2>Lista de Paradas</h2>
                  </div>   
                }

                <div className={styles.blocoSetor} id="tabelaViagens">
                                {totalPages2 > 0 ? (
                                  <div>
                                    <ul className={estilo.responsiveTable}>
                                      <li className={estilo.tableHeader}>
                                        <div className={estilo.col} id={estilo.col1}>
                                          ID
                                        </div>
                                        <div className={estilo.col} id={estilo.col2}>
                                          Local
                                        </div><div className={estilo.col} id={estilo.col2}>
                                          Data Prevista
                                        </div>
                                        <div className={estilo.col} id={estilo.col3}>
                                          Status
                                        </div>
                                      </li>
                                      {paradasSolicitacao.slice(start2, end2).map((item, index) => (
                                        <li className={estilo.tableRow} key={index}>
                                          <div className={estilo.col} id={estilo.col1} data-label="ID">
                                            {item.id}
                                          </div>
                                          <div className={estilo.col} id={estilo.col2} data-label="Local">
                                            <div className={styles.blocoParadas}>
                                              {item.local}
                                              </div>
                                          </div>

                                          <div className={estilo.col} id={estilo.col2} data-label="Data Prevista">
                                            {formatData(item.data_prevista)}
                                          </div>
                                          <div className={estilo.col} id={estilo.col3} data-label="Origem">
                                            {item.aguardar === true ? 'Sim' : 'Não'}
                                          </div>

                                        </li>
                                      ))}
                                    </ul>

                                    <div className={styles.pagination}>
                                      <ul className={styles.paginationList}>
                                        {paradasSolicitacao.length > 5 ?
                                        Array.from({ length: totalPages2 }, (_, i) => (
                                          <li
                                            key={i}
                                            className={`${styles.paginationItem} ${
                                              i + 1 === currentPage2 ? styles.active : ''
                                            }`}
                                            onClick={() => handlePageChange2(i + 1)}
                                          >
                                            {
                                              paradasSolicitacao.length >= 5 ? i + 1 : ''
                                           
                                            }
                                          </li>
                                        ))
                                      : <div></div>
                                      }
                                      </ul>
                                    </div>
                                  </div>
                                ) : loading === true ? (
                                 console.log('')
                                ) : (
                                  console.log('')
                                )}
                </div>
        </section>



        {isOpen && (
          <ModalComp
            isOpen={isOpen}
            onClose={onClose}
            data={data}
            setData={setData}
            dataEdit={dataEdit}
            setDataEdit={setDataEdit}
            arrayList={list}
            handleSave={handleAlterList}
          />
        )}

        <section className={styles.modalList}>
            <Modal className={styles.modalEstilo} show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Viagem Cadastrada Com Sucesso!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className={styles.viagemEstilo}>
                 Código da Viagem: AV-{percursoID.id} 
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button color="danger" type="button" onClick={handleClose}>
                  CADASTRAR NOVA VIAGEM
                </Button>
                <Button color="success" type="button" onClick={handlePage}>
                  LISTA DE VIAGENS
                </Button>
              </Modal.Footer>
            </Modal>
        </section>

        <section className={styles.contentArea}>
          <h2>Observação:</h2>
          <C.Textarea className={styles.textArea} placeholder="Digite aqui" onChange={(e) => setDetails(e.target.value)} value={details}/>
          <div className={styles.blocoButtons}>
            <ButtonComp acao={validaDados}   estilo={styles.contentAreaBtn} texto="Juntar" tipo="submit"/>
            <ButtonComp acao={handleShowOut}   estilo={styles.contentAreaBtn2} texto="Cancelar" tipo="submit"/>
          </div>
  
        </section>
      </main>

      { <Modal className={styles.modalEstilo} show={showHour} onHide={handleCloseHour}>
              <Modal.Header closeButton>
                <Modal.Title>Informe uma hora valida!</Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button color="danger" onClick={handleCloseHour}>
                  FECHAR
                </Button>
              </Modal.Footer>
            </Modal>
            }

  {<Modal className={styles.modalEstilo} show={showData} onHide={handleCloseData}>
                <Modal.Header closeButton>
                  <Modal.Title>Informe uma data valida!</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button color="danger" onClick={handleCloseData}>
                    FECHAR
                  </Button>
                </Modal.Footer>
              </Modal>
              }

{<Modal className={styles.modalEstilo} show={showParadas} onHide={handleCloseParadas}>
                <Modal.Header closeButton>
                  <Modal.Title>Informe os dados da parada/viagem!</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button color="danger" onClick={handleCloseParadas}>
                    FECHAR
                  </Button>
                </Modal.Footer>
              </Modal>
              }

{<Modal className={styles.modalEstilo} show={showOut} onHide={handleCloseOut}>
                <Modal.Header closeButton>
                  <Modal.Title>Deseja realmente sair?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button color="success" onClick={handlePage}>
                    SIM
                  </Button>
                  <Button color="danger" onClick={handleCloseOut}>
                    FECHAR
                  </Button>
                </Modal.Footer>
              </Modal>
              }


    </section>
  );
}; 

export default UnirSolicitacoes;
