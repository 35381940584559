/* eslint-disable no-unused-vars */
import React, { useContext} from "react";
import styles from "../Gestor/GestorEditarViagem.module.css";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import Title from "../../Components/Title"; 
import { Switch } from '@chakra-ui/react';
// import Table from 'react-bootstrap/Table';
import ButtonComp from "../../Components/Button/Button";
// import Input from "../../Components/Input/Input";
import Label from "../../Components/Label/Label";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Loading from "../../Components/Loader/Loading";
import Button from "react-bootstrap/Button";
import { Textarea } from "@chakra-ui/react";
import $ from "jquery";
import Table from "react-bootstrap/Table";
import { FormGroup, Form, Input, Row, Col } from "reactstrap";
import CodigoDaViagem from "../Viagem/CodigoViagem";
import {API_BASE_URL} from '../../services/Constantes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IMaskInput } from "react-imask";
import estilo from "../Solicitante/SetorTabel.module.scss";

const GestorEditaViagem = () => {
  useEffect(() => {
    document.title = 'Editar Viagem';
  }, []);

  // let $ = require('jquery');
  $.DataTable = require('datatables.net')

  // Contexto para o ID da Viagem
  const {codigo, setCodigo} = useContext(CodigoDaViagem);

  const [viagens, setViagens] = useState([]);
  const [local, setLocal] = useState('');
  const [localParada, setLocalParada] = useState('');
  const [dataParada, setDataParada] = useState('');
  const [horaParada, setHoraParada] = useState('');
  const [status, setStatus] = useState(false);
  const [statusParada, setStatusParada] = useState(false);
  const [data, setData] = useState([]);
  const [hora, setHora] = useState('');
  const [kmFinal, setKmFinal] = useState('');
  const [loading, setLoading] = useState(false);
  const [motorista, setMotorista] = useState([]);
  const [veiculo, setVeiculo] = useState([]);
  const [car, setCar] = useState('');
  const [drive, setDrive] = useState('');
  const [paradasID, setParadasID] = useState('');
  const [editButton, setEditButton] = useState(true);
  const [saveButton, setSaveButton] = useState(false);
  const [closeButton, setCloseButton] = useState(false);
  const [origem, setOrigem] = useState('');
  const [observacao, setObservacao] = useState('');
  const [showEdit, setShowEdit] = useState(false);
  const [stateButton, setStateButton] = useState(false);
  const [estadoBotao, setEstadoBotao] = useState(false);
  const [estadoCancelar, setEstadoCancelar] = useState(false);
  const [horaPrevista, setHoraPrevista] = useState('');

  const [showFinaliza, setShowFinaliza] = useState(false);
  const [cancelSucess, setCancelSucess] = useState(false);

  const handleCloseSucess = () => setCancelSucess(false);
  const handleShowSucess = () => setCancelSucess(true);
  const handleCloseFinaliza = () => setShowFinaliza(false);
  const handleShowFinaliza = () => setShowFinaliza(true);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);
  // eslint-disable-next-line no-unused-vars
  const [buttonEstado, setButtonEstado] = useState(false);
  const [day, setDay] = useState('');
  const [hour, setHour] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [showData, setShowData] = useState(false);
  const [showMotorista, setShowMotorista] = useState(false);
  const [showVeiculo, setShowVeiculo] = useState(false);
  // Estado para o segundo Modal
  const [show, setShow] = useState(false);
  const [showHour, setShowHour] = useState(false);
  const handleCloseHour = () => setShowHour(false);
  const handleShowHour = () => setShowHour(true);
  const handleShowMotorista = () => setShowMotorista(true);
  const handleShowVeiculo = () => setShowVeiculo(true);
  const handleCloseMotorista = () => setShowMotorista(false);
  const handleCloseVeiculo = () => setShowVeiculo(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowData = () => setShowData(true);
  const handleCloseData = () => setShowData(false);
  const [idParadaAtual, setIdParadaAtual] = useState('');
  let obj;
  let idParada;
  let paradas;
  let paradaID = parseInt(idParada);
  let idViagem = localStorage.getItem("idViagemAtual");

  function validaCancelamento(){
    cancelarViagem();
    handleCloseFinaliza();
    // handleShowSucess();
    // previewsPage();
    
  }


       
   
  
 // History
  let navigate = useNavigate();
  function previewsPage(){
           
     navigate('/gestor/listarViagem')
     
   }

    
   function pegaParada (id) {
      handleShow()
      idParada = id;

    }

    let teste;
    function pegaID(id){
      setIdParadaAtual(id);
      teste = id;
      setEditButton(false);
      setSaveButton(true);
      setCloseButton(true);
      paradas.map((item, index) => (
        item.id === teste ? (setLocalParada(item.local), setDataParada(item.data_prevista), setHoraParada(item.hora_final), setHoraPrevista(item.hora_prevista)) : console.log("Deu erro na linha 91")
      ))

    }

    

    // eslint-disable-next-line no-unused-vars
    function validaParada () {
      setLocal('');
      setData('');

    }

    function validaData (data) {
      let dataAtual = new Date();
      dataAtual.setDate(dataAtual.getDate());
      let dateFormatado = dataAtual.toLocaleDateString('en-CA');
      let horaAtual = new Date().toLocaleTimeString();
      // eslint-disable-next-line no-unused-vars
      let horaAtualFormatada = horaAtual.slice(0, -3);
      let dataInformada = data;
  
      dataInformada >= dateFormatado ? setDay(dataInformada) : handleShowData();
      dataInformada >= dateFormatado ? setData(dataInformada) : handleShowData();
      dataInformada >= dateFormatado ? setDataParada(dataInformada) : handleShowData();


    
    }

    const validaDados = () =>{
    
    
      
       obj =  paradas?.map((item, index) =>(
          { 
            "id": item.id,
            "local": item.local,
            "data_prevista": item.data_prevista, 
            "hora_final": item.hora_final,
            "aguardar": item.aguardar
          }
        ));

          postViagem(obj)
        }
        
   

    // Requisição POST para adicionar parada
    const adicionaParada = async () => {
      setLoading(false);
      window.scrollTo(0, 0);
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/transporte/viagemAvulsa/adicionar-parada/${idViagem }`,
          {
            method: 'POST',
            body: JSON.stringify({
              "paradas": [
                  {     
                      "local": local,
                      "data_prevista": data,
                      "hora_final": horaPrevista,
                      "aguardar": status
                  }
              ]
          }), 
            headers: {
              "Content-type": "application/json;charset=UTF-8",
              'token': localStorage.getItem("token"),
              'perfil': localStorage.getItem("tipo"),
            },
          }
        );
        if(response.ok){
          const json = await response.json();
          setLocal('');
          setEstadoBotao(!estadoBotao);
          setLoading(true);
          notify4();
        } else {
          setLoading(true)
        }
      } catch (error) {
        console.log(error);
      };
    
    };


    // Requisição POST para editar viagem

    const postViagem = async (listParadas) => {
      setLoading(false);
      window.scrollTo(0, 0);
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/transporte/viagemAvulsa/atualizar/${idViagem }`,
          {
            method: 'PUT',
            body: JSON.stringify({
              "motorista_id": drive === '' ? viagens.motorista_id : drive,
              "veiculo_id": car === '' ? viagens.veiculo_id : car,
              "data_prevista": day,
              "hora_prevista": hour,
              "observacao": observacao,
              "paradas": listParadas
          }), 
            headers: {
              "Content-type": "application/json;charset=UTF-8",
              'token': localStorage.getItem("token"),
              'perfil': localStorage.getItem("tipo"),
            },
          }
        );
        if(response.ok) {
          const json = await response.json();
          setSaveButton(false);
          setLoading(true);
          notify();
        } else {
          const json = await response.json();
          setSaveButton(false);
         
          // handleShowAlert();
        }
      } catch (error) {
        console.log(error);
      };
    
    };
 

    function validaViagem() {
      // drive === '' && car !== '' ? handleShowMotorista() :
      // drive !== '' && car === '' ? handleShowVeiculo() : drive === '' && car === '' ? handleShowMotorista() : validaDados()
     
     validaDados()
    }
   // Requisição para API do Veiculo
   useEffect(() => {
    fetch(`${API_BASE_URL}/api/transporte/veiculo`, {
        method: "GET",
        headers: {
            "Content-type": "application/json;charset=UTF-8",
            'token': localStorage.getItem("token"),
            'perfil': localStorage.getItem("tipo")
        }
        }).then((response) => {
            return response.json();
          })
          .then((data) => {
            setVeiculo(data);
          })
          .catch(() => {});
},[])

   // Requisição para API do Motorista

  useEffect(() => {
    fetch(`${API_BASE_URL}/api/transporte/motorista`, {
        method: "GET",
        headers: {
            "Content-type": "application/json;charset=UTF-8",
            'token': localStorage.getItem("token"),
            'perfil': localStorage.getItem("tipo")
        }
        }).then((response) => {
            return response.json();
          })
          .then((data) => {
            setMotorista(data);
          })
          .catch(() => {});
},[])


  // Requisição para Cancelar Viagem

  const cancelarViagem = async () => {
    setLoading(false);
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/transporte/viagemAvulsa/cancelar/${idViagem }`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json;charset=UTF-8",
            'token': localStorage.getItem("token"),
            'perfil': localStorage.getItem("tipo"),
          },
        }
      );
      if (response.ok) {
        const json = await response.json();
        // setEstadoCancelar(!estadoCancelar);
        setLoading(true);
        cancelNotify();
        localStorage.setItem("status", "CANCELADA")
      } else {
        cancelNotifyFalse();
      }
    } catch (error) {
      console.log(error);
    };
  
  };



  

  // Fazendo requisição GET para o id da Viagem
  useEffect(() => {
    window.scrollTo(0, 0);
    fetch(`${API_BASE_URL}/api/transporte/viagemAvulsa/buscarPorId/${idViagem }`, {
        method: "GET",
        headers: {
            "Content-type": "application/json;charset=UTF-8",
            'token': localStorage.getItem("token"),
            'perfil': localStorage.getItem("tipo"),
            'Authorization': `Bearer ${localStorage.getItem("token")}` 
        }
        }).then((response) => {
            return response.json();
          })
          .then((data) => {
            setViagens(data);
            setEstadoBotao(false);
            setLoading(true);
            setEstadoCancelar(false);
          })
          .catch(() => {});
},[codigo, estadoBotao, paradas, estadoCancelar])



    function pegaParadas (dado) {
      Object.keys(dado).forEach((item) => {
        item === 'paradas' ? paradas = dado[item]: <p></p>
      })
    }

    const formatDataAtual = (data) => {
      let date = new Date(data+'T12:00:00.00-0200');
      let formatData = (new Intl.DateTimeFormat('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'}).format(date))
      return formatData
     }

     const formatHoraAtual = (dado) => {
   
      let formatHora = String (dado)
      return formatHora.replace(':00', '');
     }

     function validaHora (hora){
      let dataAtual = new Date();
      dataAtual.setDate(dataAtual.getDate());
      let dateFormatado = dataAtual.toLocaleDateString('en-CA');
      let horaAtual = new Date().toLocaleTimeString();
      let horaAtualFormatada = horaAtual.slice(0, -3);
      let horaInformada = hora;
      (horaInformada > horaAtualFormatada || horaInformada === horaAtualFormatada) ? setHour(horaInformada) : day > dateFormatado ? setHour(horaInformada) : handleShowHour();
      (horaInformada > horaAtualFormatada || horaInformada === horaAtualFormatada) ? setHora(horaInformada) : data > dateFormatado ? setHora(horaInformada) : handleShowHour();
      (horaInformada > horaAtualFormatada || horaInformada === horaAtualFormatada) ? setHoraParada(horaInformada) : dataParada > dateFormatado ? setHoraParada(horaInformada) : handleShowHour();
      (horaInformada > horaAtualFormatada || horaInformada === horaAtualFormatada) ? setHoraPrevista(horaInformada) : dataParada > dateFormatado ? setHoraPrevista(horaInformada) : handleShowHour();
      
    }

    function validaHora2 (hora){
      let dataAtual = new Date();
      dataAtual.setDate(dataAtual.getDate());
      let dateFormatado = dataAtual.toLocaleDateString('en-CA');
      let horaAtual = new Date().toLocaleTimeString();
      let horaAtualFormatada = horaAtual.slice(0, -3);
      let horaInformada = hora;
      (horaInformada > horaAtualFormatada || horaInformada === horaAtualFormatada) ? setHoraPrevista(horaInformada) : dataParada > dateFormatado ? setHoraPrevista(horaInformada) : handleShowHour();
      
    }
    
    pegaParadas(viagens)
  

    useEffect(() => {
      // Defautl Data
  
      setDay(viagens.data_prevista);
      setHour(viagens.hora_prevista);
      setOrigem(viagens.ponto_origem);
      setObservacao(viagens.observacao);
  
    }, [paradas, viagens])


    // Tabela Material UI

    function validaCheck(){
      setCloseButton(false);
      setSaveButton(false);
      setEditButton(true);
    }

    function saveParadas(idAtual){
      paradas.map((item, index) => (

        item.id === idAtual ? (
          item.local = localParada,
          item.data_prevista = dataParada,
          item.hora_final = horaPrevista,
          item.aguardar = statusParada
        ) : console.log("Deu Erro")
      ))

      setSaveButton(false);
      setCloseButton(false);
      setEditButton(true);
          
 
      setStatusParada(false)
    }
    
    useEffect(() => {
      // Defautl Data
      let dataAtual = new Date();
      let horaAtual = new Date().toLocaleTimeString();
      setHora(horaAtual.slice(0, -3))
      dataAtual.setDate(dataAtual.getDate());
      let dateFormatado = dataAtual.toLocaleDateString('en-CA');
      setData(dateFormatado);
    }, []) 


    const notify = () => toast("Viagem atualizada!");
    const cancelNotify = () => toast("Viagem cancelada!");
    const cancelNotifyFalse = () => toast("A viagem já está em andamento!");
    const notify4 = () => toast("Parada adicionada!");
 
  return (
    <section className={styles.avulsaViagem}>
      <Header titulo="Editar Viagem" altura="240px"/>
      <Menu />
      {loading === false ?
            
            <div className={styles.loader}>
                        <div className={styles.loaderInterno}>
                          <Loading />
                        </div>
            </div>
            :
           <div></div>
       }
      <main className={styles.mainContent}>
                    <section className={styles.campoViagem}>
                        <div className={styles.blocoViagem}>
                          <h1 className={styles.viagemTitle}>Dados da Viagem:</h1>
                          <div className={styles.blocoEditarViagem}>
          
                            <div className={styles.inputOptions}>
                      
                              <div className={styles.blocoInputs}>
                                <div className={styles.formItems}>
                                      <div className={styles.formGroup1}>
                                              <span>Origem</span>
                                              <IMaskInput className={styles.formField} name="origem"   onChange={(e) => setOrigem(e.target.value)} value={origem} type="text"/>
                                      </div>
                                </div>


                              </div>
                              <div className={styles.blocoInputs}>
                                <div className={styles.formItems}>
                                      <div className={styles.formGroup}>
                                              <span>Motorista</span>
                                              <Input type="select" className={styles.select} required  onChange={(e) => setDrive(e.target.value)} value={drive}>
                                                {/* <option value="" disabled selected>Selecione um motorista</option> */}
                                                {motorista.map((pessoa, index) => (
                                                  // pessoa.id === viagens.motorista_id ? <option  selected>SIM</option> : <option value={pessoa.id}>NÃO</option>
                                                  pessoa.id === viagens.motorista_id ? <option selected value="" key={index}>{pessoa.nome}</option> : <option value={pessoa.id} key={index}>{pessoa.nome}</option>
                                                ))}
                                              </Input>
                                      </div>
                                </div>
                                <div className={styles.formItems}>
                                      <div className={styles.formGroup}>
                                              <span>Veiculo</span>
                                              <Input className={styles.select} type="select" required  onChange={(e) => setCar(e.target.value)} value={car}>
                                                  {/* <option value="" disabled selected>Selecione um carro</option> */}
                                                    {/* {veiculo.map((carro, key) => {
                                                      return (
                                                        carro.id === viagens.veiculo_id ? <option selected value="" className={styles.selectOption} key={key}>{`${carro.modelo} - ${carro.placa}`}</option> : <option value={carro.id} className={styles.selectOption} key={key}>{`${carro.modelo} - ${carro.placa}`}</option>
                                                      );
                                                    })} */}

                                                  {Array.isArray(motorista) ? (
                                                      motorista.map((carro, key) => (
                                                        carro.id === viagens.veiculo_id ? <option selected value="" className={styles.selectOption} key={key}>{`${carro.modelo} - ${carro.placa}`}</option> : <option value={carro.id} className={styles.selectOption} key={key}>{`${carro.modelo} - ${carro.placa}`}</option>
                                                      ))
                                                    ) : (
                                                      <option value="" disabled>
                                                        Nenhum motorista encontrado.
                                                      </option>
                                                    )}
                                              </Input>
                                      </div>
                                </div>
                               
                              </div>
                              <div className={styles.blocoInputs}>
                              <div className={styles.formItems}>
                                      <div className={styles.formGroup}>
                                              <span>Saída</span>
                                              <IMaskInput className={styles.formField} type="date" name="saida"   onChange={(e) => validaData(e.target.value)} value={day}/>
                                      </div>
                                </div>
                                <div className={styles.formItems}>
                                      <div className={styles.formGroup}>
                                              <span>Horário</span>
                                              <IMaskInput className={styles.formField} name="horario"   onChange={(e) => validaHora(e.target.value)} value={hour} type="time"/>
                                      </div>
                                </div>                       
                                </div>
                            </div>
                            
                                                  
                            <div>
                              <div className={styles.formItems}>
                                        <div className={styles.formGroup1}>
                                                <span>Observação</span>
                                                <IMaskInput className={styles.formField} name="observacao"   onChange={(e) => setObservacao(e.target.value)} value={observacao} type="text"/>
                                        </div>
                                  </div>  
                            </div>
                          </div> 

                       
                        </div>
                       

                      

                    
                    </section>

                    <section className={styles.campoIntinerario}>
                        <div className={styles.contentParadas}>
                          <h2 className={styles.viagemTitleParadas}>Parada não Planejada:</h2>
                          <div className={styles.viagemBloco2}>

                              <div className={styles.formItems}>
                                        <div className={styles.formGroup}>
                                                <span>Local</span>
                                                <IMaskInput className={styles.formField} name="parada"   onChange={(e) => setLocal(e.target.value)} value={local} type="text" placeholder="EGMA - Escola de Governo do Maranhão"/>
                                        </div>
                              </div>

                              <div className={styles.formItems}>
                                        <div className={styles.formGroup2}>
                                                <span>Data</span>
                                                <IMaskInput className={styles.formField} name="data"   onChange={(e) => validaData(e.target.value)} value={data} required type="date" />
                                        </div>
                              </div>

                              <div className={styles.formItems}>
                                        <div className={styles.formGroup2}>
                                                <span>Hora</span>
                                                <IMaskInput className={styles.formField} name="hora"   onChange={(e) => validaHora2(e.target.value)} value={horaPrevista} required type="time" />
                                        </div>
                              </div> 

                              <div className={styles.formItems2}>
                                <p>Aguardar</p>
                                <Switch value={status} onClick={() => setStatus(!status)} colorScheme='green' size='md'></Switch>
                              </div>

                              <div className={styles.formItems}>
                                      <ButtonComp acao={adicionaParada} estilo={styles.btn}  valor={estadoBotao}  texto="+" />
                              </div>
                          </div>          
                       </div>

                       <h2 className={styles.viagemTitleParadas}>Paradas:</h2>
                       <Table className={styles.table} size="sm">
                                  <thead className={styles.tableHeader}>
                                      <tr>
                                      <th width="5%">ID</th>
                                      <th width="20%">Paradas</th>
                                      <th width="15%">Data</th>
                                      <th width="15%">Aguardar</th>
                                      <th width="10%">Hora Prevista</th>
                                      <th width="15%">Opções</th>
                              
                                      </tr>
                                  </thead>
                                  <tbody id="tabela-viagens" className={styles.tableBody}>
                                    {
                                    
                                    (paradas)?.map((item, index) => (

                                      item.kmfinal === null ?
                                        <tr>
                                        <td>{item.id}</td>
                                        <td>{
                                         
                                          item.id === idParadaAtual ?
                                          closeButton === true && item.kmfinal === null ?
                                          <Input onChange={(e) => setLocalParada(e.target.value)} className={styles.inputParadas} value={localParada}/> :
                                          <p className={styles.tableTextB}>{item.local}</p> : <p className={styles.tableTextB}>{item.local}</p>
                                          }</td>
                                        <td>{
                                          item.id === idParadaAtual && item.kmfinal === null?
                                          closeButton === true ?
                                           <Input onChange={(e) => validaData(e.target.value)} type="date" className={styles.inputParadas} value={dataParada}/> :
                                           <p className={styles.tableTextB}>{formatDataAtual(item.data_prevista)}</p>
                                            : <p className={styles.tableTextB}>{formatDataAtual(item.data_prevista)}</p>
                                          }</td>
                                      
                                        <td>{  item.id === idParadaAtual && item.kmfinal === null ?
                                                item.aguardar === true ?
                                                    closeButton === true ? <Switch onChange={(e) => setStatusParada(!statusParada)}  value={statusParada} colorScheme='green' size='md' />:
                                                    <p className={styles.tableTextB}>Sim</p> 
                                                :   closeButton === true ? <Switch onChange={(e) => setStatusParada(!statusParada)}  value={statusParada} colorScheme='green' size='md' />:
                                                    <p className={styles.tableTextB}>Não</p>
                                                :
                                                item.aguardar === true ? <p className={styles.tableTextB}>Sim</p> : <p className={styles.tableTextB}>Não</p>
                                                
                                                                          

                                        }</td>
                                    
                                          <td>
                         
                                           {
                                          item.id === idParadaAtual && item.kmfinal === null ?
                                          closeButton === true ?
                                           <Input onChange={(e) => validaHora(e.target.value)} type="time" className={styles.inputParadas} value={horaPrevista}/> :
                                           item.hora_final === null ? <p>--:--</p> :
                                           <p className={styles.tableTextB}>{formatHoraAtual(item.hora_final)}</p>
                                            : <p className={styles.tableTextB}>{formatHoraAtual(item.hora_final)}</p>
                                          }
                                          </td>
                                        <td> 
                                          <div className={styles.iconEditContent}>
                                            {
                                               editButton === true  && saveButton === false && item.kmfinal === null && localStorage.getItem("status") !== 'CANCELADA'?
                                               <ButtonComp valor={editButton} estilo={styles.itemButtonEdit}  texto="Editar" acao={() => pegaID(item.id)} />
                                               :
                                               item.id === idParadaAtual ?
                                               <div></div>

                                               :
                                               <ButtonComp valor={editButton} estilo={styles.itemButtonEdit}  texto="Editar" acao={() => pegaID(item.id)} />
                                            }


                                            {saveButton === true && item.id === idParadaAtual  ?
                                                      <svg onClick={() => saveParadas(item.id)}  className={styles.iconEdit} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                      width="30" height="30"
                                                      viewBox="0 0 26 26">
                                                      <path d="M 13 1 C 6.382813 1 1 6.382813 1 13 C 1 19.617188 6.382813 25 13 25 C 19.617188 25 25 19.617188 25 13 C 25 6.382813 19.617188 1 13 1 Z M 13 3 C 18.535156 3 23 7.464844 23 13 C 23 18.535156 18.535156 23 13 23 C 7.464844 23 3 18.535156 3 13 C 3 7.464844 7.464844 3 13 3 Z M 17.1875 7.0625 C 17.039063 7.085938 16.914063 7.164063 16.8125 7.3125 L 11.90625 14.59375 L 9.59375 12.3125 C 9.394531 12.011719 9.011719 11.988281 8.8125 12.1875 L 7.90625 13.09375 C 7.707031 13.394531 7.707031 13.800781 7.90625 14 L 11.40625 17.5 C 11.605469 17.601563 11.886719 17.8125 12.1875 17.8125 C 12.386719 17.8125 12.707031 17.707031 12.90625 17.40625 L 18.90625 8.59375 C 19.105469 8.292969 18.992188 8.011719 18.59375 7.8125 L 17.59375 7.09375 C 17.492188 7.042969 17.335938 7.039063 17.1875 7.0625 Z"></path>
                                                    </svg>
                                                    : 
                                                      <div></div>
                                                    }

                                              {editButton === false && item.id === idParadaAtual  ?
                                                  <svg onClick={validaCheck}  className={styles.iconEdit} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                  width="32" height="32"
                                                  viewBox="0 0 24 24">
                                                  <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 8.7070312 7.2929688 L 7.2929688 8.7070312 L 10.585938 12 L 7.2929688 15.292969 L 8.7070312 16.707031 L 12 13.414062 L 15.292969 16.707031 L 16.707031 15.292969 L 13.414062 12 L 16.707031 8.7070312 L 15.292969 7.2929688 L 12 10.585938 L 8.7070312 7.2929688 z"></path>
                                                </svg>
                                                :
                                                <div></div>
                                                  }
                                          </div>
                                        </td>
                                      </tr>
                                     : <div></div>
                                    ))
                                                
                                    } 
                                   
                                  </tbody>
                              </Table> 
                              <div className={styles.buttonsContent}>
                                  <div className={styles.containerButton}>
                                      {
                                        viagens.status === 'CRIADA' ?
                                        <ButtonComp acao={handleShowFinaliza} estilo={styles.buttonCancelViagem} texto="Cancelar Viagem"/>
                                        : <div></div>
                                        
                                      }
                                </div>
                                <div className={styles.blocoButtonContent}>
                                  <ToastContainer />
                                  <div className={styles.contentButton}>
                                        <ButtonComp acao={previewsPage} texto="Voltar" estilo={styles.buttonAnt}></ButtonComp>
                                  </div>     
                                  <div className={styles.contentButton}>
                                        <ButtonComp acao={validaViagem} texto="Salvar" estilo={styles.buttonPreviews}></ButtonComp>
                                  </div>   
                                </div>  
                              </div>
                    </section>
              
      </main>
      
        
      

      {<Modal show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                  <Modal.Title>Viagem Editada com Sucesso</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button variant="danger" onClick={handleCloseEdit}>
                    FECHAR
                  </Button>
                </Modal.Footer>
              </Modal>
              }

{<Modal className={styles.modalEstilo} show={showFinaliza} onHide={handleCloseFinaliza}>
                <Modal.Header closeButton>
                  <Modal.Title>Deseja realmente cancelar a viagem ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                <Button value={estadoCancelar} variant="success" onClick={validaCancelamento} >
                  SIM
                </Button>
                <Button variant="danger" onClick={handleCloseFinaliza}>
                  NÃO
                </Button>
                </Modal.Footer>
              </Modal>
              }


  {<Modal show={showHour} onHide={handleCloseHour}>
                  <Modal.Header closeButton>
                    <Modal.Title>Informe uma Hora valida</Modal.Title>
                  </Modal.Header>
                  <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseHour}>
                      FECHAR
                    </Button>
                  </Modal.Footer>
                </Modal>
                }

{<Modal show={showData} onHide={handleCloseData}>
                  <Modal.Header closeButton>
                    <Modal.Title>Informe uma data valida</Modal.Title>
                  </Modal.Header>
                  <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseData}>
                      FECHAR
                    </Button>
                  </Modal.Footer>
                </Modal>
                }

{<Modal show={showMotorista} onHide={handleCloseMotorista}>
                  <Modal.Header closeButton>
                    <Modal.Title>Informe um motorista</Modal.Title>
                  </Modal.Header>
                  <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseMotorista}>
                      FECHAR
                    </Button>
                  </Modal.Footer>
                </Modal>
                }


{<Modal show={showVeiculo} onHide={handleCloseVeiculo}>
                  <Modal.Header closeButton>
                    <Modal.Title>Informe uma veiculo</Modal.Title>
                  </Modal.Header>
                  <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseVeiculo}>
                      FECHAR
                    </Button>
                  </Modal.Footer>
                </Modal>
                }
{<Modal className={styles.modalEstilo} show={cancelSucess} onHide={handleCloseSucess}>
                <Modal.Header closeButton>
                  <Modal.Title>Viagem cancelada com sucesso!</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button variant="danger" onClick={handleCloseSucess}>
                    FECHAR
                  </Button>
                </Modal.Footer>
              </Modal>
              }
                
    </section>
  );
};

export default GestorEditaViagem;