
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import styles from "../Veiculo/Veiculo.module.css";
// eslint-disable-next-line no-unused-vars
import { FormGroup, Input, Col, Button } from "reactstrap";
import ButtonComp from "../../Components/Button/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Label from "../../Components/Label/Label";
import * as C from "@chakra-ui/react"; 
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { json, useActionData} from "react-router-dom";
import { Formik } from "formik";
import Loading from "../../Components/Loader/Loading";
import {API_BASE_URL} from '../../services/Constantes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IMaskInput } from "react-imask";
import EstadoMenu from "../../Components/Menu/EstadoMenu";

const Veiculo = () => {

    useEffect(() => {
        document.title = 'Cadastro de Veículo';
        setAtivadoMenu(false)
      }, []);
     // Estado para pegar os gets das opções do banco 
     const [categ, setCateg] = useState([]);
     const [tipo, setTipo] = useState([]);
     const [uso, setUso] = useState([]);
     const [proprietario, setProprietario] = useState([]);

     const [showVeiculo, setShowVeiculo] = useState(false);
     const [showCadastro, setShowCadastro] = useState(false);
     const [showDados, setShowDados] = useState(false);
     const handleShowVeiculo = () => setShowVeiculo(true);
     const handleCloseVeiculo = () => setShowVeiculo(false);
     const handleShowCadastro = () => setShowCadastro(true);
     const handleCloseCadastro = () => setShowCadastro(false);
     const handleShowDados = () => setShowDados(true);
     const handleCloseDados = () => setShowDados(false);
     const [loading, setLoading] = useState(true);

 
     // Estado para os campos com os dados do veiculo
     const [renavam, setRenavam] = useState('');
     const [placa, setPlaca] = useState('');
     const [inputCateg, setInputCateg] = useState('');
     const [inputTipo, setInputTipo] = useState('');
     const [inputUso, setInputUso] = useState('');
     const [inputProp, setInputProp] = useState('');
     const [fabricante, setFabricante] = useState('');
     const [modelo, setModelo] = useState('');
     const [fabricacao, setFabricacao] = useState('');
     const [cor, setCor] = useState('');
     const [motorizacao, setMotorizacao] = useState('');
     const [kmInicial, setKmInicial] = useState('');
     const [status, setStatus] = useState(false);
     const [apolice, setApolice] = useState('');
     const [seguradora, setSeguradora] = useState('');
     const [telefone, setTelefone] = useState('');
     const [veiculos, setVeiculos] = useState([]);

     // Contexto para o estado do menu
     const { ativadoMenu, setAtivadoMenu } = useContext(EstadoMenu);
     let navigate = useNavigate();
     function handlePage(){
       setLoading(true);
       navigate('/');
        
     }

      // Requisição GET para a pegar todos os veiculos
    useEffect(() => {
    setLoading(false);
    window.scrollTo(0, 0);

    const token = localStorage.getItem("token");

    if (token) {
      fetch(`${API_BASE_URL}/api/transporte/tipoVeiculo`, {
        method: "GET",
        headers: {
          "Content-type": "application/json;charset=UTF-8",
          'token': token,
          'perfil': localStorage.getItem("tipo"),
          'Authorization': `Bearer ${token}` 
        }
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Não foi possível realizar a requisição!");
          }
          return response.json();
        })
        .then((data) => {
          setTipo(data);
          setLoading(true);
        })
        .catch((error) => {
          console.log(error);
          setLoading(true);
          notify("Não foi possível realizar a requisição!");
        });
    } else {
      handlePage();

    }
}, []);


 
     // Requisição GET para a categoria do veículo
     useEffect(() => {
        setLoading(false);
        window.scrollTo(0, 0);
        fetch(`${API_BASE_URL}/api/transporte/categoriaVeiculo`, {
            method: "GET",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo"),
                'Authorization': `Bearer ${localStorage.getItem("token")}` 
            }
            }).then((response) => {
                return response.json();
              })
              .then((data) => {
                    setCateg(data);
                setLoading(true);
              })
              .catch((error) => {
                console.log(error)
                setLoading(true);
              });
        
    },[])

 
 
 
     // Requisição GET para os tipos de uso do veículo
     useEffect(() => {
        setLoading(false);
        window.scrollTo(0, 0);
        fetch(`${API_BASE_URL}/api/transporte/tipoUsoVeiculo`, {
            method: "GET",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo"),
                'Authorization': `Bearer ${localStorage.getItem("token")}` 
            }
            }).then((response) => {
                return response.json();
              })
              .then((data) => {
                    setUso(data);
                setLoading(true);
              })
              .catch((error) => {
                console.log(error)
                setLoading(true);
              });
        
    },[])
 
     // Requisção GET para os tipos de status de proprietário do veículo
     useEffect(() => {
        setLoading(false);
        window.scrollTo(0, 0);
        fetch(`${API_BASE_URL}/api/transporte/tipoProprietarioVeiculo`, {
            method: "GET",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo"),
                'Authorization': `Bearer ${localStorage.getItem("token")}` 
            }
            }).then((response) => {
                return response.json();
              })
              .then((data) => {
                    setProprietario(data);
                setLoading(true);
              })
              .catch((error) => {
                console.log(error)
                setLoading(true);
              });
        
    },[])


   
     const onSubmitHandler = (data) => {
        postVeiculo(data);
        console.log(data);
        reset({})
     }
    
    // Requisição POST para cadastrar um veículo
    const postVeiculo = async (vetor) => {
        setLoading(false);
        window.scrollTo(0, 0);
        try {
          const response2 = await fetch(
            `${API_BASE_URL}/api/transporte/veiculo`,
            {
              method: "POST",
              body: JSON.stringify({ 
                "placa": vetor.placa,
                "renavam": vetor.renavam,
                "modelo": vetor.modelo,
                "km_inicial": vetor.kmInicial, 
                "ano_fabricacao": vetor.fabricacao,
                "motorizacao": vetor.motorizacao,
                "cor": vetor.cor,
                "fabricante": vetor.fabricante,
                "apolice": vetor.apolice,
                "seguradora": vetor.seguradora,
                "contato_seguradora": vetor.telefone,
                "is_ativo": status,
                "categoria_id": inputCateg,
                "tipo_uso_id": inputUso,
                "tipo_veiculo_id": inputTipo,
                "tipo_proprietario_id": inputProp
            }), 
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo"),

              },
            }
          );
          const json2 = await response2.json();
          if(response2.ok){
            console.log(json2)
            if (json2.sucesso === false) {
              notify("Veículo não cadastrado! Tente novamente");
              setLoading(true);
            } else {
              notify("Veículo Cadastrado com Sucesso!");
              setLoading(true);
              setRenavam('');
              setFabricacao('');
              setFabricante('');
              setCor('');
              setKmInicial('');
              setPlaca('');
              setModelo('');
              setMotorizacao('');
              setApolice('');
              setSeguradora('');
              setTelefone('');
            }
            
            // console.clear();
          } else {
            if (json2.msg.includes("SQLSTATE[23505]")){
              notify("Veículo já cadastrado!");
              setLoading(true);
            } else {
              notify("Veículo não cadastrado! Tente novamente");
              setLoading(true);
            }     
            // console.clear();
          }
        } catch (error) {
          console.log(error);
          notify(error);
          setLoading(true);
          notify("Veículo não cadastrado!");
          notify("Tente novamente!");
        };
      }

 
    // Função para validar os campos
    let validaCampos = yup.object().shape({
        renavam: yup.string().default(renavam).required("O renavam é obrigatório!"),
        fabricacao: yup.string().default(fabricacao).required("O ano é obrigatório!"),
        fabricante: yup.string().default(fabricante).required("Informe o fabricante do veículo!"),
        cor: yup.string().default(cor).required("Informe a cor do veículo!"),
        kmInicial: yup.string().default(kmInicial).required("Informe o KM Inicial!"),
        placa: yup.string().default(placa).required("Informe a placa do veículo!"),
        modelo: yup.string().default(modelo).required("Informe o modelo do veículo!"),
        motorizacao: yup.string().default(motorizacao).required("Informe a motorização do veículo!"),
        apolice: yup.string().default(apolice),
        seguradora: yup.string().default(seguradora),
        telefone: yup.string().default(telefone),
    

    });
    

    const { register, handleSubmit, formState: { errors }, reset} = useForm({
        resolver: yupResolver(validaCampos),
        
    });

    const notify = (msg) => toast(msg);
    return (
        <section className={styles.cadastroVeiculo}>
           <Header titulo="Cadastro de Veículo" altura="240px"/>
 
            <Menu />    
            <ToastContainer />
            <main className={
              ativadoMenu === true ? styles.mainContent2 : styles.mainContent
            }> 
                <form  onSubmit={handleSubmit(onSubmitHandler)} className={styles.form}>
                    <FormGroup className={styles.formBlock}>
                        
                            <div> 
                        <h2>Dados do Veículo</h2>
                            <div className={styles.formComponents}>
                                <div  className={styles.formItems}>
                                    <div className={
                                      ativadoMenu === true ? styles.formGroupActive : styles.formGroup
                                    }>
                                        <span>Renavam</span>
                                        <IMaskInput className={styles.formField} mask="00000000000" name="renavam" {...register("renavam")}  onChange={(e) => setRenavam(e.target.value)} value={renavam}  type="text"  placeholder="Informe o renavam do veículo"/>
                                    </div>
                                    <p className={styles.messagemErro}>{errors.renavam?.message}</p>
                                </div>
                                <div className={styles.formItems}>
                                    <div className={
                                       ativadoMenu === true ? styles.formGroupActive : styles.formGroup
                                    }>
                                        <span>Ano de Fabricação</span>
                                        <IMaskInput className={styles.formField} {...register("fabricacao")} mask="0000" name="fabricacao"  placeholder="2021 / 2022" onChange={(e) => setFabricacao(e.target.value)} value={fabricacao} type="text"/>
                                    </div>
                                    <p className={styles.messagemErro}>{errors.fabricacao?.message}</p>
                                </div> 

                                <div className={styles.formItems}>
                                    <div className={
                                       ativadoMenu === true ? styles.formGroupActive : styles.formGroup
                                    }>
                                        <span>Tipo de Veículo</span>
                                        <Input   {...register("inputTipo")}  id="select" className={styles.formField} placeholder="Selecione a opção" type="select" onChange={(e) => setInputTipo(e.target.value)} value={inputTipo}>
                                        <option value="" disabled>Selecione uma opção</option>
                                        {/* { tipo.map((items, index) => {
                                           return <option value={items.id} key={index}>{items.nome}</option>
                                        })}; */}
                                         {Array.isArray(tipo) && Array.from(tipo).map((items, index) => {
                                                      return <option value={items.id} key={index}>{items.nome}</option>
                                                })}
                                    
                                    </Input>
                                    </div>           
                                    {/* <p className={styles.messagemErro}>{errors.fabricacao?.message}</p> */}
                                </div>
                            </div>

                            <div className={styles.formComponents}>
                                <div className={styles.formItems}>
                                    <div className={
                                       ativadoMenu === true ? styles.formGroupActive : styles.formGroup
                                    }>
                                        <span>Fabricante</span>
                                        <IMaskInput className={styles.formField} {...register("fabricante")}  name="fabricante" onChange={(e) => setFabricante(e.target.value)} value={fabricante} type="text"  placeholder="Fabricante do Veículo"/>
                                    </div>
                                    <p className={styles.messagemErro}>{errors.fabricante?.message}</p>
                                </div>

                                <div className={styles.formItems}>
                                    <div className={
                                       ativadoMenu === true ? styles.formGroupActive : styles.formGroup
                                    }>
                                        <span>Cor</span>
                                        <IMaskInput className={styles.formField} {...register("cor")}  name="cor"  onChange={(e) => setCor(e.target.value)} value={cor} type="text" placeholder="cor"/>
                                    </div>
                                    <p className={styles.messagemErro}>{errors.cor?.message}</p>
                                </div>

                                <div className={styles.formItems}>
                                    <div className={
                                       ativadoMenu === true ? styles.formGroupActive : styles.formGroup
                                    }>
                                        <span>Tipo de Uso</span>
                                        <Input {...register("inputUso")} id="select" name="tipoUso" className={styles.formField} placeholder="Selecione a opção" type="select" onChange={(e) => setInputUso(e.target.value)} value={inputUso}>
                                          <option value="" disabled>Selecione uma opção</option>
                                          {uso && uso.length > 0 ? (
                                            uso.map((item) => (
                                              <option value={item.id} key={item.id}>{item.nome}</option>
                                            ))
                                          ) : (
                                            <option value="" disabled>Nenhuma opção encontrada</option>
                                          )}
                                        </Input>

                                    </div>
                                </div>
                            </div>

                            <div className={styles.formComponents}>
                                <div className={styles.formItems}>
                                    <div className={
                                       ativadoMenu === true ? styles.formGroupActive : styles.formGroup
                                    }>
                                        <span>Km Inicial</span>
                                        <IMaskInput className={styles.formField} {...register("kmInicial")}  name="kmInicial"  onChange={(e) => setKmInicial(e.target.value)} value={kmInicial} type="text" placeholder="Informe o KM Inicial"/>
                                    </div>
                                    <p className={styles.messagemErro}>{errors.kmInicial?.message}</p>
                                </div>

                                <div className={styles.formItems}>
                                    <div className={
                                       ativadoMenu === true ? styles.formGroupActive : styles.formGroup
                                    }>
                                        <span>Placa</span>
                                        <IMaskInput className={styles.formField} {...register("placa")}  name="placa" onChange={(e) => setPlaca((e.target.value).toUpperCase())} value={placa} type="text"  placeholder="Informe a placa do veículo"/>
                                    </div>
                                    <p className={styles.messagemErro}>{errors.placa?.message}</p>
                                </div>
                                <div className={styles.formItems}>
                                    <div className={
                                       ativadoMenu === true ? styles.formGroupActive : styles.formGroup
                                    }>
                                        <span>Tipo de Proprietário</span>
                                        <Input {...register("inputProp")} id="select" className={styles.formField} placeholder="Selecione a opção" type="select" onChange={(e) => setInputProp(e.target.value)} value={inputProp}>
                                        <option value="" disabled>Selecione uma opção</option>
                                        {proprietario && proprietario.length > 0 ? (
                                          proprietario.map((item) => (
                                            <option value={item.id} key={item.id}>{item.nome}</option>
                                          ))
                                        ) : (
                                          <option value="" disabled>Nenhuma opção encontrada</option>
                                        )}
                                      </Input>

                                    </div>

                                    
                                </div>
                        </div>
                        <div className={styles.formComponents}>
                        <div className={styles.formItems}>
                                <div className={
                                   ativadoMenu === true ? styles.formGroupActive : styles.formGroup
                                }>
                                        <span>Modelo</span>
                                        <IMaskInput className={styles.formField} {...register("modelo")} placeholder="Informe o modelo do veículo"  name="modelo"  onChange={(e) => setModelo(e.target.value)} value={modelo} type="text"/>
                                </div>
                                <p className={styles.messagemErro}>{errors.modelo?.message}</p>
                        </div>

                        <div className={styles.formItems}>
                            <div className={
                               ativadoMenu === true ? styles.formGroupActive : styles.formGroup
                            }>
                                        <span>Motorização</span>
                                        <IMaskInput className={styles.formField} mask="0.0" {...register("motorizacao")}   name="motorizacao"   onChange={(e) => setMotorizacao(e.target.value)} value={motorizacao} type="text"  placeholder="2.0"/>
                            </div>
                            <p className={styles.messagemErro}>{errors.motorizacao?.message}</p>
                        </div>
                        <div className={styles.formItems}>
                            <div className={
                               ativadoMenu === true ? styles.formGroupActive : styles.formGroup
                            }>
                                        <span>Categoria</span>
                                        <Input {...register("categoria")} className={styles.formField} placeholder="Selecione a opção" type="select" onChange={(e) => setInputCateg(e.target.value)} value={inputCateg}>
                                          <option value="" disabled>Selecione uma opção</option>
                                          {categ && categ.length > 0 ? (
                                            categ.map((item, key) => (
                                              <option value={item.id} key={item.id}>{item.nome}</option>
                                            ))
                                          ) : (
                                            <option value="" disabled>Nenhuma opção encontrada</option>
                                          )}
                                        </Input>

                            </div>
                            <p className={styles.messagemErro}>{errors.motorizacao?.message}</p>
                        </div>
                        </div>
                        <div className={styles.formComponents3}>

                                
    
                                <div>
                                    <C.Switch onChange={(e) => setStatus(!status)} value={status} display="flex" alignItems="center" marginTop={10} colorScheme="green" size="md">Ativo</C.Switch>
                                </div>
                                </div>
 
                        </div>
                   
                    </FormGroup>

                    <FormGroup className={styles.formBlock2}>
                        <h2>Dados do Seguro</h2>     
                        <div className={styles.formComponents4}>
                                <div className={styles.formItems}>
                                    <div className={
                                       ativadoMenu === true ? styles.formGroupActive : styles.formGroup
                                    }>
                                            <span>Apólice</span>
                                            <IMaskInput className={styles.formField} maxLength={24} {...register("apolice")}  name="apolice"   onChange={(e) => setApolice((e.target.value).toUpperCase())} value={apolice} id="apolice" type="text"  placeholder="N de Apólices de seguro"/>
                                    </div>
                                    <p className={styles.messagemErro}>{errors.apolice?.message}</p>
                                </div>

                                <div className={styles.formItems}>
                                    <div className={
                                       ativadoMenu === true ? styles.formGroupActive : styles.formGroup
                                    }>
                                            <span>Seguradora</span>
                                            <IMaskInput className={styles.formField} {...register("seguradora")}  name="seguradora"  onChange={(e) => setSeguradora(e.target.value)} value={seguradora} id="seguradora" type="text"  placeholder="Seguradora"/>
                                    </div>
                                    <p className={styles.messagemErro}>{errors.seguradora?.message}</p>
                                </div>

                                <div className={styles.formItems}>
                                    <div className={
                                       ativadoMenu === true ? styles.formGroupActive : styles.formGroup
                                    }>
                                            <span>Telefone</span>
                                            <IMaskInput className={styles.formField} mask="(00) 00000-0000" {...register("telefone")}  name="telefone"  onChange={(e) => setTelefone(e.target.value)} value={telefone} id="telefone" type="text"  placeholder="Informe o número da seguradora"/>
                                    </div>
                                    <p className={styles.messagemErro}>{errors.telefone?.message}</p>
                                </div>

                                
                        </div>    

                        <div className={styles.formItems3}>
                                    <ButtonComp  texto="Cadastrar" estilo={styles.formButton3} tipo='submit'/>
                        </div> 
                            
                    </FormGroup>                   
                </form>
           </main>

           {<Modal className={styles.modalEstilo} show={showVeiculo} onHide={handleCloseVeiculo}>
                <Modal.Header closeButton>
                  <Modal.Title>Veículo já cadastrado!</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button color="danger" onClick={handleCloseVeiculo}>
                    FECHAR
                  </Button>
                </Modal.Footer>
              </Modal>
              } 

    {<Modal className={styles.modalEstilo} show={showCadastro} onHide={handleCloseCadastro}>
                <Modal.Header closeButton>
                  <Modal.Title>Veículo Cadastrado Com Sucesso!</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button color="danger" onClick={handleCloseCadastro}>
                    FECHAR
                  </Button>
                </Modal.Footer>
              </Modal>
              } 

{<Modal className={styles.modalEstilo} show={showDados} onHide={handleCloseDados}>
                <Modal.Header closeButton>
                  <Modal.Title>Informe todos os dados do veículo!</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button color="danger" onClick={handleCloseDados}>
                    FECHAR
                  </Button>
                </Modal.Footer>
              </Modal>
              } 

{loading === false ?
                
                <div className={styles.loader}>
                            <div className={styles.loaderInterno}>
                              <Loading />
                            </div>
                </div>
                :
                <div></div>
                }
        </section>
    );
};

export default Veiculo;