import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  Input,
  Box,
} from "@chakra-ui/react";
import { useState } from "react";
import { FormLabel } from "react-bootstrap";
import { Button } from "@chakra-ui/react";


const ModalComp = ({ dataEdit, isOpen,onClose, handleSave}) => {
  // eslint-disable-next-line no-unused-vars
  const [parada, setLocal] = useState(dataEdit.item || "");
  const [localParada, setLocalParada] = useState(parada.local);
  const [dataParada, setDataParada] = useState(parada.dataOriginal);
  const [estado, setEstado] = useState(false);
  function saveData(dado){
    handleSave(dado);
    onClose()
  }

  function validaDataParada (data) {
    let dataAtual = new Date();
    dataAtual.setDate(dataAtual.getDate());
    let dateFormatado = dataAtual.toLocaleDateString('en-CA');
    let dataInformada = data;


    dataInformada >= dateFormatado ? setDataParada(dataInformada)  : setEstado(!estado)

    
  }

  return (
   <>
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
          <ModalHeader>Alterar Local de Parada</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl display="flex" flexDir="column" gap={4}>
                <Box>
                  <FormLabel>Local:</FormLabel>
                  <Input
                  type="text"
                  value={localParada}
                  onChange={(e) => setLocalParada(e.target.value)}
                  ></Input>
                </Box>

                <Box>
                  <FormLabel>Data:</FormLabel>
                  <Input
                  type="date"
                  value={dataParada}
                  onChange={(e) => validaDataParada(e.target.value)}
                  ></Input>
                </Box> 
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={() => saveData({localParada, dataParada})}>
              SALVAR
            </Button>

            <Button colorScheme="red" mr={3} onClick={onClose}>
              CANCELAR
            </Button>
            
          </ModalFooter>
      </ModalContent>
    </Modal>
   </>
  )
};

export default ModalComp;