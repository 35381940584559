import React from 'react';
import * as RiIcons from 'react-icons/ri';
import styles from "../Menu/Menu.module.css";
import { FiUsers } from "react-icons/fi";
import { FiUserCheck } from "react-icons/fi"; 
import { FaUsers } from "react-icons/fa"; 
import { MdEmergencyShare } from "react-icons/md";
import { MdOutlinePlace } from "react-icons/md";
import { BsCardChecklist } from "react-icons/bs";
export const SiderDataMotorista = [

    {
      title: 'Viagens',
      path: '',
      icon: <MdEmergencyShare id={styles.iconMotorista}/>,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,

      
      subNav: [
  
        {
          title: 'Viagens Avulsa',
          path: '/motorista/listar-viagem',
          icon: <MdEmergencyShare id={styles.iconMotorista}/>
        },
        {
          title: 'Viagens Comum',
          path: '/motorista/listar-viagemComum',
          icon: <MdOutlinePlace id={styles.iconMotorista}/>
        },

      ]
    },

    

  ]; 