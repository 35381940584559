import React, {useEffect, useState, useContext} from "react";
import { useNavigate } from "react-router-dom";
import styles from "../Viagem/ListaViagem.module.css";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import MenuMotorista from "../../Components/Menu/MenuMotorista";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import Button from "../../Components/Button/Button";
// import Input from "../../Components/Input/Input";
import Label from "../../Components/Label/Label"; 
import Loading from "../../Components/Loader/Loading"; 
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import DataTable from "react-data-table-component";
// eslint-disable-next-line no-unused-vars
import $ from "jquery";
import { Input } from "reactstrap";
import CodigoDaViagem from "./CodigoViagem";
import ListViagens from "../../Contexts/ArrayViagens";
import {API_BASE_URL} from '../../services/Constantes';
import { IMaskInput } from "react-imask";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import estilo from "../Solicitante/SetorTabel.module.scss";
import EstadoMenu from "../../Components/Menu/EstadoMenu";

const ListarViagem = () => {
    useEffect(() => {
        document.title = 'Lista de Viagens';
        setAtivadoMenu(false);

      }, []);
    // let $ = require('jquery');
    $.DataTable = require('datatables.net')
  

     // History 
     let detalhar = useNavigate();
     let iniciar = useNavigate();
    // eslint-disable-next-line no-unused-vars
    const [estadoID, setEstadoID] = useState('');
     function handlePage(id){
            setCodigo(id);
            detalhar('/motorista/detalharViagem')
            localStorage.setItem("idViagemAtual", id)
     }

     function nextPage(id) {
        setCodigo(id);
        localStorage.setItem("idViagemAtual", id)
        iniciar('/motorista/iniciar-viagem');
        
       
     }
     
   
     // Contextos
     const {codigo, setCodigo} = useContext(CodigoDaViagem);
     const [viagensMotorista, setViagensMotorista] = useState([]);
     const {ativadoMenu, setAtivadoMenu} = useContext(EstadoMenu);

    const [selectedStatus, setSelectedStatus] = useState('Todos');
    const [showPagination, setShowPagination] = useState(true);
    
    // Estado para pegar as viagens
    const [list, setList] = useState([]);
    const [listEstado, setListEstado] = useState([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let vetorViagens = [];
    const [dataInicio, setDataInicio] = useState('');
    const [dataFinal, setDataFinal] = useState('');
    const [loading, setLoading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [kmInicial, setKmInicial] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [buttonState, setButtonState] = useState(false);
    const [estado, setEstado] = useState('');
    const [idNotificacao, setIdNotificacao] = useState('');
    const [valueUpdate, setValueUpdate] = useState(false);
    
    // Estado para pegar o Motorista da Viagem
    const [motorista, setMotorista] = useState([]);
    // const [formatData, setFormatData] = useState(''); 

     // Funcionalidade de Páginação
    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 5;
    const totalItems = list.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const [applyFilter, setApplyFilter] = useState(false);
    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  
     let cpf = localStorage.getItem("cpf")
 

     useEffect(() => {
      window.scrollTo(0, 0);
  
      fetch(`${API_BASE_URL}/api/transporte/viagemAvulsa/todas/motorista/cpf/${cpf}`, {
        method: "GET",
        headers: { 
          "Content-type": "application/json;charset=UTF-8",
          'token': localStorage.getItem("token"),
          'perfil': localStorage.getItem("tipo")
        }
      })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const vetorViagens = [];
        data.map((item, index) => {
          if (item.status === 'CRIADA' || item.status === 'EM_ANDAMENTO') {
            const exists = vetorViagens.some((viagem) => viagem.id === item.id);
            if (!exists) {
              vetorViagens.push(item);
            }
          }
        });
        console.log(vetorViagens);
        setList(vetorViagens);
        setLoading(true);
        valueUpdate(false);
      })
      .catch((error) => {
        console.log(error)
        setLoading(true);
      });
      
    },[]);

    function fetchViagens () {
      fetch(`${API_BASE_URL}/api/transporte/viagemAvulsa/todas/motorista/cpf/${cpf}`, {
        method: "GET",
        headers: { 
          "Content-type": "application/json;charset=UTF-8",
          'token': localStorage.getItem("token"),
          'perfil': localStorage.getItem("tipo")
        }
      })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const vetorViagens = [];
        data.map((item, index) => {
          if (item.status === 'CRIADA' || item.status === 'EM_ANDAMENTO') {
            const exists = vetorViagens.some((viagem) => viagem.id === item.id);
            if (!exists) {
              vetorViagens.push(item);
            }
          }
        });
        console.log(vetorViagens);
        setList(vetorViagens);
        setLoading(true);
        valueUpdate(false);
      })
      .catch((error) => {
        console.log(error)
        setLoading(true);
      });
    } 
    


    // IMplementação do Código da Notitificação
    useEffect(() => {
      function fetchNotifications() {
        window.scrollTo(0, 0);
        fetch(`${API_BASE_URL}/api/transporte/notifica/nao-lidas/cpf/${cpf}`, {
          method: "GET",
          headers: { 
            "Content-type": "application/json;charset=UTF-8",
            'token': localStorage.getItem("token"),
            'perfil': localStorage.getItem("tipo")
          }
        })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          notify(data[0]?.mensagem)
          console.log(data)
          const id = data[0]?.id_notificado;
          setIdNotificacao(id);
          setLoading(true);
          fetchLeitura(id);
          fetchViagens();
        })
        .catch((error) => {
          console.log(error)
          setLoading(true);
        });
      }
  
      // Chama a função fetchNotifications() a cada 1 minuto
      const interval = setInterval(() => {
        fetchNotifications();
      }, 30000);
  
      // Limpa o intervalo quando o componente é desmontado
      return () => clearInterval(interval);
  
  
    },[]);


    function fetchLeitura(id) {
      fetch(`${API_BASE_URL}/api/transporte/notifica/registrar-leitura/${id}`, {
        method: "GET",
        headers: { 
          "Content-type": "application/json;charset=UTF-8",
          'token': localStorage.getItem("token"),
          'perfil': localStorage.getItem("tipo")
        }
      })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data)
      })
      .catch((error) => {
        console.log(error)
      })

    }

   // formata data
   const formatDataAtual = (data) => {
    var date = new Date(data+'T12:00:00.00-0200');
    let formatData = (new Intl.DateTimeFormat('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'}).format(date))
    return formatData
   }

   const formatHoraAtual = (dado) => {
   
    let formatHora = String (dado)

    return formatHora.replace(':00', '');
   }

   useEffect(() => {
    // Default Data
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    setDataInicio(startOfMonth);
    setDataFinal(endOfMonth);
  }, [setDataInicio, setDataFinal]);

  const tamanhoList = list.filter((item) => {
    if (selectedStatus === 'Todos') {
      return true;
    } else {
      return item.status === selectedStatus;
    }
  }).filter((item) => {
    if (!applyFilter || !dataInicio || !dataFinal) {
      return true;
    } else {
      const itemDate = new Date(item.data_prevista);
      const startDateObj = new Date(dataInicio);
      const endDateObj = new Date(dataFinal);
      return itemDate >= startDateObj && itemDate <= endDateObj;
    }
  }).length;

  const handleFilter = () => {
    let dataInicioAtual = new Date(dataInicio);
    let dataFinalAtual = new Date(dataFinal);
    const formattedDate = dataInicioAtual.toLocaleDateString('pt-BR', {day: '2-digit', month: '2-digit', year: 'numeric'});
    const formattedDate2 = dataFinalAtual.toLocaleDateString('pt-BR', {day: '2-digit', month: '2-digit', year: 'numeric'});
    console.log(formattedDate);
    console.log(formattedDate2);
  
    if (dataFinalAtual < dataInicioAtual) {
      const today = new Date();
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      notify('A data inicial não pode ser maior que a data final.');
      setDataFinal(endOfMonth)
    } else {
      setApplyFilter(true);
    }
  }

  const handleFilter2 = () => {
    setApplyFilter(false);
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    dataInicio(startOfMonth);
    setDataFinal(endOfMonth);
  }


  const notify = (msg) => toast(msg);
  const handleDataInicioChange = (e) => {
    const novaDataInicio = new Date(e.target.value);
    setDataInicio(novaDataInicio);
    if (novaDataInicio > dataFinal) {
      setDataFinal(novaDataInicio);
    }
  };

  const handleDataFinalChange = (e) => {
    const novaDataFinal = new Date(e.target.value);
    if (novaDataFinal < dataInicio) {
      setDataFinal(novaDataFinal);
    } else {
      setDataFinal(novaDataFinal);
    }
  };

   // notificação
  let tipoNotitificacao = localStorage.getItem("tipo");
  useEffect(() => {
  
    const intervalId = setInterval(() => {
      fetch(`${API_BASE_URL}/api/transporte/notifica/nao-lidas/cpf/${cpf}`, {
        method: "GET",
        headers: { 
          "Content-type": "application/json;charset=UTF-8",
          'token': localStorage.getItem("token"),
          'perfil': localStorage.getItem("tipo")
        }
      })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        notify(data[0].mensagem);
        console.log(data)
      })
      .catch((error) => {
        console.log(error)
  
      });
    }, 60000); 

    return () => clearInterval(intervalId);
  }, []);
  
  
 

    return (
        <section className={styles.listViagem}>
        <Header titulo="Listar Viagem" altura="240px"/>
           {
            localStorage.getItem("tipo") === 'gestor' ?
            <Menu />
            :
            <MenuMotorista />
           }

          <ToastContainer />
           <main className={
            ativadoMenu === true ? styles.mainContent2 : styles.mainContent
           }>
        

                    <section className={styles.blockItens}>
                       <div className={styles.blockItensOptions} >
                       <div className={styles.inputContainer}>
                          <div className={styles.inputForm}>
                            <div className={styles.formGroup4}>
                              <span>Data inicial</span>
                              <IMaskInput
                                className={styles.formField}
                                type="date"
                                onChange={handleDataInicioChange}
                                value={dataInicio ? dataInicio.toISOString().slice(0, 10) : ''}
                              />
                            </div>
                          </div>

                          <div className={styles.inputForm}>
                            <div className={styles.formGroup4}>
                              <span>Data final</span>
                              <IMaskInput
                                className={styles.formField}
                                type="date"
                                onChange={handleDataFinalChange}
                                value={dataFinal ? dataFinal.toISOString().slice(0, 10) : ''}
                              />
                            </div>
                          </div>
                        </div>


                            <div className={styles.blockItensButton}> 
                                {
                                  applyFilter === false ?
                                  <Button  acao={handleFilter}  estilo={styles.searchButton} texto="Filtar"  />
                                  :
                                  <Button  acao={handleFilter2}  estilo={styles.clearButton} texto="Limpar"  />
                                }
                               
                            </div>

                                
                       </div>

                     <div className={styles.blockItensOptions2} >
                        
                            <div className={styles.inputForm}>
                            <div className={styles.formGroup}>
                                              <span>Filtrar</span>
                                            <Input id="selectSearch" type="select" className={styles.formField} onChange={(e) => setSelectedStatus(e.target.value)} value={selectedStatus}>
                                              <option disabled value="">Selecione uma opção</option>
                                              <option value="CRIADA">Aguardando</option>
                                              <option value="EM_ANDAMENTO">Em andamento</option>
                                              {/* <option value="FINALIZADA">Finalizada</option>
                                              <option value="CANCELADA">Cancelada</option> */}
                                              <option value="Todos">Todas as Viagens</option>
                                          </Input>
                            </div>
                        </div>

                     </div>
                    </section>

                    <section className={styles.mainTable}>
                      <div className={styles.blocoSetor} id="tabelaViagens">
                                {
                                  <div>
                                    <ul className={estilo.responsiveTable}>
                                      <li className={estilo.tableHeader}>
                                        <div className={estilo.col} id={estilo.col1}>
                                          ID
                                        </div>
                                        <div className={estilo.col} id={estilo.col2}>
                                          Paradas
                                        </div>
                                        <div className={estilo.col} id={estilo.col2}>
                                          Data
                                        </div>
                                        <div className={estilo.col} id={estilo.col2}>
                                          Status
                                        </div>
                                        <div className={estilo.col} id={estilo.col3}>
                                          Opções
                                        </div>
                                      </li>
                                      { list.length > 0 ?
                                      list.filter((item) => {
                                          if (selectedStatus === 'Todos') {
                                            return item.status === 'CRIADA' || item.status === 'EM_ANDAMENTO';
                                          } else {
                                            return item.status === selectedStatus;
                                         
                                          }
                                        }).filter((item) => {
                                          if (!applyFilter || !dataInicio || !dataFinal) {
                                            return true;
                                          } else {
                                            const itemDate = new Date(item.data_prevista);
                                            const startDateObj = new Date(dataInicio);
                                            const endDateObj = new Date(dataFinal);
                                            return itemDate >= startDateObj && itemDate <= endDateObj;
                                          }
                                        }).slice(start, end).map((item, index) => (
                                        <li className={estilo.tableRow} key={index}>
                                          <div className={estilo.col} id={estilo.col1} data-label="ID">
                                            {item.id}
                                          </div>
                                          <div className={estilo.col} id={estilo.col2} data-label="Paradas">
                                            <div className={styles.blocoParadas}>
                                                {
                                                  item.paradas.map((destino, index) => (
                                                    
                                                              
                                                    
                                                  
                                                                      <p className={styles.tableText}>{destino.local}</p>
                                                    
                                                                  
                                                        ))
                                                }
                                              </div>
                                          </div>
                                          <div className={estilo.col} id={estilo.col2} data-label="Data">
                                            {
                                              formatDataAtual(item.data_prevista)
                                            }
                                          </div>
                                          <div className={estilo.col} id={estilo.col2} data-label="Status">
                                            {item.status === 'CRIADA' ? (
                                              <div className={styles.buttonsContainer}>
                                                <p className={styles.tableTextB}>Aguardando</p>
                                              </div>
                                            ) : item.status === 'CANCELADA' ? (
                                              <div className={styles.buttonsContainer}>
                                                <p className={styles.tableTextC}>Cancelada</p>
                                              </div>
                                            ) :
                                            
                                            item.status === "EM_ANDAMENTO" ?

                                            (
                                              <div className={styles.buttonsContainer}>
                                                <p className={styles.tableTextA}>Em Andamento</p>
                                              </div>
                                            )
                                            :
                                            (
                                              <div className={styles.buttonsContainer}>
                                                <p className={styles.tableTextC}>Finalizada</p>
                                              </div>
                                            )}
                                          </div>
                                          <div className={estilo.col} id={estilo.col3} data-label="Opções">
                                            {
                                                  item.status === 'FINALIZADA' || item.status === 'CANCELADA' ? 
                                                  <div className={styles.buttonsContainer}>
                                                  {/* <Button estilo={styles.buttonAddModifer} texto="Iniciar"/>  */}
                                                  <Button acao={() => handlePage(item.id)}  estilo={styles.buttonEdit} texto="Detalhar" /> 
                                                  </div>
                                                  :
                                                  item.status === 'CRIADA' ?
                                              
                                                  <div className={styles.buttonsContainer}>
                                                      <Button acao={() => nextPage(item.id)} estilo={styles.buttonAdd} texto="Iniciar"/> 
                                                      <Button acao={() => handlePage(item.id)}  estilo={styles.buttonEdit} texto="Detalhar" />    
                                                      </div>
                                              :

                                              <div className={styles.buttonsContainer}>
                                              <Button acao={() => nextPage(item.id)} estilo={styles.buttonAdd} texto="Iniciada"/> 
                                              <Button acao={() => handlePage(item.id)}  estilo={styles.buttonEdit} texto="Detalhar" />    
                                              </div> 

                                            
                                            }
                                          </div>
                                        </li>
                                      ))
                                      : <li  className={estilo.tableRow} >
                                      <p className={estilo.col}>Não há viagens cadastradas</p>
                                      </li>
                                    }
                                    </ul>
 
                                    <div className={styles.pagination}>
                                      <ul className={styles.paginationList}>
                                        {tamanhoList > 5 ?
                                        Array.from({ length: totalPages }, (_, i) => (
                                          <li
                                            key={i}
                                            className={`${styles.paginationItem} ${
                                              i + 1 === currentPage ? styles.active : ''
                                            }`}
                                            onClick={() => handlePageChange(i + 1)}
                                          >
                                            {i + 1}
                                          </li>
                                        ))
                                      : <div></div>
                                      }
                                      </ul>
                                    </div>
                                  </div>
                                }
                      </div>

               
                    </section>

           </main>

           {loading === false ?
                
                <div className={styles.loader}>
                            <div className={styles.loaderInterno}>
                              <Loading />
                            </div>
                </div> 
                :
                <div></div>
                }
        </section>
    );
};

export default ListarViagem;