import React, { useState, useEffect, useContext } from "react";
import styles from "../Solicitante/CadastroSolicitante.module.css";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { FormGroup, Form, Input, Row, Col, Button } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Table from "react-bootstrap/Table";
import { IMaskInput } from "react-imask";
import ButtonComp from "../../Components/Button/Button";
import {API_BASE_URL} from '../../services/Constantes';
import Loading from "../../Components/Loader/Loading";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import EstadoMenu from "../../Components/Menu/EstadoMenu";
const EditarSolicitante = () => {

    useEffect(() => {
        document.title = 'Editar Solicitante';
        window.scrollTo(0, 0);
        setAtivadoMenu(false);
      }, []);
    // Estados para os inputs
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [Cpf, setCpf] = useState('');
    const [login, setLogin] = useState('');
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [setor, setSetor] = useState([]);
    const [setorAlter, setSetorAlter] = useState('');
    const [solicitante, setSolicitante] = useState([]);
    const [setorAtual, setSetorAtual] = useState('');
    const [setorSelecionado, setSetorSelecionado] = useState(null);
    let setorFinal = 0;
    const [telefone, setTelefone] = useState('');
    const [categoria, setCategoria] = useState('');
    const [list, setList] = useState([]);
    const [alter, setAlter] = useState(true);
    const [close, setClose] = useState(false);
    const [save, setSave] = useState(false);
    const [del, setDel] = useState(true);
    const [loading, setLoading] = useState(true);
    const [editButton, setEditbutton] = useState(true);
    const [idAtual, setIdAtual] = useState('');
    const [idSetor, setIDSetor] = useState('');
    
    let idSolicitante = localStorage.getItem("idSolicitante");

    // Contexto do Estado do Menu
    const {ativadoMenu, setAtivadoMenu} = useContext(EstadoMenu);

    const notify = (msg) => toast(msg);

    function handleAlterList(id){
        let newList = [...list]; 
        newList.map((item, index) => (
          item.id === id ? (item.nome = setorAlter) : console.log("Não é igual")
        ))
      setList(newList); 
      setEditbutton(true);
      console.log(list)
    }


    function handleEditSetores(id) {
        setIdAtual(id);
        setEditbutton(false)
    }
    function handleCloseButton(id){
        setIdAtual(id);
        setEditbutton(true);
    }

    function handleRemoveList(){

        let newList = [...list];
        newList.map((item, index) => (
            index === idSetor ? newList.splice(idSetor, 1) : console.log("Não é")
        ))
        setList(newList);
        handleClose();
        console.log(list)
       
      }

      function verificaModal(id){
        setIDSetor(id);
        handleShow();
      }


      useEffect(() => {
        setLoading(false);
        window.scrollTo(0, 0); 
        fetch(`${API_BASE_URL}/api/transporte/solicitante/${idSolicitante}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo"),
                'Authorization': `Bearer ${localStorage.getItem("token")}` 
            }
            }).then((response) => {
                return response.json();
              })
              .then((data) => {
                setSolicitante(data);
                setList(data.setores);
                setLoading(true);
               
              })
              .catch((error) => {
                console.log(error)
                setLoading(true);
              }); 

    },[]);

    
    useEffect(() => {
        setLoading(false);
        window.scrollTo(0, 0); 
        fetch(`${API_BASE_URL}/api/transporte/setor`, {
            method: "GET",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo"),
                'Authorization': `Bearer ${localStorage.getItem("token")}` 
            }
            }).then((response) => {
                return response.json();
              })
              .then((data) => {
                setSetor(data);
                setLoading(true);
               
              })
              .catch((error) => {
                console.log(error)
                setLoading(true);
              }); 

    },[]);


    // Cadastro de Solicitante
    const postAlter = async () => {
      setLoading(false);
      window.scrollTo(0, 0);
      try {
        const response2 = await fetch(
          `${API_BASE_URL}/api/transporte/solicitante/${idSolicitante}`,
          {
            method: "PUT",
            body: JSON.stringify({ 
              "nome": nome,
              "cpf": (Cpf).match(/\d/g).join(""),
              "email": email,
              "telefone": telefone,
              "setores": list
          }), 
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              'token': localStorage.getItem("token"),
              'perfil': localStorage.getItem("tipo"),

            },
          }
        );
        const json2 = await response2.json();
       console.log(json2);
       setLoading(true);
       notify("Solicitante editado com sucesso!");
      } catch (error) {
        console.log(error);
        notify(error);
        setLoading(true);
        notify("Não foi possível atualizar o solicitante!");
      };
    }

    // useEffect(() => {
    //     setLoading(false);
    //     window.scrollTo(0, 0);
    //     fetch(`${API_BASE_URL}/api/transporte/setor`, {
    //         method: "GET",
    //         headers: {
    //             "Content-type": "application/json;charset=UTF-8",
    //             'token': localStorage.getItem("token"),
    //             'perfil': localStorage.getItem("tipo"),
    //             'Authorization': `Bearer ${localStorage.getItem("token")}` 
    //         }
    //         }).then((response) => {
    //             return response.json();
    //           })
    //           .then((data) => {
    //             setList(data)
    //             setLoading(true);
    //           })
    //           .catch((error) => {
    //             console.log(error)
    //             setLoading(true);
    //           }); 
        
    // },[]);

    useEffect(() => {
        setCpf(solicitante.cpf);
        setEmail(solicitante.email);
        setNome(solicitante.nome);
        setTelefone(solicitante.telefone);
        setSetor(solicitante.setores);
        console.log(setor)
    }, [solicitante])

       // History 
       let editar = useNavigate();
   
   
        function previewsPage() {
           editar('/gestor/listar-solicitantes');
           
          
        }
    return (
        <section className={styles.cadastro}>
            <Header titulo="Editar Solicitante" altura="240px"/>
            <Menu />
            <ToastContainer />
            <main className={
              ativadoMenu === true ? styles.mainContent2 : styles.mainContent
            }>
                <form className={styles.form}>
                    <FormGroup className={styles.formBlock}>
                            
                            <div>
                                <h2>Dados do Solicitante</h2>
                            </div>

                            <div className={styles.formComponents}>
                                    <div className={styles.formItems}>
                                        <div className={styles.formGroup}>
                                                <span>CPF</span>
                                                <IMaskInput className={styles.formField} name="cpf" mask="000.000.000-00" onChange={(e) => setCpf(e.target.value)} value={Cpf} type="text"  placeholder="Informe o seu CPF"/>
                                        </div>
                                        {/* <p className={styles.messagemErro}>{errors.nome?.message}</p> */}
                                    </div>

                                    <div className={styles.formItems}>
                                        <div className={styles.formGroup}>
                                                <span>Email</span>
                                                <IMaskInput className={styles.formField} name="email"  onChange={(e) => setEmail(e.target.value)} value={email} type="text"  placeholder="email@gmail.com"/>
                                        </div>
                                        {/* <p className={styles.messagemErro}>{errors.nome?.message}</p> */}
                                     </div>

                                    <div className={styles.formItems}>
                                        <div className={styles.formGroup}>
                                                <span>Nome</span>
                                                <IMaskInput className={styles.formField}  name="nome"   onChange={(e) => setNome(e.target.value)} value={nome} type="text" placeholder="Informe o nome do solicitante"/>
                                        </div>
                                        {/* <p className={styles.messagemErro}>{errors.cnh?.message}</p> */}
                                    </div>
                            </div>

                            <div className={styles.formComponents}>

                             

                                    <div className={styles.formItems}>
                                        <div className={styles.formGroup}>
                                                <span>Telefone</span>
                                                <IMaskInput className={styles.formField}  name="telefone"  mask="(00) 00000-0000" placeholder="(XX) X XXXX XXXX" required="" data-js="phone"  onChange={(e) => setTelefone(e.target.value)} value={telefone} type="tel"/>
                                        </div>
                                        {/* <p className={styles.messagemErro}>{errors.cnh?.message}</p> */}
                                    </div>
                            </div>

                     
            
                    </FormGroup> 

                    <FormGroup className={styles.formBlock}>
                        
                                   <div>
                                      <h2>Setores</h2>
                                   </div>
                                   <div className={styles.formItems}>
                                    {/* <div className={styles.formGroup}>
                                      <span>Setor</span>
                                      <Input className={styles.formField} name="setor" onChange={handleSetorChange} value={setorAtual} type="select" placeholder="Nome do setor">
                                        <option value="" disabled>Selecione uma opção</option>
                                        {setor.map((items, index) => {
                                          return <option value={items.id} key={index}>{items.nome}</option>
                                        })}
                                      </Input>
                                    </div> */}

                                    {/* <div className={styles.blocoButton}>
                                      <ButtonComp acao={() => validaContato()} texto="+" estilo={styles.formButtonPlus} tipo="button" />
                                    </div> */}

                                  </div>
                                   {
                                    list.length > 0 &&
                                               
                                    <Table className={styles.table} size="sm">
                                        <thead>
                                          <tr className={styles.tableHeader}>
                                            <th className={styles.itemHidden}>
                                              <div className={styles.tableName}>ID</div>
                                            </th>
                                            <th>
                                            <div className={styles.tableName}>Setor</div>
                                            </th>
                                            <th>
                                            <div className={styles.tableName}>Opção</div>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody display="flex" align="center">
                                              {
                                              list.map((item, index) => (
                
                                                <tr key={index+1}>
                                                <td width="10%" className={styles.itemHidden}>
                                                  <div className={styles.numbers}>{index+1}</div>
                                                </td>
                                        
                                                <td width="30%">
                                                  <div className={styles.numbers}>{
                                                    item.nome
                                                    
                                                  }</div>
                                                </td>

                                                <td width="10%">
                                                  <div className={styles.numbers}>
                                                        <div className={styles.buttonsBloco}>
                                                            <ButtonComp acao={() => verificaModal(index)} tipo="button" estilo={styles.formButtonClose} texto="Remover"/>
                                                        </div>
                                                     
                                                  </div>
                                                </td>
                                              </tr>
                                  
                                            ))
                                              }
                                        </tbody>

                                    </Table>
}
                                    <div className={styles.blocoButton2}>
                                        <ButtonComp acao={() =>  previewsPage()} texto="Voltar" estilo={styles.formButton} tipo="button"/>
                                        <ButtonComp acao={() => postAlter()} texto="Salvar" estilo={styles.formButton} tipo="button"/>
                                    </div>
                    </FormGroup> 


                </form>
            </main>

            {loading === false ?
                
                <div className={styles.loader}>
                            <div className={styles.loaderInterno}>
                              <Loading />
                            </div>
                </div>
                :
                <div></div>
                }

{<Modal className={styles.modalEstilo} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Deseja realmente remover o setor?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                <Button color="success" onClick={handleRemoveList}>
                    SIM
                </Button>
                  <Button color="danger" onClick={handleClose}>
                    NÃO
                  </Button>
                </Modal.Footer>
              </Modal>
              }
        </section>
    );
};

export default EditarSolicitante;