/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import * as C from "@chakra-ui/react";
import styles from "../Motorista/MotoristaAvulsaDetalhe.module.css";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import MenuMotorista from "../../Components/Menu/MenuMotorista";
import Title from "../../Components/Title";
// import { Switch } from '@chakra-ui/react'
// import Table from 'react-bootstrap/Table'; 
import Button from "../../Components/Button/Button";
import Input from "../../Components/Input/Input"; 
import Label from "../../Components/Label/Label";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal } from "react-bootstrap";
import ButtonModal from "react-bootstrap/Button";
import Loading from "../../Components/Loader/Loading";
import CodigoDaViagem from "../Viagem/CodigoViagem";
import {API_BASE_URL} from '../../services/Constantes';
import EstadoMenu from "../../Components/Menu/EstadoMenu";
import {
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  UncontrolledTooltip
} from "reactstrap";



const MotoristaAvulsaDetalhe = (props) => {
  useEffect(() => {
    document.title = 'Viagens - Detalhamento';
    setAtivadoMenu(false);
  }, []);

  // Contexto 
  const {codigo, setCodigo} = useContext(CodigoDaViagem);
  let codigoEstado = codigo;
  const [viagens, setViagens] = useState([]);
  const [local, setLocal] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [origem, setOrigem] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [destino, setDestino] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState(false);
  const [data, setData] = useState('');
  const [estadoBotao, setEstadoBotao] = useState(false);
  const [kmInicial, setKmInicial] = useState('');
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState();
  const [motorista, setMotorista] = useState([]);
  const [dataViagem, setDataViagem] = useState('');
  let paradas;
  const {ativadoMenu, setAtivadoMenu} = useContext(EstadoMenu);


  // Modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [buttonState, setButtonState] = useState(false);
       // History
       let navigate = useNavigate();
       // eslint-disable-next-line no-unused-vars
       let pageViagem = useNavigate();
       function previewsPage(){
             
              navigate('/motorista/listar-viagem')
       
       }


    useEffect(() => {
      window.scrollTo(0, 0);
      fetch(`${API_BASE_URL}/api/transporte/viagemAvulsa/buscarPorId/${codigo}`, {
          method: "GET",
          headers: {
              "Content-type": "application/json;charset=UTF-8",
              'token': localStorage.getItem("token"),
              'perfil': localStorage.getItem("tipo")
          }
          }).then((response) => {
              return response.json();
            })
            .then((data) => {
              setViagens(data);
              setDataViagem(formatDataAtual(viagens.data_prevista));
            })
            .catch(() => {});
  },[])

  useEffect(() => {
    fetch(`${API_BASE_URL}/api/transporte/motorista`, {
        method: "GET",
        headers: {
            "Content-type": "application/json;charset=UTF-8",
            'token': localStorage.getItem("token"),
            'perfil': localStorage.getItem("tipo")
        }
        }).then((response) => {
            return response.json();
          })
          .then((data) => {
            setMotorista(data);
            setOrigem('STC')
            motorista.map((pessoa, index) => (
              pessoa.id === viagens.motorista_id ? setNome(pessoa.nome) : console.log("Motorista Inválido")
            ));
            setLoading(true);
          })
          .catch(() => {});
},[])


    

    function pegaParadas (dado) {
      Object.keys(dado).forEach((item) => {
        item === 'paradas' ? paradas = dado[item]: console.log("Erro")
      })
    }

    const formatDataAtual = (data) => {
      var date = new Date(data+'T12:00:00.00-0200');
      let formatData = (new Intl.DateTimeFormat('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'}).format(date))
      return formatData
     }

     const formatHoraAtual = (dado) => {
   
      let formatHora = String (dado)

      return formatHora.replace(':00', '');
     }
    
    pegaParadas(viagens)
    useEffect(() => {
      // Defautl Data
      let dataAtual = new Date();
      dataAtual.setDate(dataAtual.getDate());
      let dateFormatado = dataAtual.toLocaleDateString('en-CA');
      console.log(dateFormatado);
      setData(dateFormatado);
    }, [])

  return (
    <section className={styles.avulsaViagem}>
       <Header titulo="Detalhamento de Viagem Avulsa" altura="240px"/>
       {
            localStorage.getItem("tipo") === 'gestor' ?
            <Menu />
            :
            <MenuMotorista />
           }
      <main className={
        ativadoMenu === true ? styles.mainContent2 : styles.mainContent
      }> 
                    <section className={styles.campoViagem}>  
                       <div className={styles.blocoDetalhes}>
                          <div className={styles.viagemBloco2}>
                            <h2 className={styles.labelTitle}>Dados da Viagem</h2>
                              <div className={styles.blocoInput}>
                                <Label estilo={styles.labelBlocos} texto="ID da Viagem:"/>
                                {/* <Label estilo={styles.labelBlocos} texto={"VA -"+viagens.id}/> */}
                                {viagens.id &&
                                <Label estilo={styles.labelBlocos} texto={"VA - "+viagens.id}/> }
                              </div>
                              <div className={styles.blocoInput}>
                                  <Label estilo={styles.labelBlocos} texto="Origem:"/>
                                  {/* <Input estilo={styles.inputDetails}  valor={viagens.ponto_origem} habilidato={true}  /> */}
                                  {viagens.ponto_origem &&
                                <Label estilo={styles.labelBlocos} texto={viagens.ponto_origem}/> }
                              </div>
  
                              <div className={styles.blocoInput}>
                                  <Label estilo={styles.labelBlocos} texto="Motorista:"/>
                                  {/* <Input estilo={styles.inputDetails} valor={nome}  habilidato={true}  /> */}
                                  {nome &&
                                <Label estilo={styles.labelBlocos} texto={nome}/> }
                              </div>

                              <div className={styles.blocoInput}>
                                  <Label estilo={styles.labelBlocos} texto="Data Prevista:"/>
                                  {/* <Input estilo={styles.inputDetails}  valor={dataViagem} habilidato={true}  /> */}
                                  {dataViagem &&
                                <Label estilo={styles.labelBlocos} texto={dataViagem}/> }
                              </div> 

                              <div className={styles.blocoInput}>
                                  <Label estilo={styles.labelBlocos} texto="Status da viagem:"/>
              

                                    {viagens.status === 'FINALIZADA' ?
                                      <Label estilo={styles.labelBlocos} texto="Finalizada"/>
                                    :
                                    viagens.status === 'CRIADA' ?
                                    <Label estilo={styles.labelBlocos} texto="Aguardando Motorista"/>
                                    :
                                    viagens.status === 'CANCELADA' ?
                                    <Label estilo={styles.labelBlocos} texto="Cancelada"/>
                                    :
                                    <Label estilo={styles.labelBlocos} texto="Em Andamento"/>
                                    }
                              </div>
  
                              <div id={styles.loaderViagem} className={styles.loader}>
                                    {!loading && <Loading />}
                            </div>
                          </div>
                          <h2 className={styles.labelTitle}>Paradas</h2>
                          <section className={styles.mainTable}>
                            
                                  {(paradas)?.map((item, index) => (
                                        item.local === 'STC' && item.is_ultima_parada === false ?
                                        <div></div> :
                                          <div key={index} className={styles.cardParada}>
                                            <div className={styles.cardTitle}>
                                              <p className={styles.cardTexto}>{item.local}</p>
                                            </div>

                                            <div className={styles.cardsContent}>
                                              <p className={styles.cardsItems}>ID: PA - {item.id}</p>
                                              <p className={styles.cardsItems}>Data: {item.data_prevista && formatDataAtual(item.data_prevista)}</p>
                                              <p className={styles.cardsItems}>Hora Final: {item.hora_final && formatHoraAtual(item.hora_final)}</p>
                                              <p className={styles.cardsItems}>Status: {viagens.status === 'CRIADA' ? 
                                                  'Aguardando' :
                                                  viagens.status === 'FINALIZADA' ? 
                                                  'Finalizada' :
                                                  viagens.status === 'CANCELADA' ? 
                                                  'Cancelada' :
                                                  'Em andamento'
                                            }</p>

                                            </div>
                                          </div>
                                      
                                      ))}  
                          </section>
                        </div>
                       <div className={styles.contentArea}>
                          <h2 className={styles.labelTitle}>Observação:</h2>
                          <p>{viagens['observacao']}</p>
                       </div>
                       
                       <div className={styles.contentButton}>
                          <Button acao={previewsPage} texto="Voltar" estilo={styles.buttonPreviews}></Button>
                       </div>
                    </section>

                            
      </main>
    </section>
  );
};

export default MotoristaAvulsaDetalhe;