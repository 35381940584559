import React from 'react';
import * as RiIcons from 'react-icons/ri';
import styles from "../Menu/Menu.module.css";
import {HiUserGroup} from "react-icons/hi";
import { FiUsers } from "react-icons/fi";
import { FiUserCheck } from "react-icons/fi"; 
import { FaUsers } from "react-icons/fa"; 
import { MdEmergencyShare } from "react-icons/md";
import { MdOutlinePlace } from "react-icons/md";
import { BsCardChecklist } from "react-icons/bs";

export const SiderDataSolicitante = [



    {
      title: 'Passageiro',
      path: '',
      icon: <FaUsers id={styles.iconMotorista}/>,


      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,

      
      subNav: [
        {
          title: 'Cadastro',
          path: '/gestor/cadastro-passageiro',
          icon: <BsCardChecklist id={styles.iconMotorista2} />
  

        },

        {
          title: 'Passageiros',
          path: '/gestor/listar-passageiros',
          icon:  <FaUsers id={styles.iconMotorista}/>
        },

      ]
    },
    

    { 
      title: 'Solicitações',
      icon: <FiUserCheck id={styles.iconMotorista}/>,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
  
      subNav: [
        {
          title: 'Criar',
          path: '/solicitante/cadastro-solicitacao',
          icon: <BsCardChecklist id={styles.iconMotorista2} />
        },

        {
          title: 'Listar',
          path: '/solicitante/listar-solicitacoes',
          icon: <i id={styles.iconGestor} className="fa-sharp fa-regular fa-map"></i>  
        }
      ]
    },
  ]; 