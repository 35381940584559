import React, { useState, useEffect, useContext } from "react";
import styles from "../Solicitante/CadastroSolicitante.module.css";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import MenuSolicitante from "../../Components/Menu/MenuSolicitante";
import MenuMotorista from "../../Components/Menu/MenuMotorista";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { FormGroup, Form, Input, Row, Col, Button } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Table from "react-bootstrap/Table";
import { IMaskInput } from "react-imask";
import ButtonComp from "../../Components/Button/Button"; 
import {API_BASE_URL} from '../../services/Constantes';
import Loading from "../../Components/Loader/Loading";
import EstadoMenu from "../../Components/Menu/EstadoMenu";

const Cadastro = () => { 
  window.scrollTo(0, 0);
    useEffect(() => {
        document.title = 'Cadastro de Passageiro';
        setAtivadoMenu(false);
      }, []);
    // Estados para os inputs

    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [loading, setLoading] = useState(true);
    const [cpf, setCpf] = useState('');
    const notify = (msg) => toast(msg);
    let tipoUsuario = localStorage.getItem("tipo");
    // Contexto para o Estado do Menu
    const { ativadoMenu, setAtivadoMenu } = useContext(EstadoMenu);
     
    const buscaCPF = async () => {
      setLoading(false);
      window.scrollTo(0, 0);
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/transporte/usuario/cpf/${cpf.match(/\d/g).join("")}`,
          {
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              'token': localStorage.getItem("token"),
              'perfil': localStorage.getItem("tipo")
            },
          }
        ); 
        if(response.ok) {
          const json = await response.json();
          console.log(json);
          setNome(json.str_nome);
          setEmail(json.str_email);
          setTelefone(json.str_telefone);
          notify("Usuário encontrado!");
          setLoading(true);
        } else {
          const json = await response.json();
          console.log(json);
          notify(json.msg)
          setLoading(true);
        }
       
      } catch (error) {
        notify('Não autorizado');
        console.log(error)
        setLoading(true);
        // console.clear();
     
      };
    
  }

    // Cadastro de Solicitante
    const postPassageiro = async () => {
      setLoading(false);
      window.scrollTo(0, 0);
      try {
        const response2 = await fetch(
          `${API_BASE_URL}/api/transporte/passageiro`,
          {
            method: "POST",
            body: JSON.stringify({ 
              "nome": nome,
              "email": email,
              "telefone": telefone,
          }), 
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              'token': localStorage.getItem("token"),
              'perfil': localStorage.getItem("tipo"),

            },
          }
        );
        const json2 = await response2.json();
       console.log(json2);
       setLoading(true);
       notify("Passageiro cadastrado com sucesso!");
       setNome("");
       setTelefone("");
       setEmail("");
      } catch (error) {
        console.log(error);
        notify(error);
        setLoading(true);
        notify("Não foi possível realizar o cadastro!");
      };
    }
    return (
        <section className={styles.cadastro}>
            <Header titulo="Cadastro de Passageiro" altura="240px"/>
            {
              tipoUsuario === 'gestor' ?
              <Menu /> :
              tipoUsuario === 'motorista' ?
              <MenuMotorista /> :
              <MenuSolicitante />
            }
            <ToastContainer />
            <main className={
              ativadoMenu === true ? styles.mainContent2 : styles.mainContent
            }>
                <form className={styles.form}>
                    <FormGroup className={styles.formBlock}>
                            
                            <div>
                                <h2>Dados do Passageiro</h2>
                            </div>



                            <div className={styles.formItems}>
                                                <div className={styles.formGroup}>
                                                        <span>CPF</span>
                                                        <IMaskInput className={styles.formField}  name="cpf" mask="000.000.000-00" onChange={(e) => setCpf(e.target.value)} value={cpf} type="text"  placeholder="Informe o seu CPF"/>
                                                </div>
                                                

                                                <div className={styles.formItems}>
                                                  <ButtonComp tipo="button" acao={buscaCPF} texto="Buscar" estilo={styles.formButton}/>
                                                </div>
                            </div>
                            <div className={styles.formComponents}>
                                 
                                    <div className={styles.formItems}>
                                        <div className={styles.formGroup}>
                                                <span>Nome</span>
                                                <IMaskInput className={styles.formField}  name="nome"   onChange={(e) => setNome(e.target.value)} value={nome} type="text" placeholder="Informe o nome do solicitante"/>
                                        </div>
                                        {/* <p className={styles.messagemErro}>{errors.cnh?.message}</p> */}
                                    </div>

                                    <div className={styles.formItems}>
                                        <div className={styles.formGroup}>
                                                <span>Email</span>
                                                <IMaskInput className={styles.formField} name="email"  onChange={(e) => setEmail(e.target.value)} value={email} type="text"  placeholder="email@gmail.com"/>
                                        </div>
                                        {/* <p className={styles.messagemErro}>{errors.nome?.message}</p> */}
                                     </div>

                                     <div className={styles.formItems}>
                                        <div className={styles.formGroup}>
                                                <span>Telefone</span>
                                                <IMaskInput className={styles.formField}  name="telefone"  mask="(00) 00000-0000" placeholder="(XX) X XXXX XXXX" required="" data-js="phone"  onChange={(e) => setTelefone(e.target.value)} value={telefone} type="tel"/>
                                        </div>
                                        {/* <p className={styles.messagemErro}>{errors.cnh?.message}</p> */}
                                    </div>
                            </div>
                     
                                    <div className={styles.blocoButton2}>
                                        <ButtonComp acao={() => postPassageiro()} texto="Cadastrar" estilo={styles.formButton} tipo="button"/>
                                    </div>
            
                    </FormGroup> 


                </form>
            </main>

            {loading === false ?
                
                <div className={styles.loader}>
                            <div className={styles.loaderInterno}>
                              <Loading />
                            </div>
                </div>
                :
                <div></div>
                }
        </section>
    );
};

export default Cadastro;