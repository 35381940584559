import React, { useState, useEffect } from "react";
import styles from "../Solicitante/CadastroSolicitante.module.css";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { FormGroup, Form, Input, Row, Col, Button } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Table from "react-bootstrap/Table";
import { IMaskInput } from "react-imask";
import ButtonComp from "../../Components/Button/Button";
import {API_BASE_URL} from '../../services/Constantes';
import Loading from "../../Components/Loader/Loading";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

const EditarSolicitacao = () => {

    useEffect(() => {
        document.title = 'Editar Solicitação';
        window.scrollTo(0, 0);
      }, []);
    // Estados para os inputs
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    // Estados dos inputs
    const [origem, setOrigem] = useState('');
    const [finalidade, setFinalidade] = useState('');
    const [referencia, setReferencia] = useState('');
    const [setor, setSetor] = useState('');
    const [hora, setHora] = useState('');
    const [data, setData] = useState('');
    const [listPassageiros, setListPassageiros] = useState([]);
    const [paradas, setParadas] = useState([])
    const [list, setList] = useState([]);
    const [setoresList, setSetoresList] = useState([]);
    const [pessoa, setPessoa] = useState('');
    const [pasList, setPasList] = useState([]);
    //

    const [Cpf, setCpf] = useState('');
    const [login, setLogin] = useState('');
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [setorAlter, setSetorAlter] = useState('');
    const [solicitante, setSolicitante] = useState([]);
    const [setorAtual, setSetorAtual] = useState('');
    const [setorSelecionado, setSetorSelecionado] = useState(null);
    let setorFinal = 0;
    const [telefone, setTelefone] = useState('');
    const [categoria, setCategoria] = useState('');

    
    const [alter, setAlter] = useState(true);
    const [close, setClose] = useState(false);
    const [save, setSave] = useState(false);
    const [del, setDel] = useState(true);
    const [loading, setLoading] = useState(true);
    const [editButton, setEditbutton] = useState(true);
    const [idAtual, setIdAtual] = useState('');
    const [idSetor, setIDSetor] = useState('');
    
    let idSolicitante = localStorage.getItem("idSolicitante");
    let idSolicitacao = localStorage.getItem("idSolicitacao");

    const notify = (msg) => toast(msg);

    function handleAlterList(id){
        let newList = [...list]; 
        newList.map((item, index) => (
          item.id === id ? (item.nome = setorAlter) : console.log("Não é igual")
        ))
      setList(newList); 
      setEditbutton(true);
      console.log(list)
    }


    function handleEditSetores(id) {
        setIdAtual(id);
        setEditbutton(false)
    }
    function handleCloseButton(id){
        setIdAtual(id);
        setEditbutton(true);
    }

    function handleRemoveList(){

        let newList = [...list];
        newList.map((item, index) => (
            index === idSetor ? newList.splice(idSetor, 1) : console.log("Não é")
        ))
        setList(newList);
        handleClose();
        console.log(list)
       
      }

      function verificaModal(id){
        setIDSetor(id);
        handleShow();
      }


      useEffect(() => {
        setLoading(false);
        window.scrollTo(0, 0); 
        fetch(`${API_BASE_URL}/api/transporte/solicitacao/${idSolicitacao}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo"),
                'Authorization': `Bearer ${localStorage.getItem("token")}` 
            }
            }).then((response) => {
                return response.json();
              })
              .then((data) => {
                setList(data);
                setLoading(true);
                console.log(list)
              })
              .catch((error) => {
                console.log(error)
                setLoading(true);
              }); 

    },[]);

    
    useEffect(() => {
        setLoading(false);
        window.scrollTo(0, 0); 
        fetch(`${API_BASE_URL}/api/transporte/solicitante/setores/${idSolicitante}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo"),
                'Authorization': `Bearer ${localStorage.getItem("token")}` 
            }
            }).then((response) => {
                return response.json();
              })
              .then((data) => {
                setSetoresList(data);
                setLoading(true);
              })
              .catch((error) => {
                console.log(error)
                setLoading(true);
              }); 

    },[]);


    // Cadastro de Solicitante
    const postAlter = async () => {
      setLoading(false);
      window.scrollTo(0, 0);
      try {
        const response2 = await fetch(
          `${API_BASE_URL}/api/transporte/solicitacao/${idSolicitacao}`,
          {
            method: "PUT",
            body: JSON.stringify({ 
              "origem": origem,
              "finalidade": finalidade,
              "ponto_referencia": referencia,
              "setor_id": setor,
              "hora_prevista": hora,
              "data_prevista": data,
              "passageiros": pasList,
              "paradas": paradas
          }), 
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              'token': localStorage.getItem("token"),
              'perfil': localStorage.getItem("tipo"),

            },
          }
        );
        const json2 = await response2.json();
       console.log(json2);
       setLoading(true);
       notify("Solicitação editada com sucesso!");
      } catch (error) {
        console.log(error);
        notify(error);
        setLoading(true);
        notify("Não foi possível atualizar a solicitação!");
      };
    }


    useEffect(() => {
        setOrigem(list.origem);
        setFinalidade(list.finalidade)
        setReferencia(list.ponto_referencia);
        setSetor(list.setor_id);
        setHora(list.hora_prevista);
        setData(list.data_prevista);
        setParadas(list.paradas);
        setListPassageiros(list.passageiros);
        setPasList(list.passageiros);
        console.log(listPassageiros);
    }, [list])

       // History 
       let editar = useNavigate();
   
   
        function previewsPage() {
           editar('/gestor/listar-solicitacao');
           
          
        }

        function handleRemovePassList(id) {
            const newList = pasList.filter((item) => item.id !== id);
            setPasList(newList);

          }

          function handleValidaDados(value) {
            const [id, nome] = value.split('-');
            handleSavePasList({ id, nome });
          }
          
          function handleSavePasList(dado) {
            // Verifica se o passageiro já foi adicionado na lista
            const alreadyAdded = pasList.some((item) => item.id === dado.id);
            if (alreadyAdded) {
              notify("Passageiro já adicionado");
              return;
            }
          
            // Adiciona o passageiro na lista
            const newList = [...pasList, { id: dado.id, nome: dado.nome }];
            setPasList(newList);
          }

    // Função de validação de data
    function validaData (dado) {

      const data = new Date(dado);
      const dia = data.getDate().toString().padStart(2, "0");
      const mes = (data.getMonth() + 1).toString().padStart(2, "0");
      const ano = data.getFullYear();

      const dataFormatada = `${dia}/${mes}/${ano}`;
      return dataFormatada
  }

  function validaHora(hora){
    const horario = hora
    const horarioSemSegundos = horario.replace(":00", "");
    return horarioSemSegundos;
  }
    return (
        <section className={styles.cadastro}>
            <Header titulo="Editar Solicitação" altura="240px"/>
            <Menu />
            <ToastContainer />
            <main className={styles.mainContent}>
                <form className={styles.form}>
                    <FormGroup className={styles.formBlock}>
                            
                            <div>
                                <h2>Dados do Solicitação</h2>
                            </div>

                            <div className={styles.formComponents}>
                                    <div className={styles.formItems}>
                                        <div className={styles.formGroup}>
                                                <span>Origem</span>
                                                <IMaskInput className={styles.formField} name="origem"  onChange={(e) => setOrigem(e.target.value)} value={origem} type="text"  placeholder="Informe o ponto de origem"/>
                                        </div>
                                        {/* <p className={styles.messagemErro}>{errors.nome?.message}</p> */}
                                    </div>

                                    <div className={styles.formItems}>
                                        <div className={styles.formGroup}>
                                                <span>Finalidade</span>
                                                <IMaskInput className={styles.formField} name="finalidade"  onChange={(e) => setFinalidade(e.target.value)} value={finalidade} type="text"  placeholder="Informe a finalidade da viagem"/>
                                        </div>
                                        {/* <p className={styles.messagemErro}>{errors.nome?.message}</p> */}
                                     </div>

                                    <div className={styles.formItems}>
                                        <div className={styles.formGroup}>
                                                <span>Ponto de Referência</span>
                                                <IMaskInput className={styles.formField}  name="referencia"   onChange={(e) => setReferencia(e.target.value)} value={referencia} type="text" placeholder="Informe um ponto de refêrencia"/>
                                        </div>
                                        {/* <p className={styles.messagemErro}>{errors.cnh?.message}</p> */}
                                    </div>
                            </div>

                            <div className={styles.formComponents}>

                             

                                    <div className={styles.formItems}>
                                         <div className={styles.formGroup}>
                                            <span>Setor</span>
                                            <Input className={styles.formField} name="setor" onChange={(e) => setSetor(e.target.value)} value={setor} type="select" placeholder="Nome do setor">
                                                <option value="" disabled>Selecione uma opção</option>
                                                {setoresList.map((items, index) => {
                                                return <option value={items.id} key={index}>{items.nome}</option>
                                                })}
                                            </Input>
                                            </div>
                                        {/* <p className={styles.messagemErro}>{errors.cnh?.message}</p> */}
                                    </div>

                                    <div className={styles.formItems}>
                                        <div className={styles.formGroup}>
                                                <span>Data</span>
                                                <IMaskInput className={styles.formField} name="data"  onChange={(e) => setData(e.target.value)} value={data} type="date"/>
                                        </div>
                                        {/* <p className={styles.messagemErro}>{errors.nome?.message}</p> */}
                                     </div>

                                     <div className={styles.formItems}>
                                        <div className={styles.formGroup}>
                                                <span>Hora</span>
                                                <IMaskInput className={styles.formField} name="hora"  onChange={(e) => setHora(e.target.value)} value={hora} type="time"/>
                                        </div>
                                        {/* <p className={styles.messagemErro}>{errors.nome?.message}</p> */}
                                     </div>
                            </div>

                     
            
                    </FormGroup> 

                    <FormGroup className={styles.formBlock}>
                        
                                   <div>
                                      <h2>Passageiros</h2>
                                   </div>
                                   <div className={styles.formItems}>

                                  </div>

                                   <div className={styles.formItems}>
                                         <div className={styles.formGroup}>
                                            <span>Passageiros</span>
                                            <Input
                                                className={styles.formField}
                                                name="passageiro"
                                                onChange={(e) => setPessoa(e.target.value)}
                                                value={pessoa}
                                                type="select"
                                            >
                                                <option value="" disabled>
                                                Selecione uma opção
                                                </option>
                                                { listPassageiros &&
                                                listPassageiros.map((items, index) => {
                                                return (
                                                    <option value={`${items.id}-${items.nome}`} key={index}>
                                                    {items.nome}
                                                    </option>
                                                );
                                                })}
                                            </Input>
                                        </div>

                                        <div>
                                            <ButtonComp 
                                                acao={() => handleValidaDados(pessoa)}
                                                estilo={styles.btn}
                                                tipo="button"
                                                texto="+"
                                                >
                                                +
                                            </ButtonComp>
                                        </div>
                                      
                                    </div> 


                                   {
                                    pasList && 
                                               
                                    <Table className={styles.table} size="sm">
                                        <thead>
                                          <tr className={styles.tableHeader}>
                                            <th className={styles.itemHidden}>
                                              <div className={styles.tableName}>ID</div>
                                            </th>
                                            <th>
                                            <div className={styles.tableName}>Nome</div>
                                            </th>
                                            <th>
                                            <div className={styles.tableName}>Opção</div>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody display="flex" align="center">
                                              {
                                              pasList.map((item, index) => (
                
                                                <tr key={index+1}>
                                                <td width="10%" className={styles.itemHidden}>
                                                  <div className={styles.numbers}>{index+1}</div>
                                                </td>
                                        
                                                <td width="30%">
                                                  <div className={styles.numbers}>{
                                                    item.nome
                                                    
                                                  }</div>
                                                </td>

                                                <td width="10%">
                                                  <div className={styles.numbers}>
                                                        <div className={styles.buttonsBloco}>
                                                        <ButtonComp
                                                            acao={() => handleRemovePassList(item.id)}
                                                            texto="Remover"
                                                            estilo={styles.formButtonClose}
                                                            tipo="button"
                                                        />
                                                        </div>
                                                     
                                                  </div>
                                                </td>
                                              </tr>
                                  
                                            ))
                                              }
                                        </tbody>

                                    </Table>

} 

                    </FormGroup> 

                    <FormGroup className={styles.formBlock}>
                        
                        <div>
                           <h2>Paradas</h2>
                        </div>
                        <div className={styles.formItems}>

                       </div>


                        {
                         paradas && 
                                    
                         <Table className={styles.table} size="sm">
                             <thead>
                               <tr className={styles.tableHeader}>
                                 <th className={styles.itemHidden}>
                                   <div className={styles.tableName}>ID</div>
                                 </th>
                                 <th>
                                 <div className={styles.tableName}>Local</div>
                                 </th>
                                 <th>
                                 <div className={styles.tableName}>Data</div>
                                 </th>
                                 <th>
                                 <div className={styles.tableName}>Hora</div>
                                 </th>
                               </tr>
                             </thead>
                             <tbody display="flex" align="center">
                                   {
                                   paradas.map((item, index) => (
     
                                     <tr key={index+1}>
                                     <td width="10%" className={styles.itemHidden}>
                                       <div className={styles.numbers}>{index+1}</div>
                                     </td>
                             
                                     <td width="30%">
                                       <div className={styles.numbers}>{
                                         item.local
                                         
                                       }</div>
                                     </td>

                                     <td width="30%">
                                       <div className={styles.numbers}>{
                                         validaData(item.data_prevista)
                                          
                                       }</div>
                                     </td>

                                     <td width="30%">
                                       <div className={styles.numbers}>{
                           
                                         validaHora(item.hora_prevista)
                                         
                                       }</div>
                                     </td>

                                     <td width="10%">
                                       {/* <div className={styles.numbers}>
                                             <div className={styles.buttonsBloco}>
                                             <ButtonComp
                                                 acao={() => handleRemovePassList(item.id)}
                                                 texto="Remover"
                                                 estilo={styles.formButtonClose}
                                                 tipo="button"
                                             />
                                             </div>
                                          
                                       </div> */}
                                     </td>
                                   </tr>
                       
                                 ))
                                   }
                             </tbody>

                         </Table>

} 
                         <div className={styles.blocoButton2}>
                             <ButtonComp acao={() =>  previewsPage()} texto="Voltar" estilo={styles.formButton} tipo="button"/>
                             <ButtonComp acao={() => postAlter()} texto="Salvar" estilo={styles.formButton} tipo="button"/>
                         </div>
         </FormGroup> 


                </form>
            </main>

            {loading === false ?
                
                <div className={styles.loader}>
                            <div className={styles.loaderInterno}>
                              <Loading />
                            </div>
                </div>
                :
                <div></div>
                }

{<Modal className={styles.modalEstilo} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Deseja realmente remover o setor?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                <Button color="success" onClick={handleRemoveList}>
                    SIM
                </Button>
                  <Button color="danger" onClick={handleClose}>
                    NÃO
                  </Button>
                </Modal.Footer>
              </Modal>
              }
        </section>
    );
};

export default EditarSolicitacao;