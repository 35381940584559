import React from "react";

const Button = ({texto, estilo, acao, tipo, toogleModal, targetModal}) => {

  // eslint-disable-next-line no-unused-vars
  const [estado, setEstado] = React.useState(false);
  
  return (
    <button  type={tipo} onClick={acao} className={estilo}>{texto}</button>
  );
};

export default Button;

