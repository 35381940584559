import React, {useEffect, useState, useContext} from "react";
import { useNavigate } from "react-router-dom";
import styles from "../Gestor/GestorListarViagem.module.css";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import Button from "../../Components/Button/Button"; 
// import Input from "../../Components/Input/Input";
import Loading from "../../Components/Loader/Loading";
import estilo from "../Solicitante/SetorTabel.module.scss";
// eslint-disable-next-line no-unused-vars
import ButtonModal from "react-bootstrap/Button";
import Label from "../../Components/Label/Label";
import $ from "jquery";
import DataTable, { createTheme} from "react-data-table-component";
import styled, { keyframes } from 'styled-components';
import { FormGroup, Form, Input, Row, Col, Media } from "reactstrap";  
import { red } from "@mui/material/colors";
import { width } from "@mui/system";
import CodigoDaViagem from "../Viagem/CodigoViagem";
import {API_BASE_URL} from '../../services/Constantes';
import { IMaskInput } from "react-imask";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Error from "../../Components/Error/Error";
import EstadoMenu from "../../Components/Menu/EstadoMenu";

const GestorListarViagem = () => {
    useEffect(() => {
        document.title = 'Listar de Viagens';
        setAtivadoMenu(false);
      }, []);
    // let $ = require('jquery');
    // $.DataTable = require('datatables.net') 

    // Contexto 
    const {codigo, setCodigo} = useContext(CodigoDaViagem);

    const [selectedStatus, setSelectedStatus] = useState('Todos');
    const [showPagination, setShowPagination] = useState(true);
    
    // Contexto do Estado do Menu
    const { ativadoMenu, setAtivadoMenu } = useContext(EstadoMenu);
     // History
     let detalhar = useNavigate();
     let iniciar = useNavigate(); 
    // eslint-disable-next-line no-unused-vars 
    const [estadoID, setEstadoID] = useState('');
     function handlePage(id){
            setCodigo(id);
            detalhar('/gestor/detalharViagem');
            localStorage.setItem("StatusViagem", "Viagem Avulsa");
     }

     function nextPage(id) {
        localStorage.setItem("idViagemAtual", id)
        setCodigo(id);  
        iniciar('/gestor/editarViagem');
        localStorage.setItem("StatusViagem", "Viagem Avulsa");
    
     }


    
    // Estado para pegar as viagens
    const [list, setList] = useState([]);
    const [dataInicio, setDataInicio] = useState(null);
    const [dataFinal, setDataFinal] = useState(null);
    const [loading, setLoading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [kmInicial, setKmInicial] = useState('');
    const [buttonState, setButtonState] = useState(false);
    const [estado, setEstado] = useState('');
    const [listParadas, setListParadas] = useState([])

    
    // Estado para pegar o Motorista da Viagem
    const [motorista, setMotorista] = useState([]);
    // const [formatData, setFormatData] = useState(''); 
    let vetorParadas = [];

    let idGestor = localStorage.getItem("IdUsuario");

  // Funcionalidade de Páginação
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 5;
  const totalItems = list.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage); 

  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  const [applyFilter, setApplyFilter] = useState(false);
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
 
    useEffect(() => {
        window.scrollTo(0, 0);
        fetch(`${API_BASE_URL}/api/transporte/viagemAvulsa/gestor/${idGestor}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo")
            }
            }).then((response) => {
                return response.json();
              })
              .then((data) => {
                setList(data);
                setLoading(true);
              })
              .catch(() => {
                setLoading(true);
              });
        
    },[])



   // formata data
   const formatDataAtual = (data) => {
    var date = new Date(data+'T12:00:00.00-0200');
    let formatData = (new Intl.DateTimeFormat('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'}).format(date))
    return formatData
   }

   const formatHoraAtual = (dado) => {
   
    let formatHora = String (dado)
    return formatHora.replace(':00', '');
   }


   

   useEffect(() => {
    // Default Data
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    setDataInicio(startOfMonth);
    setDataFinal(endOfMonth);
  }, [setDataInicio, setDataFinal]);



  const tamanhoList = list.filter((item) => {
    if (selectedStatus === 'Todos') {
      return true;
    } else {
      return item.status === selectedStatus;
    }
  }).filter((item) => {
    if (!applyFilter || !dataInicio || !dataFinal) {
      return true;
    } else {
      const itemDate = new Date(item.data_prevista);
      const startDateObj = new Date(dataInicio);
      const endDateObj = new Date(dataFinal);
      return itemDate >= startDateObj && itemDate <= endDateObj;
    }
  }).length;

  const handleFilter = () => {
    let dataInicioAtual = new Date(dataInicio);
    let dataFinalAtual = new Date(dataFinal);
    const formattedDate = dataInicioAtual.toLocaleDateString('pt-BR', {day: '2-digit', month: '2-digit', year: 'numeric'});
    const formattedDate2 = dataFinalAtual.toLocaleDateString('pt-BR', {day: '2-digit', month: '2-digit', year: 'numeric'});
   

  
    if (dataFinalAtual < dataInicioAtual) {
      const today = new Date();
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      notify('A data inicial não pode ser maior que a data final.');
      setDataFinal(endOfMonth)
    } else {
      setApplyFilter(true);
    }
  }

  const handleFilter2 = () => {
    setApplyFilter(false);
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    dataInicio(startOfMonth);
    setDataFinal(endOfMonth);
  }


  const notify = (msg) => toast(msg); 
  const handleDataInicioChange = (e) => {
    const novaDataInicio = new Date(e.target.value);
    setDataInicio(novaDataInicio);
    if (novaDataInicio > dataFinal) {
      setDataFinal(novaDataInicio);
    }
  };

  const handleDataFinalChange = (e) => {
    const novaDataFinal = new Date(e.target.value);
    if (novaDataFinal < dataInicio) {
      setDataFinal(novaDataFinal);
    } else {
      setDataFinal(novaDataFinal);
    }
  };
 
    return (
        <section className={styles.listViagem}>
           <Header titulo="Listar Viagem" altura={
           '240px' 
           }/>
           <Menu />

           <ToastContainer />
           <main className={
            ativadoMenu === true ? styles.mainContent2 : styles.mainContent
           }>
                  {
                  
                  
                    <section className={styles.blockItens}>
                       <div className={styles.blockItensOptions} >
                       <div className={styles.inputContainer}>
                          <div className={styles.inputForm}>
                            <div className={styles.formGroup4}>
                              <span>Data inicial</span>
                              <IMaskInput
                                className={styles.formField}
                                type="date"
                                onChange={handleDataInicioChange}
                                value={dataInicio ? dataInicio.toISOString().slice(0, 10) : ''}
                              />
                            </div>
                          </div>

                          <div className={styles.inputForm}>
                            <div className={styles.formGroup4}>
                              <span>Data final</span>
                              <IMaskInput
                                className={styles.formField}
                                type="date"
                                onChange={handleDataFinalChange}
                                value={dataFinal ? dataFinal.toISOString().slice(0, 10) : ''}
                              />
                            </div>
                          </div>
                        </div>


                            <div className={styles.blockItensButton}> 
                                {
                                  applyFilter === false ?
                                  <Button  acao={handleFilter}  estilo={styles.searchButton} texto="Filtar"  />
                                  :
                                  <Button  acao={handleFilter2}  estilo={styles.clearButton} texto="Limpar"  />
                                }
                              
                            </div>

                                
                       </div>

                     <div className={styles.blockItensOptions2} >
                        
                            <div className={styles.inputForm}>
                              <div className={styles.formGroup}>
                                              <span>Filtrar</span>
                                            <Input id="selectSearch" type="select" className={styles.formField} onChange={(e) => setSelectedStatus(e.target.value)} value={selectedStatus}>
                                              <option disabled value="">Selecione uma opção</option>
                                              <option value="CRIADA">Aguardando</option>
                                              <option value=" EM_ANDAMENTO">Em andamento</option>
                                              <option value="FINALIZADA">Finalizada</option>
                                              <option value="CANCELADA">Cancelada</option>
                                              <option value="Todos">Todas as Viagens</option>
                                          </Input>
                            </div>
                        </div>

                     </div>
                    </section>
                  }
                <section className={styles.mainTable}>
                     <div className={styles.blocoSetor} id="tabelaViagens">
                              {
                                <div>
                                  <ul className={estilo.responsiveTable}>
                                    <li className={estilo.tableHeader}>
                                      <div className={estilo.col} id={estilo.col1}>
                                        ID
                                      </div>
                                      <div className={estilo.col} id={estilo.col2}>
                                        Paradas
                                      </div>
                                      <div className={estilo.col} id={estilo.col3}>
                                        Data
                                      </div>
                                      <div className={estilo.col} id={estilo.col2}>
                                        Status
                                      </div>
                                      <div className={estilo.col} id={estilo.col3}>
                                        Opções
                                      </div>
                                    </li>
                                    { list.length > 0 ?
                                    list.filter((item) => {
                                        if (selectedStatus === 'Todos') {
                                          return true;
                                        } else {
                                          return item.status === selectedStatus;
                                        }
                                      }).filter((item) => {
                                        if (!applyFilter || !dataInicio || !dataFinal) {
                                          return true;
                                        } else {
                                          const itemDate = new Date(item.data_prevista);
                                          const startDateObj = new Date(dataInicio);
                                          const endDateObj = new Date(dataFinal);
                                          return itemDate >= startDateObj && itemDate <= endDateObj;
                                        }
                                      }).slice(start, end).map((item, index) => (
                                      <li className={estilo.tableRow} key={index}>
                                        <div className={estilo.col} id={estilo.col1} data-label="ID">
                                          {item.id}
                                        </div>
                                        <div className={estilo.col} id={estilo.col2} data-label="Paradas">
                                          <div className={styles.blocoParadas}>
                                              {
                                                item.paradas.map((destino, index) => (
                                                    destino.local === 'STC' && destino.is_ultima_parada === false ? console.log("") :
                                                            
                                                  
                                                
                                                                    <p className={styles.tableText}>- {destino.local}</p>
                                                  
                                                                
                                                      ))
                                              }
                                            </div>
                                        </div>
                                        <div className={estilo.col} id={estilo.col3} data-label="Data">
                                          {
                                            formatDataAtual(item.data_prevista)
                                          }
                                        </div>
                                        <div className={estilo.col} id={estilo.col2} data-label="Status">
                                          {item.status === 'CRIADA' ? (
                                            <div className={styles.buttonsContainer}>
                                              <p className={styles.tableTextB}>Aguardando</p>
                                            </div> 
                                          ) : item.status === 'CANCELADA' ? (
                                            <div className={styles.buttonsContainer}>
                                              <p className={styles.tableTextC}>Cancelada</p>
                                            </div>
                                          ) :  item.status === 'EM_ANDAMENTO' ? 
                                          
                                          (
                                            <div className={styles.buttonsContainer}>
                                              <p className={styles.tableTextA}>Em Andamento</p>
                                            </div>
                                          )

                                          :
                                          (
                                            <div className={styles.buttonsContainer}>
                                              <p className={styles.tableTextC}>Finalizada</p>
                                            </div>
                                          )}
                                        </div>
                                        <div className={estilo.col} id={estilo.col3} data-label="Opções">
                                          {
                                            item.status === 'FINALIZADA' || item.status === 'CANCELADA' ? 
                                             <div className={styles.buttonsContainer}>
                                                {/* <Button estilo={styles.buttonAddModifer} texto="Editar"/>  */}
                                                <Button  acao={() => handlePage(item.id)}  estilo={styles.buttonEdit} texto="Detalhar" /> 
                                              </div>
                                             :
                                          
                                             <div className={styles.buttonsContainer}>
                                                 <Button acao={() => nextPage(item.id)} estilo={styles.buttonAdd} texto="Editar"/> 
                                                 <Button acao={() => handlePage(item.id)}  estilo={styles.buttonEdit} texto="Detalhar" />    
                                                 </div>
                                          
                                          }
                                        </div>
                                      </li>
                                    ))
                                    : <li  className={estilo.tableRow} >
                                    <p className={estilo.col}>Não há viagens cadastradas</p>
                                    </li>
                                  }
                                  </ul>

                                  <div className={styles.pagination}>
                                    <ul className={styles.paginationList}>
                                      {tamanhoList > 5 ?
                                      Array.from({ length: totalPages }, (_, i) => (
                                        <li
                                          key={i}
                                          className={`${styles.paginationItem} ${
                                            i + 1 === currentPage ? styles.active : ''
                                          }`}
                                          onClick={() => handlePageChange(i + 1)}
                                        >
                                          {i + 1}
                                        </li>
                                      ))
                                    : <div></div>
                                    }
                                    </ul>
                                  </div>
                                </div>
                              }
                            </div>
                </section>
           </main>
           {loading === false ?
                
                <div className={styles.loader}>
                            <div className={styles.loaderInterno}>
                              <Loading />
                            </div>
                </div>
                :
                <div></div>
                }

        </section>
    );
};

export default GestorListarViagem;