import {React} from "react";
import {Routes, Route} from "react-router-dom";
import HomeGestor from "./pages/Home/HomeGestor";
// import HomeMotorista from "./pages/Home/HomeMotorista";
import Login from "./pages/Login/Login";
import IniciarViagem from "./pages/Motorista/IniciarViagem";
import Motorista from "./pages/Motorista/Motorista";
import Veiculo from "./pages/Veiculo/Veiculo";
import ListarViagem from "./pages/Viagem/ListarViagem";
import ViagemAvulsa from "./pages/Viagem/ViagemAvulsa";
import MotoristaAvulsaDetalhe from "./pages/Motorista/MotoristaAvulsaDetalhe";
import GestorListarViagem from "./pages/Gestor/GestorListarViagem";
import GestorDetalheViagem from "./pages/Gestor/GestorDetalhaViagem";
import GestorEditaViagem from "./pages/Gestor/GestorEditarViagem";
import Dados from "./pages/Veiculo/Dados";
import CadastroSolicitante from "./pages/Solicitante/CadastroSolicitante";
import CadastrodoSetor from "./pages/Solicitante/CadastroSetor";
import ListarSolicitantes from "./pages/Solicitante/ListarSolicitantes";
import EditarSolicitante from "./pages/Solicitante/EditarSolicitante";
import Cadastro from "./pages/Passageiro/Cadastro";
import ListarPassageiros from "./pages/Passageiro/ListarPassageiros";
import EditarPassageiro from "./pages/Passageiro/EditarPassageiro";
import CadastroSolicitacao from "./pages/Solicitacao/CadastroSolicitacao";
import ListarSolicitacoes from "./pages/Solicitacao/ListarSolicitacoes";
import EditarSolicitacao from "./pages/Solicitacao/EditarSolicitacao";
import GestorSolicitacoes from "./pages/Gestor/GestorSolicitações";
import GestorComum from "./pages/Gestor/GestorViagemComum";
import GestorDetalhaComum from "./pages/Gestor/GestorDetalhaComum";
import DetalhaSolicitacao from "./pages/Solicitacao/DetalhaSolicitacao";
import ListarViagemComum from "./pages/Viagem/ListarViagemComum";
import IniciarViagemComum from "./pages/Motorista/IniciarViagemComum";
import UnirSolicitacoes from "./pages/Gestor/UnirSolicitacoes";
import ListarMotoristas from "./pages/Motorista/ListarMotoristas";
import MotoristaDetalhaComum from "./pages/Motorista/MotoristaDetalhaComum";
import Error from "./Components/Error/Error";

const Rotas = () =>{

    return (
       <Routes>
         {/* <Route path="/motorista" element={<HomeMotorista />}/> */}
         <Route path="/gestor" element={<HomeGestor />}/>
         <Route path="/" element={<Login />}/>
         <Route path="/motorista/iniciar-viagem" element={<IniciarViagem />}/>
         <Route path="/motorista" element={<Motorista />}/>
         <Route path="/veiculo" element={<Veiculo />}/>
         <Route path="/motorista/listar-viagem" element={<ListarViagem  />}/>
         <Route path="/viagem-avulsa" element={<ViagemAvulsa />}/>
         <Route path="/motorista/detalharViagem" element={<MotoristaAvulsaDetalhe />}/>
         <Route path="/gestor/listarViagem" element={<GestorListarViagem />}/>
         <Route path="/gestor/detalharViagem" element={<GestorDetalheViagem />}/>
         <Route path="/gestor/editarViagem" element={<GestorEditaViagem />}/>
         <Route path="/gestor/veiculos/cadastro-dados" element={<Dados />}/>
         <Route path="/gestor/cadastro-solicitante" element={<CadastroSolicitante />}/>
         <Route path="/gestor/cadastro-setor" element={<CadastrodoSetor />}/>
         <Route path="/gestor/listar-solicitantes" element={<ListarSolicitantes />}/>
         <Route path="/gestor/editar-solicitante" element={<EditarSolicitante />}/>
         <Route path="/gestor/cadastro-passageiro" element={<Cadastro />}/>
         <Route path="/gestor/listar-passageiros" element={<ListarPassageiros />}/>
         <Route path="/gestor/editar-passageiro" element={<EditarPassageiro />}/>
         <Route path="/solicitante/cadastro-solicitacao" element={<CadastroSolicitacao />}/>
         <Route path="/solicitante/listar-solicitacoes" element={<ListarSolicitacoes />}/>
         <Route path="/gestor/editar-solicitacao" element={<EditarSolicitacao />}/>
         <Route path="/gestor/listar-solicitacao" element={<GestorSolicitacoes />}/>
         <Route path="/gestor/listar-viagem-comum" element={<GestorComum />}/>
         <Route path="/gestor/detalha-comum" element={<GestorDetalhaComum />}/>
         <Route path="/motorista/detalha-comum" element={<MotoristaDetalhaComum />}/> 
         <Route path="/solicitante/detalha-solicitacao" element={<DetalhaSolicitacao />}/>
         <Route path="/motorista/listar-viagemComum" element={<ListarViagemComum />}/>
         <Route path="/motorista/iniciar-viagemComum" element={<IniciarViagemComum />}/>
         <Route path="/gestor/solicitacoes-join" element={<UnirSolicitacoes />}/>
         <Route path="/gestor/listar-motoristas" element={<ListarMotoristas />}/>
         <Route path="/error" element={<Error />}/>




 



       </Routes>
    ) 
}; 

export default Rotas;