import React, { useState, useEffect, useContext} from "react";
import styles from "../Solicitante/CadastroSetor.module.css";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { FormGroup, Form, Input, Row, Col, Button, ModalBody } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Table from "react-bootstrap/Table";
import { IMaskInput } from "react-imask";
import ButtonComp from "../../Components/Button/Button";
import {API_BASE_URL} from '../../services/Constantes';
import Loading from "../../Components/Loader/Loading";
import estilo from "../Solicitante/SetorTabel.module.scss";
import Modal from "react-bootstrap/Modal"; 
import EstadoMenu from "../../Components/Menu/EstadoMenu";
import { useNavigate } from "react-router-dom";

const CadastrodoSetor = () => {
    useEffect(() => {
        document.title = 'Setores';
        setAtivadoMenu(false);
      }, []);

    // Estados para armazenar os valores dos campos
    const [nome, setNome] = useState('');
    const [setores, setSetores] = useState([]);
    const [loading, setLoading] = useState(true);
    const [setorAlter, setSetorAlter] = useState('');
    const [buttonCadastro, setButtonCadastro] = useState(false);
    const [showDelete, setShowDelete] =useState(false);
    const [setorAtual, setSetorAtual] = useState('');
    const [idDelete, setIDDelete] = useState('');
    const [idAlter, setIDAlter] = useState('');
    const [cpf, setCpf] = useState('');
    const handleClose = () => setShowDelete(false);
    const handleShow = () => setShowDelete(true);

    // Pega o id atual da linha
    const [idAtual, setIdAtual] = useState(0);

    // Estado dos botões de editar e excluir
    const [buttonEdit, setButtonEdit] = useState(true);
    const [buttonEx, setButtonEx] = useState(true);
    const [buttonDelete, setButtonDelete] = useState(false);
    const [buttonSave, setButtonSave] = useState(false);

    // Contexto do estado do Menu
    const { ativadoMenu, setAtivadoMenu } = useContext(EstadoMenu);

    // useEffect que pega os setores cadastrados
    useEffect(() => {
      setLoading(false);
      window.scrollTo(0, 0);
    
      const token = localStorage.getItem("token");
    
      if (token) {
        fetch(`${API_BASE_URL}/api/transporte/setor`, {
          method: "GET",
          headers: {
            "Content-type": "application/json;charset=UTF-8",
            'token': token,
            'perfil': localStorage.getItem("tipo"),
            'Authorization': `Bearer ${token}` 
          }
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Não foi possível realizar a requisição!");
            }
            return response.json();
          })
          .then((data) => {
            setSetores(data);
            setLoading(true);
          })
          .catch((error) => {
            console.log(error);
            setLoading(true);
          });
      } else {
        setLoading(true);
        handlePage();
      }
    }, [buttonCadastro, buttonDelete, buttonSave]);
    

    let navigate = useNavigate();
    function handlePage(){
      setLoading(true);
      navigate('/');
       
    }
    // Adicionar novo setor
    const postSetor = async () => {
        setLoading(false);
        window.scrollTo(1000, 1000);
        try {
          const response2 = await fetch(
            `${API_BASE_URL}/api/transporte/setor/cadastrar`,
            {
              method: "POST",
              body: JSON.stringify({ 
                "nome": nome
            }), 
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo"),

              },
            }
          );
          const json2 = await response2.json();
         console.log(json2);
         setLoading(true);
         setNome('');
         setButtonCadastro(!buttonCadastro);
         notify("Setor Cadastrado com Sucesso!");
        } catch (error) {
          console.log(error);
          notify(error);
          setLoading(true);
          notify("Não foi possivel realizar o cadastro!");
          notify("Tente novamente!");
        };
      }

    // Excluir um setor
    const postDelete = async () => {
        setLoading(false);
        window.scrollTo(1000, 1000);
        try {
          const response2 = await fetch(
            `${API_BASE_URL}/api/transporte/setor/${idDelete}`,
            {
              method: "DELETE",
              body: JSON.stringify({ 
                "nome": nome
            }), 
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo"),

              },
            }
          );
          const json2 = await response2.json();
         console.log(json2);
         setLoading(true);

         if (json2.msg) {
          notify(json2.msg);
         } else {
          notify("Setor excluido com sucesso");
         }
   
        
      
        } catch (error) {
          console.log(error);
          notify(error);
          setLoading(true);
          notify("Não foi possíveld deletar o setor!");
        };
      }   
    
    // Editar um setor
    const postAlter = async () => {
        setLoading(false);
        window.scrollTo(0, 0);
        try {
          const response2 = await fetch(
            `${API_BASE_URL}/api/transporte/setor/${idAlter}`,
            {
              method: "PUT",
              body: JSON.stringify({ 
                "nome": setorAlter
            }), 
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo"),

              },
            }
          );
          const json2 = await response2.json();
         console.log(json2);
         if (json2.ok) {
          setLoading(true);
          notify("Setor atualizado com sucesso!");
         } else {
          setLoading(true);
          notify("Não foi possível atualizar o setor!");
         }
        
        } catch (error) {
          console.log(error);
          notify(error);
          setLoading(true);
          notify("Não foi possíveld atualizar o setor!");
        };
      }   


      const notify = (msg) => toast(msg);

      function pegaID(id) {
        setIdAtual(id);
        setButtonEdit(!buttonEdit);
      }

    function deleteSetor(setor, id){
        setSetorAtual(setor);
        setIDDelete(id);
        handleShow();
    }

    function excluirElemento(){
        handleClose(); 
        postDelete();
        setButtonDelete(!buttonDelete);
    }

    function alterElemento(id){
        setButtonEdit(true);
        setIDAlter(id);
        postAlter();
        setButtonSave(!buttonSave);
    }

    setores.sort((a, b) => a.id - b.id);
    return (
        <section className={styles.cadastro}>
            <Header titulo="Setores" altura="240px"/>
            <Menu />
            <ToastContainer />
            <main className={
              ativadoMenu === true ? styles.mainContent2 : styles.mainContent
            }>
                <form className={styles.form}>
                    <FormGroup className={styles.formBlock}>
                            <div>
                                <h2>Cadastro de Setor</h2>
                            </div>     

                              <div className={styles.formComponents}>
                                        <div className={styles.formItems}>
                                            <div className={styles.formGroup}>
                                                    <span>Setor</span>
                                                    <IMaskInput className={styles.formField} name="nome" onChange={(e) => setNome(e.target.value)} value={nome} type="text"  placeholder="Informe o nome do setor"/>
                                            </div>
                        
                                        </div>

                                        <div className={styles.formItems}>
                                        <ButtonComp acao={() => postSetor()} tipo="button" texto="Cadastrar" estilo={styles.formButton}/>
                                    </div>
                                </div>                   
                    </FormGroup> 

                    <FormGroup className={styles.formBlock3}>
                            <div>
                                <h2>Lista de Setores Cadastrados</h2>
                            </div>     

                            <div className={styles.blocoSetor}>
                  
                               

                                 <ul className={estilo.responsiveTable}>
                                        <li className={estilo.tableHeader}>
                                          <div  className={estilo.col} id={estilo.col1}>ID</div>
                                          <div className={estilo.col} id={estilo.col2}>Setor</div>
                                          <div className={estilo.col} id={estilo.col3}>Opções</div>
                                        </li>
                                        { setores.length > 0 ?
                                            setores.map((item, index) => (
                                            <li  className={estilo.tableRow} key={index}>
                                                <div className={estilo.col} id={estilo.col1} data-label="ID">{index+1}</div>
                                                <div className={estilo.col} id={estilo.col2} data-label="Setor">{
                                                    buttonEdit === false && item.id === idAtual ?
                                                    <div className={styles.formGroup}>
                                                        <span>Setor</span>
                                                        <IMaskInput className={styles.formField} name="setor" onChange={(e) => setSetorAlter(e.target.value)} value={setorAlter} type="text"  placeholder="Informe o setor"/>
                                                     </div>

                                                     :
                                                     item.nome
                                                }</div>
                                                <div className={estilo.col} id={estilo.col3} data-label="Opções">
                                                    {
                                                        buttonEdit === true ?
                                                        <div className={styles.buttonContainer}>
                                                             <ButtonComp acao={() => pegaID(item.id)} tipo="button" estilo={styles.buttonEdit} texto="Editar"/>
                                                             <ButtonComp acao={() => deleteSetor(item.nome, item.id)} tipo="button" estilo={styles.buttonClose} texto="Excluir"/>
                                                        </div>

                                                        :
                                                        item.id === idAtual ?
                                                        <div className={styles.buttonContainer}>
                                                             <ButtonComp acao={() => alterElemento(item.id)} tipo="button"  estilo={styles.buttonSave} texto="Salvar"/>
                                                             <ButtonComp acao={() => setButtonEdit(true)} tipo="button" estilo={styles.buttonClose} texto="Cancelar"/>
                                                        </div>

                                                        : 
                                                           <div className={styles.buttonContainer}>
                                                             <ButtonComp acao={() => pegaID(item.id)} tipo="button" estilo={styles.buttonEdit} texto="Editar"/>
                                                             <ButtonComp acao={() => deleteSetor(item.nome, item.id)} tipo="button" estilo={styles.buttonClose} texto="Excluir"/>
                                                           </div>

                                                    }
                                                 
                                                </div>
                                            </li>
                                            ))
                                            :
                                            <li  className={estilo.tableRow} >
                                            <p className={estilo.col}>Não há setores cadastrados</p>
                                            </li>
                                        }
                                       
                              </ul>
                               
                                
                          </div> 
                    </FormGroup> 
                </form>
            </main>

            {loading === false ?
                
                <div className={styles.loader}>
                            <div className={styles.loaderInterno}>
                              <Loading />
                            </div>
                </div>
                :
                <div></div>
                }

{<Modal className={styles.modalEstilo} show={showDelete} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Deseja realmente excluir o setor?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {setorAtual}
                </Modal.Body>
                <Modal.Footer>
                  <Button color="success" value={buttonDelete} onClick={excluirElemento}>
                    SIM
                  </Button>
                  <Button color="danger" onClick={handleClose}>
                    FECHAR
                  </Button>
                </Modal.Footer>
              </Modal>
              }
        </section>
    );
};

export default CadastrodoSetor;