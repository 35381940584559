import { style } from '@mui/system';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from "../Menu/Menu.module.css";


const SubMenuMotorista = ({ item, l }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return ( 
    <>
    <div className={styles.menuList}>
      <NavLink to={item.path} onClick={item.subNav && showSubnav} className={styles.classListMenu}>
        <div className={styles.menuListItem}>
            <div className={styles.iconsBloco}>
                {item.icon}
            </div> 
          {
            l > 60 ? <div className={styles.menuItens}>{item.title}</div> : ''
          }
        </div>
        <div>
        { l > 60 ?
          item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null
          : null}
        </div>
      </NavLink>
      </div>
      <div className={styles.classSubMenu}>
      {subnav && 
        item.subNav.map((item, index) => {
          return (
     
            <NavLink to={item.path} key={index} className={styles.menuListItem3}>
              {item.icon}
              <div className={styles.menuItens2}>{item.title}</div>
            </NavLink>

          );
        })}
        </div>
    </>
  );
};

export default SubMenuMotorista;