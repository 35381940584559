import React, { useState, useEffect, useContext } from "react";
import styles from "../Solicitante/CadastroSolicitante.module.css";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { FormGroup, Form, Input, Row, Col, Button } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Table from "react-bootstrap/Table";
import { IMaskInput } from "react-imask";
import ButtonComp from "../../Components/Button/Button";
import {API_BASE_URL} from '../../services/Constantes';
import Loading from "../../Components/Loader/Loading";
import EstadoMenu from "../../Components/Menu/EstadoMenu";

const CadastroSolicitante = () => {

    useEffect(() => {
        document.title = 'Cadastro de Solicitante';
        setAtivadoMenu(false);
      }, []);
    // Estados para os inputs
    const [Cpf, setCpf] = useState('');
    const [login, setLogin] = useState('');
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [setor, setSetor] = useState([]);
    const [setorAtual, setSetorAtual] = useState('');
    const [setorSelecionado, setSetorSelecionado] = useState(null);
    let setorFinal = 0;
    const [telefone, setTelefone] = useState('');
    const [categoria, setCategoria] = useState('');
    const [list, setList] = useState([]);
    const [alter, setAlter] = useState(true);
    const [close, setClose] = useState(false);
    const [save, setSave] = useState(false);
    const [del, setDel] = useState(true);
    const [loading, setLoading] = useState(true);
    let idAtual = 0;

    // Contexto para o estado do Menu
    const { ativadoMenu, setAtivadoMenu } = useContext(EstadoMenu);
    
   // Funcionalidade de Páginação
   const [currentPage, setCurrentPage] = useState(1);

   const itemsPerPage = 5;
   const totalItems = list.length;
   const totalPages = Math.ceil(totalItems / itemsPerPage);
 
   const start = (currentPage - 1) * itemsPerPage;
   const end = start + itemsPerPage;
 
   const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    function validaContato() {
      setorAtual === '' ? notify("Informe o setor") : handleSaveList();
    }
  
    function handleSaveList() {
      const setorSelecionadoAtual = setor.find((item) => item.id === parseInt(setorAtual));
      if (!setorSelecionadoAtual) {
        return;
      }
      if (list.some((item) => item.id === setorSelecionadoAtual.id)) {
        notify("Setor já adicionado");
      } else {
        setList([...list, { id: setorSelecionadoAtual.id, nome: setorSelecionadoAtual.nome }]);
        console.log(list)
      }
      setSetorAtual('');
      setSetorSelecionado(null);
    }
  
    function handleSetorChange(event) {
      const setorId = event.target.value;
      const setorSelecionadoAtual = setor.find((item) => item.id === parseInt(setorId));
      setSetorAtual(setorId);
      setSetorSelecionado(setorSelecionadoAtual);
    }

    const notify = (msg) => toast(msg);

    function handleEdit(dado){
        setAlter(false);
        idAtual = dado;
        console.log(dado);

        
    }
    const buscaCPF = async () => {
      setLoading(false);
      window.scrollTo(0, 0);
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/transporte/usuario/cpf/${Cpf.match(/\d/g).join("")}`,
          {
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              'token': localStorage.getItem("token"),
              'perfil': localStorage.getItem("tipo")
            },
          }
        ); 
        if(response.ok) {
          const json = await response.json();
          console.log(json);
          setNome(json.str_nome);
          setEmail(json.str_email);
          setTelefone(json.str_telefone);
          notify("Usuário encontrado!");
          setLoading(true);
        } else {
          const json = await response.json();
          console.log(json);
          notify(json.msg)
          setLoading(true);
        }
       
      } catch (error) {
        notify('Não autorizado');
        console.log(error)
        setLoading(true);
        // console.clear();
     
      };
    
  }
    

    function handleRemoveList(dado){
        let newList = [...list];
        newList.splice(dado.index, 1)
        setList(newList);
      }

      useEffect(() => {
        setLoading(false);
        window.scrollTo(0, 0); 
        fetch(`${API_BASE_URL}/api/transporte/setor`, {
            method: "GET",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo"),
                'Authorization': `Bearer ${localStorage.getItem("token")}` 
            }
            }).then((response) => {
                return response.json();
              })
              .then((data) => {
                setSetor(data);
                setLoading(true);
              })
              .catch((error) => {
                console.log(error)
                setLoading(true);
              }); 
        console.log(setor);
    },[]);

    // Cadastro de Solicitante
    const postSolicitante = async () => {
      setLoading(false);
      window.scrollTo(0, 0);
      try {
        const response2 = await fetch(
          `${API_BASE_URL}/api/transporte/solicitante/cadastrar`,
          {
            method: "POST",
            body: JSON.stringify({ 
              "nome": nome,
              "cpf": (Cpf).match(/\d/g).join(""),
              "email": email,
              "telefone": telefone,
              "setores": list
          }), 
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              'token': localStorage.getItem("token"),
              'perfil': localStorage.getItem("tipo"),

            },
          }
        );
        const json2 = await response2.json();
       console.log(json2);
       setLoading(true);
       notify("Solicitante cadastrado com sucesso!");
       setNome("");
       setCpf('');
       setTelefone("");
       setEmail("");
       setList([]);
      } catch (error) {
        console.log(error);
        notify(error);
        setLoading(true);
        notify("Não foi possível realizar o cadastro!");
      };
    }
    return (
        <section className={styles.cadastro}>
            <Header titulo="Cadastro de Solicitante" altura="240px"/>
            <Menu />
            <ToastContainer />
            <main className={
              ativadoMenu === true ? styles.mainContent2 : styles.mainContent
            }>
                <form className={styles.form}>
                    <FormGroup className={styles.formBlock}>
                            
                            <div>
                                <h2>Dados do Solicitante</h2>
                            </div>

                            <div className={styles.formComponents}>
                                    <div className={styles.formItems}>
                                        <div className={styles.formGroup}>
                                                <span>CPF</span>
                                                <IMaskInput className={styles.formField}  name="cpf" mask="000.000.000-00" onChange={(e) => setCpf(e.target.value)} value={Cpf} type="text"  placeholder="Informe o seu CPF"/>
                                        </div>
                                        

                                        <div className={styles.formItems}>
                                          <ButtonComp tipo="button" acao={buscaCPF} texto="Buscar" estilo={styles.formButton}/>
                                        </div>
                                    </div>

                                   
                            </div>

                            <div className={styles.formComponents}>

                                <div className={styles.formItems}>
                                            <div className={styles.formGroup}>
                                                    <span>Email</span>
                                                    <IMaskInput className={styles.formField} name="email"  onChange={(e) => setEmail(e.target.value)} value={email} type="text"  placeholder="email@gmail.com"/>
                                            </div>
                                            {/* <p className={styles.messagemErro}>{errors.nome?.message}</p> */}
                                        </div>

                                        <div className={styles.formItems}>
                                            <div className={styles.formGroup}>
                                                    <span>Nome</span>
                                                    <IMaskInput className={styles.formField}  name="nome"   onChange={(e) => setNome(e.target.value)} value={nome} type="text" placeholder="Informe o nome do solicitante"/>
                                            </div>
                                            {/* <p className={styles.messagemErro}>{errors.cnh?.message}</p> */}
                                        </div>

                                    <div className={styles.formItems}>
                                        <div className={styles.formGroup}>
                                                <span>Telefone</span>
                                                <IMaskInput className={styles.formField}  name="telefone"  mask="(00) 00000-0000" placeholder="(XX) X XXXX XXXX" required="" data-js="phone"  onChange={(e) => setTelefone(e.target.value)} value={telefone} type="tel"/>
                                        </div>
                                        {/* <p className={styles.messagemErro}>{errors.cnh?.message}</p> */}
                                    </div>
                            </div>

                     
            
                    </FormGroup> 

                    <FormGroup className={styles.formBlock}>
                        
                                   <div>
                                      <h2>Adicionar Setores</h2>
                                   </div>
                                   <div className={styles.formItems}>
                                    <div className={styles.formGroup}>
                                      <span>Setor</span>
                                      <Input className={styles.formField} name="setor" onChange={handleSetorChange} value={setorAtual} type="select" placeholder="Nome do setor">
                                        <option value="" disabled>Selecione uma opção</option>
                                        {Array.isArray(setor) ? (
                                          setor.map((items, index) => (
                                            <option value={items.id} key={index}>{items.nome}</option>
                                          ))
                                        ) : (
                                          <option value="" disabled>
                                            Nenhum setor encontrado.
                                          </option>
                                        )}
                                      </Input>
                                    </div>

                                    <div className={styles.blocoButton}>
                                      <ButtonComp acao={() => validaContato()} texto="+" estilo={styles.formButtonPlus} tipo="button" />
                                    </div>

                                  </div>
                                   {
                                    list.length > 0 &&
                                               
                                    <Table className={styles.table} size="sm">
                                        <thead>
                                          <tr className={styles.tableHeader}>
                                            <th className={styles.tableHidden}>
                                              <div className={styles.tableName}>ID</div>
                                            </th>
                                            <th>
                                            <div className={styles.tableName}>Setor</div>
                                            </th>
                                            <th>
                                            <div className={styles.tableName}>Opção</div>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody display="flex" align="center">
                                              {
                                              list.map((item, index) => (
                
                                                <tr key={index+1}>
                                                <td width="10%">
                                                  <div className={styles.numbers}>{item.id}</div>
                                                </td>
                                        
                                                <td width="30%">
                                                  <div className={styles.numbers}>{
                                                      item.nome
                                                    
                                                  }</div>
                                                </td>

                                                <td width="10%">
                                                  <div className={styles.numbers}>
                                                  <ButtonComp acao={() => handleRemoveList(item.id)} texto="Remover" estilo={styles.formButtonClose} tipo="button"/>
                                                  </div>
                                                </td>
                                              </tr>
                                  
                                            ))
                                              }
                                        </tbody>

                                    </Table>
}
                                    <div className={styles.blocoButton2}>
                                        <ButtonComp acao={() => postSolicitante()} texto="Cadastrar" estilo={styles.formButton} tipo="button"/>
                                    </div>
                    </FormGroup> 


                </form>
            </main>

            {loading === false ?
                
                <div className={styles.loader}>
                            <div className={styles.loaderInterno}>
                              <Loading />
                            </div>
                </div>
                :
                <div></div>
                }
        </section>
    );
};

export default CadastroSolicitante;