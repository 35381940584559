import React, {useEffect, useState, useContext} from "react";
import styles from "../Gestor/GestorSolicitacoes.module.css";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import MenuMotorista from "../../Components/Menu/MenuMotorista";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
// import Input from "../../Components/Input/Input";
import Label from "../../Components/Label/Label"; 
import Loading from "../../Components/Loader/Loading"; 
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import DataTable from "react-data-table-component";
// eslint-disable-next-line no-unused-vars
import $ from "jquery";
import ListViagens from "../../Contexts/ArrayViagens";
import {API_BASE_URL} from '../../services/Constantes';
import estilo from "../Solicitante/SetorTabel.module.scss";
import { FormGroup, Form, Input, Row, Col, Button, ModalBody } from "reactstrap";
import ButtonComp from "../../Components/Button/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { IMaskInput } from "react-imask";
import { Switch } from '@chakra-ui/react'
import EstadoMenu from "../../Components/Menu/EstadoMenu";
import Erro from "../../Components/Error/Error";
const GestorSolicitacoes = () => {
    let idSolicitante = localStorage.getItem("idSolicitante");
    const [list, setList] = useState([]);
    const [list2, setList2] = useState([]);
    const uniqueList = list2.filter((item, index) => list2.findIndex(obj => obj.id === item.id) === index);
    const uniqueList2 = list.filter((item, index) => list.findIndex(obj => obj.id === item.id) === index);
    const [loading, setLoading] = useState(false);
    const [idAtual, setIDAtual] = useState(''); 
    const [buttonPost, setButtonPost] = useState(false);
    const [newList, setNewList] = useState([]);
    let vetorAtual = [];
    const [motorista, setMotorista] = useState([]);
    const [motoristaValue, setMotoristaValue] = useState('');
    const [veiculos, setVeiculos] = useState([]);
    const [veiculo, setVeiculo] = useState('');
    const [arraySolicitacoes, setArraySolicitacoes] = useState([]);
    let arraySol = [];
    let paradasArray = [];
    const [totalPassageiros, setTotalPassageiros] = useState(0);
    const [paradasSolicitacao, setParadasSolicitacao] = useState([]);
    const [larguraMenu, setLarguraMenu] = useState('');
    // Estado do botão
    const [buttonEdit, setButtonEdit] = useState(true);
    const [buttonUpdate, setButtonUpdate] = useState(false);

    // Modal
    const [modalShow, setModalShow] = useState(false);
    const [modalIniciar, setModalIniciar] = useState(false);
    const [idSolicitacaoAtual, setIdSolicitacaoAtual] = useState('');
    const [modalAtendimento, setModalAtendimento] = useState(false);

    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);
    const handleShowIniciar = () => setModalIniciar(true);
    const handleCloseIniciar = () => setModalIniciar(false);
    const handleShowModal = () => setModalAtendimento(true);
    const handleCloseModal = () => setModalAtendimento(false);

  // Funcionalidade de Páginação
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const itemsPerPage = 5;
  const itemsPerPage2 = 20;
  const totalItems = uniqueList.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const totalPages2 = Math.ceil(totalItems / itemsPerPage2);
  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  const start2 = (currentPage2 - 1) * itemsPerPage2;
  const end2 = start2 + itemsPerPage2;
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  const handlePageChange2 = (pageNumber) => setCurrentPage2(pageNumber); 
  
  // Estados para os novos valores
  const [dataFinal, setDataFinal] = useState('');
  const [horaFinal, setHoraFinal] = useState('');

  // Contexto do Estado do Menu
  const { ativadoMenu, setAtivadoMenu } = useContext(EstadoMenu);


  // Input checkbox
  const [selectedItems, setSelectedItems] = useState([]);
    // Title da página
    useEffect(() => {
        document.title = 'Lista de Solicitações';
        setAtivadoMenu(false);
      }, []);

      let navigate = useNavigate();
      function handlePage(){
        setLoading(true);
        navigate('/');
         
      }

    useEffect(() => {
        setLoading(false);
        window.scrollTo(0, 0);
        
        const token = localStorage.getItem("token");
        
        if (token) {
          fetch(`${API_BASE_URL}/api/transporte/solicitacao`, {
            method: "GET",
            headers: {
              "Content-type": "application/json;charset=UTF-8",
              'token': token,
              'perfil': localStorage.getItem("tipo"),
              'Authorization': `Bearer ${token}` 
            }
          })
            .then((response) => {
              if (response.status === 401) {
                throw new Error("Não autorizado");
              }
              return response.json();
            })
            .then((data) => {
              if (Array.isArray(data)) {
                const vetorAtual = [];
                data.forEach(function(item) {
                  item.status === 'CRIADA' && vetorAtual.push(item);
                });
                setList(vetorAtual);
                setList2(vetorAtual);
              }
              setLoading(true);
            })
            .catch((error) => {
              if (error.message === "Não autorizado") {
                // Lógica para tratar a resposta 401 aqui, por exemplo, redirecionar para a página de login ou exibir uma mensagem de erro adequada.
                console.log("Erro de autenticação:");
              } else {
                // Outros erros
                console.log(error);
              }
              setLoading(true);
            });
        } else {
          handlePage();
        }
      }, [buttonPost, buttonUpdate]);
      

      

    useEffect(() => {
        setLoading(false);
        window.scrollTo(0, 0);
        fetch(`${API_BASE_URL}/api/transporte/motorista`, {
            method: "GET",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo"),
                'Authorization': `Bearer ${localStorage.getItem("token")}` 
            }
            }).then((response) => {
                return response.json();
              })
              .then((data) => {
                  console.log(data);
                  setMotorista(data)
                  setLoading(true);
              })
              .catch((error) => {
                console.log(error)
                setLoading(true);
              }); 
        
    },[]);

    useEffect(() => {
        setLoading(false);
        window.scrollTo(0, 0);
        fetch(`${API_BASE_URL}/api/transporte/veiculo`, {
            method: "GET",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo"),
                'Authorization': `Bearer ${localStorage.getItem("token")}` 
            }
            }).then((response) => {
                return response.json();
              })
              .then((data) => {
                  setVeiculos(data)
                  setLoading(true);
              })
              .catch((error) => {
                console.log(error)
                setLoading(true);
              }); 
        
    },[]);

    function pegaID(id){
      nextPage(id)
    }

    function getModal(id){
        setIdSolicitacaoAtual(id);
        handleShowIniciar();

    }
    let editar = useNavigate();
    let join = useNavigate();
    
    function nextPage(id) {
      localStorage.setItem("idSolicitacao", id)
      editar('/gestor/editar-solicitacao');
   }
   
   function joinPage() {
    join('/gestor/solicitacoes-join'); 
 }

    function cancelarSolicitacao(){
      handleClose();
      deleteSolicitacao(idAtual);


    }

    function validaIdSolicitacao(id){
      handleShow();
      setIDAtual(id);
    }

    const deleteSolicitacao = async (idSolicitacao) => {
      setLoading(false);
      window.scrollTo(0, 0);
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/transporte/solicitacao/cancelar/${idSolicitacao}`,
          {
            method: "DELETE",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              'token': localStorage.getItem("token"),
              'perfil': localStorage.getItem("tipo")
            },
          }
        ); 
        if(response.ok) {
          setLoading(true);
          const json = await response.json();
          notify("Solicitação Cancelada com Sucesso!");
          setButtonPost(!buttonPost);
        } else {
          const json = await response.json();
          setLoading(true);
          notify("Não é possível cancelar está solicitação, já está em andamento!")
        }
       
      } catch (error) {
        console.log(error);
     
      };
    
  }

  const postAceitar = async () => {
    setLoading(false);
    window.scrollTo(0, 0);
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/transporte/solicitacao/aceitar/${idSolicitacaoAtual}`,
        {
          method: "POST",
          body: JSON.stringify({
            "gestor_id": localStorage.getItem("IdUsuario"),
            "motorista_id": motoristaValue,
            "veiculo_id": veiculo
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            'token': localStorage.getItem("token"),
            'perfil': localStorage.getItem("tipo")
          },
        }
      ); 
      if(response.ok) {
        setLoading(true);
        const json = await response.json();
        notify("Solicitação aceita com sucesso!");
        setButtonUpdate(!buttonUpdate);
      } else {
        setLoading(true);
        notify("Não foi possível aceitar a solicitação")
      }
     
    } catch (error) {
      console.log(error);
      notify("Não foi possível aceitar a solicitação")
   
    };
  
}

const postJuntar = async () => {
  handleCloseModal();
  setLoading(false);
  window.scrollTo(0, 0);
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/transporte/solicitacao/unir`,
      {
        method: "POST",
        body: JSON.stringify({
          "gestor_id": localStorage.getItem("IdUsuario"),
          "motorista_id": "17",
          "veiculo_id": "4",
          "observacao": "União da Solicitação 38 e 39",
          "data_prevista": "2022-10-24",
          "hora_prevista": "17:00",
          "ponto_origem": "STC", 
          "paradas": paradasSolicitacao,
          "solicitacoes": selectedItems.map((item, index) => (
            {
              "id": item
            }
          ))
      }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'token': localStorage.getItem("token"),
          'perfil': localStorage.getItem("tipo")
        },
      }
    ); 
    if(response.ok) {
      setLoading(true);
      const json = await response.json();
      notify("Solicitações unidas com sucesso!");
      setButtonUpdate(!buttonUpdate);
    } else {
      setLoading(true);
      notify("Não foi possível unir as solicitações")
    }
   
  } catch (error) {
    console.log(error);
    notify("Não foi possível unir as solicitações")
 
  };

}

   function aceitarRequest(){
    handleCloseIniciar();
    postAceitar();
   }

   function getAtendimento(){
    handleShowModal();
   }

   const handleCheckboxChange = (id) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(id)) {
        return prevSelectedItems.filter((item) => item !== id);
      } else {
        return [...prevSelectedItems, id];
      }
    });
  };

  const calculateTotalPassageiros = () => {
    const totalPassageiros = uniqueList
      .filter((item) => selectedItems.includes(item.id))
      .reduce((sum, item) => sum + item.qtdPassageiros, 0);
    return totalPassageiros;
  };

  useEffect(() => {
    const totalPassageiros = calculateTotalPassageiros();
    setTotalPassageiros(totalPassageiros);
  }, [selectedItems]);
  

   list.sort((a, b) => a.id - b.id);
  const notify = (msg) => toast(msg);

  useEffect(() => {
    let arraySol = [];
  
    list.forEach((item) => {
      if (selectedItems.includes(item.id)) {
        arraySol.push(item);
      }
    });
  
    setArraySolicitacoes(arraySol);
  
    let total = 0;
    arraySol.forEach((item) => {
      if (selectedItems.includes(item.id)) {
        total += item.passageiros.length;
      } else {
        total -= item.passageiros.length;
      }
    });
    setTotalPassageiros(total);
  }, [selectedItems]);
  
  
  // função que verifica as solicitações clicadas
  function validaSolicitacoes() {
    list.map((item, index) => {
      selectedItems.map((item2, index) =>{
        item.id === item2 && arraySol.push(item)
      })
    })
    setArraySolicitacoes(arraySol);
    console.log(arraySolicitacoes);
    localStorage.setItem("SolicitacoesJoin", selectedItems);
    arraySolicitacoes.map((item, index) => {
     (item.paradas).length > 0 && paradasArray.push(item.paradas)
    
    });
    setParadasSolicitacao(paradasArray);
    joinPage();
  }

  function closeModalOption(){
    handleCloseModal();
    setSelectedItems([]);
  }


    return (
        <section className={styles.container}>
            <Header titulo="Listar Solicitações" altura="140px"/>
            <Menu  />
            <ToastContainer />
            <main className={
              ativadoMenu === true ? styles.mainContent2 : styles.mainContent
            }>
              
                    <FormGroup className={styles.formBlock}>
                        {
                          totalItems > 0 &&
                        
                        <div className={styles.blocoOptions}>
                                <div>
                                    <h2>Solicitações Cadastradas </h2>
                                </div>     

                                <div className={styles.buttonContainer}> 
                                                  <ButtonComp
                                                    acao={() => getAtendimento()}
                                                    tipo="button"
                                                    estilo={styles.buttonJoin}
                                                    texto="JUNTAR"
                                                  />
                                </div>
                       </div>
                        }
                            <div className={styles.blocoSetor}>
                              {
                                <div>
                                  <ul className={estilo.responsiveTable}>
                                    <li className={estilo.tableHeader}>
                                      <div className={estilo.col} id={estilo.col1}>
                                        ID
                                      </div>
                                      <div className={estilo.col} id={estilo.col2}>
                                        Solicitação
                                      </div>
                                      <div className={`${estilo.col} ${styles.status}`} id={estilo.col2}>
                                        Status
                                      </div>
                                      <div className={estilo.col} id={estilo.col3}>
                                        Opções
                                      </div>
                                    </li>
                                    { uniqueList2.length ?
                                    uniqueList2.slice(start, end).map((item, index) => (
                                      <li className={estilo.tableRow}  key={`${item.id}-${index}`}>
                                        <div className={estilo.col} id={estilo.col1} data-label="ID">
                                          {item.id}
                                        </div>
                                        <div className={estilo.col} id={estilo.col2} data-label="Solicitacão">
                                          <p className={styles.finalidade}>{item.finalidade}</p>
                                        </div>
                                        <div className={`${estilo.col} ${styles.status}`} id={estilo.col2} data-label="Status">
                                        
                                              {item.status === 'CRIADA' ? (
                                                <div className={styles.buttonsContainer}>
                                                  <p className={styles.tableTextB}>Aguardando</p>
                                                </div>
                                              ) : item.status === 'CANCELADA' ? (
                                                <div className={styles.buttonsContainer}>
                                                  <p className={styles.tableTextC}>Cancelada</p>
                                                </div>
                                              ) : (
                                                <div className={styles.buttonsContainer}>
                                                  <p className={styles.tableTextC}>Finalizada</p>
                                                </div>
                                              )}
                                       
                                    
                                        </div>
                                        <div className={estilo.col} id={estilo.col3} data-label="Opções">
                                          {buttonEdit === true ? (
                                            <div className={styles.blocoButton}>
                                                <div className={styles.buttonContainer}>
                                                <ButtonComp
                                                  acao={() => getModal(item.id)}
                                                  tipo="button"
                                                  estilo={styles.buttonSave}
                                                  texto="Aceitar"
                                                />
                                              </div>

                                              <div className={styles.buttonContainer}> 
                                              <ButtonComp
                                                acao={() => pegaID(item.id)}
                                                tipo="button"
                                                estilo={styles.buttonEdit2}
                                                texto="Editar"
                                              />
                                              </div>
                                            </div>
                                          ) : (
                                            console.log('')
                                          )}
                                        </div>
                                      </li>
                                    ))
                                    : <li  className={estilo.tableRow} >
                                      <p className={estilo.col}>Não há solicitações cadastradas</p>
                                      </li>
                                  }
                                  </ul>

                                  <div className={styles.pagination}>
                                    <ul className={styles.paginationList}>
                                      {Array.from({ length: totalPages }, (_, i) => (
                                        <li
                                          key={i}
                                          className={`${styles.paginationItem} ${
                                            i + 1 === currentPage ? styles.active : ''
                                          }`}
                                          onClick={() => handlePageChange(i + 1)}
                                        >
                                           {
                                            uniqueList2.length <= 5 ? '' : i + 1
                                          }
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              
                              }
                            </div>
             
                    </FormGroup> 
       
            </main>

            {loading === false ?
                
                <div className={styles.loader}>
                            <div className={styles.loaderInterno}>
                              <Loading />
                            </div>
                </div>
                :
                <div></div>
                }

  {<Modal className={styles.modalEstilo} show={modalShow} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Deseja realmente cancelar a solicitacao?</Modal.Title>
                  </Modal.Header>
                  <Modal.Footer>
                    <Button color="success" onClick={cancelarSolicitacao}>
                      SIM
                    </Button>
                    <Button color="danger" onClick={handleClose}>
                      FECHAR
                    </Button>
                  </Modal.Footer>
                </Modal>
                }

{<Modal className={styles.modalEstilo} show={modalIniciar} onHide={handleCloseIniciar}>
                  <Modal.Header>
                    <Modal.Title>Informe o motorista e o veículo para aceitar a solicitação com id - {idSolicitacaoAtual}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  <div className={styles.inputForm}>
                                <div className={styles.formGroup}>
                                            <span>Veículo</span>
                                            <Input className={styles.formField} type="select" onChange={(e) => setVeiculo(e.target.value)} value={veiculo}>
                                              <option value="" disabled>Selecione um carro</option>
                                              {Array.isArray(veiculos) && veiculos.map((carro, key) => (
                                                <option value={carro.id} className={styles.selectOption} key={key}>
                                                  {`${carro.modelo} - ${carro.placa}`}
                                                </option>
                                              ))}
                                            </Input>

                                </div>

                                <div className={styles.formGroup}>
                                    <span>Motorista</span>
                                    <Input className={styles.formField} type="select" onChange={(e) => setMotoristaValue(e.target.value)} value={motoristaValue}>
                                      <option value="" disabled>Selecione um motorista</option>
                                      {motorista && motorista.length > 0 ? (
                                        motorista.map((pessoa, index) => (
                                          <option value={pessoa.id} key={index}>{pessoa.nome}</option>
                                        ))
                                      ) : (
                                        <option value="" disabled>Nenhum motorista encontrado</option>
                                      )}
                                    </Input>

                                 </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                  <Button color="success" onClick={aceitarRequest}>
                      ACEITAR
                    </Button>
                    <Button color="danger" onClick={handleCloseIniciar}>
                      FECHAR
                    </Button>
                  </Modal.Footer>
                </Modal>
                }


{<Modal className={`${styles.modalEstilo2} ${styles.modalLarguraExtra}`} show={modalAtendimento} onHide={handleCloseModal}>
                  <Modal.Header className={styles.modalLargura}> 
                    <Modal.Title className={styles.modalLargura}>Quais solicitações você deseja juntar?</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className={styles.modalLargura}>
                  <div className={styles.blocoSetor}>
                              {totalItems > 0 ? (
                                <div>
                                  <ul className={estilo.responsiveTable}>
                                    <li className={estilo.tableHeader}>
                                      <div className={estilo.col} id={estilo.col1}>
                                        ID
                                      </div>
                                      <div className={estilo.col} id={estilo.col2}>
                                        Solicitação
                                      </div>
                                      <div className={estilo.col} id={estilo.col3}>
                                        Opção
                                      </div>
                                    </li>
                                    {uniqueList.slice(start2, end2).map((item, index) => (
                                      <li id={styles.tableContent} className={estilo.tableRow}  key={`${item.id}-${index}`}>
                                        <div className={estilo.col} id={estilo.col1} data-label="ID">
                                          {item.id}
                                        </div>
                                        <div className={estilo.col} id={estilo.col2} data-label="Solicitacão">
                                          <p className={styles.finalidade}>{item.finalidade}</p>
                                        </div>

                                        <div className={estilo.col} id={estilo.col3} data-label="Opções">
                                          {buttonEdit === true ? (
                                            // <div className={styles.blocoButton}>
                                            //   <input
                                            //     className={styles.inputRadio}
                                            //     // id={inputStyle.toggle}
                                            //     type="checkbox"
                                            //     name="opcoes"
                                            //     value={item.id}
                                            //     onClick={() => handleCheckboxChange(item.id)}
                                            //     checked={selectedItems.includes(item.id)} 
                                            //   />
                                            // </div>
                                         
                                            <Switch onChange={() => handleCheckboxChange(item.id)} checked={selectedItems.includes(item.id)}  colorScheme='green' size='md'></Switch>
                                          ) : (
                                            <div></div>
                                          )}
                                        </div>
                                      </li>
                                    ))}
                                  </ul>

                                  <div className={styles.pagination}>
                                    <ul className={styles.paginationList}>
                                      {Array.from({ length: totalPages2 }, (_, i) => (
                                        <li
                                          key={i}
                                          className={`${styles.paginationItem} ${
                                            i + 1 === currentPage2 ? styles.active : ''
                                          }`}
                                          onClick={() => handlePageChange2(i + 1)}
                                        >
                                          {
                                            uniqueList.length <= 20 ? '' : i + 1
                                          }
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              ) : loading === true ? (
                                // <div className={styles.blocoSolicitacao}>
                                //   <p className={styles.solicitacaoTitle}>Não há solicitações cadastradas</p>
                                // </div>
                                <Erro />
                              ) : (
                                console.log('')
                              )}
                            </div>
                  </Modal.Body>
                  <Modal.Footer className={styles.modalFooter}>
                    <div>
                      <p><span className={styles.modalPass}>Total de Passageiros: </span><span className={styles.paginationItem2}>{totalPassageiros < 0 ? 0 : totalPassageiros}</span></p>
                    </div>
                    <div className={styles.modalButtons}>
                      {
                        uniqueList.length > 0 ? 
                        <Button color="success" onClick={validaSolicitacoes}>
                        SALVAR
                      </Button>
                      :
                        <div></div>
                      }
                      <Button color="danger" onClick={closeModalOption}>
                        FECHAR
                      </Button>
                    </div>
                  </Modal.Footer>
                </Modal>
                }
        </section>
    )
};

export default GestorSolicitacoes;