import React from "react";
import axios from "axios";
import Label from "../../Components/Label/Label";
import styles from "../Veiculo/Dados.module.css";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import { FormGroup, Input, Col, Button } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import { json } from "react-router-dom";
import Table from "react-bootstrap/Table";
import ButtonComp from "../../Components/Button/Button";
import Loading from "../../Components/Loader/Loading";
import Loading2 from "../../Components/Loader/Loader2";
import Modal from "react-bootstrap/Modal";
import {API_BASE_URL} from '../../services/Constantes';
import { IMaskInput } from "react-imask";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
const Dados = () => {

    // Criando estados para receber os valores do Input
    const [tipoVeiculo, setTipoVeiculo] = useState('');
    const [tipoUso, setTipoUso] = useState('');
    const [tipoProp, setTipoProp] = useState('');
    const [tipoCateg, setTipoCateg] = useState('');

    // Captura o id do elemento que desejo excluir
    const [valueRemove, setValueRemove] = useState('');

    // Criando estados para receber os arrays já cadastrados
    const [arrayVeiculo, setArrayVeiculo] = useState([]);
    const [arrayUso, setArrayUso] = useState([]);
    const [arrayProp, setArrayProp] = useState([]);
    const [arrayCateg, setArrayCateg] = useState([]);
    const [idAtual, setIdAtual] = useState('');

    // Criando estado para o botão atualizar
    const [buttonUpdate, setButtonUpdate] = useState(false);
    const [buttonSave, setButtonSave] = useState(false);
    const [buttonAdd, setButtonAdd] = useState(false);
    const [buttonUpdate2, setButtonUpdate2] = useState(false);
    const [buttonSave2, setButtonSave2] = useState(false);
    const [buttonAdd2, setButtonAdd2] = useState(false);
    const [buttonUpdate3, setButtonUpdate3] = useState(false);
    const [buttonSave3, setButtonSave3] = useState(false);
    const [buttonAdd3, setButtonAdd3] = useState(false);
    const [buttonUpdate4, setButtonUpdate4] = useState(false);
    const [buttonSave4, setButtonSave4] = useState(false);
    const [buttonAdd4, setButtonAdd4] = useState(false);


    // Criando estado para atualizar os valores dos tipos de veículo
    const [tipoVeiculoAlter, setTipoVeiculoAlter] = useState('');
    const [tipoUsoAlter, setTipoUsoAlter] = useState('');
    const [tipoPropAlter, setTipoPropAlter] = useState('');
    const [tipoCategAlter, setTipoCategAlter] = useState('');


    // Loading
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(true);
    const [loading3, setLoading3] = useState(true);
    const [loading4, setLoading4] = useState(true);
 
    // Estado para o modal
    const [modalErro, setModalErro] = useState(false);
    const [modalQuestion, setModalQuestion] = useState(false);
    const [modalQuestion2, sectionModalQuestion2] = useState(false);
    const [modalQuestion3, sectionModalQuestion3] = useState(false);
    const [modalQuestion4, sectionModalQuestion4] = useState(false);
    const handleShowModalErro = () => setModalErro(true);
    const handleCloseModalErro = () => setModalErro(false);
    const handleShowModalQuestion = () => setModalQuestion(true);
    const handleCloseModalQuestion = () => setModalQuestion(false);
    const handeShowModalQuestion2 = () => sectionModalQuestion2(true);
    const handleCloseModalQuestion2 = () => sectionModalQuestion2(false);
    const handleShowModalQuestion3 = () => sectionModalQuestion3(true);
    const handleCloseModalQuestion3 = () => sectionModalQuestion3(false);
    const handleShowModalQuestion4 = () => sectionModalQuestion4(true);
    const handleCloseModalQuestion4 = () => sectionModalQuestion4(false);

    

    // Criando requisições GET para pegar esses dados
    useEffect(() => {
        setLoading(false);
        window.scrollTo(0, 0); 
        fetch(`${API_BASE_URL}/api/tipoVeiculo`, {
            method: "GET",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo"),
                'Authorization': `Bearer ${localStorage.getItem("token")}` 
            }
            }).then((response) => {
                return response.json();
              })
              .then((data) => {
                setArrayVeiculo(data);
                setLoading(true);
              })
              .catch((error) => {
                console.log("Erro na API dos tipos de veículo");
                setLoading(true);
              }); 
        
    },[]);


    // Requisição GET para pegar os dados dos tipos de uso do veículo
    useEffect(() => {
        setLoading(false);
        window.scrollTo(0, 0); 
        fetch(`${API_BASE_URL}/api/tipoUsoVeiculo`, {
            method: "GET",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo"),
                'Authorization': `Bearer ${localStorage.getItem("token")}` 
            }
            }).then((response) => {
                return response.json();
              })
              .then((data) => {
                setArrayUso(data);
                setLoading(true);
              })
              .catch((error) => {
                console.log("Erro na API dos tipos de veículo");
                setLoading(true);
              }); 
        
    },[]);


    // Pegando os dados dos tipos de proprietário do veículo
    useEffect(() => {
        setLoading(false);
        window.scrollTo(0, 0); 
        fetch(`${API_BASE_URL}/api/tipoProprietarioVeiculo`, {
            method: "GET",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo"),
                'Authorization': `Bearer ${localStorage.getItem("token")}` 
            }
            }).then((response) => {
                return response.json();
              })
              .then((data) => {
                setArrayProp(data);
                setLoading(true);
              })
              .catch((error) => {
                console.log("Erro na API dos tipos de veículo");
                setLoading(true);
              }); 
        
    },[]);


    useEffect(() => {
        setLoading(false);
        window.scrollTo(0, 0); 
        fetch(`${API_BASE_URL}/api/categoriaVeiculo`, {
            method: "GET",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo"),
                'Authorization': `Bearer ${localStorage.getItem("token")}` 
            }
            }).then((response) => {
                return response.json();
              })
              .then((data) => {
                setArrayCateg(data);
                setLoading(true);
              })
              .catch((error) => {
                console.log("Erro na API dos tipos de veículo");
                setLoading(true);
              }); 
        
    },[]);


    // Criando funções para cadastro dos dados do veículo
    const postTipoVeiculo = () => {
        fetch("", {
            method: 'POST',
            body: JSON.stringify({ 
                "nome": tipoVeiculo
            }), 
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },      
        }).then((response) => response.json()).then((json) => 
        
        axios.get(`${API_BASE_URL}/api/tipoVeiculo`).then((response) => {
            setArrayVeiculo(response.data);
            setLoading2(true);
        }).catch(() => {
            console.log("Erro na API dos tipos de veículo");
        })
        ).catch(
            console.log(json.message)
        );
        
        setTipoVeiculo('');
    };

    // Cadastro - Tipo de Uso de Veículo
    const postTipoUso = () => {
        fetch(`${API_BASE_URL}/api/tipoUsoVeiculo`, {
            method: 'POST',
            body: JSON.stringify({ 
                "nome": tipoUso
            }), 
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },      
        }).then((response) => response.json()).then((json) => 
        
        axios.get(`${API_BASE_URL}/api/tipoUsoVeiculo`).then((response) => {
            setArrayUso(response.data);
            setLoading2(true);
        }).catch(() => {
            console.log("Erro na API dos tipos de veículo");
        })
        ).catch(
            console.log(json.message)
        );
        
        setTipoUso('');
    };

    // Cadastro - Tipo de Proprietário do Veículo
    const postTipoProp = () => {
        fetch(`${API_BASE_URL}/api/tipoProprietarioVeiculo`, {
            method: 'POST',
            body: JSON.stringify({ 
                "nome": tipoProp
            }), 
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },      
        }).then((response) => response.json()).then((json) => 
        
        axios.get(`${API_BASE_URL}/api/tipoProprietarioVeiculo`).then((response) => {
            setArrayProp(response.data);
            setLoading3(true);
        }).catch(() => {
            console.log("Erro na API dos tipos de veículo");
        })
        ).catch(
            console.log(json.message)
        );
        
        setTipoUso('');
    };

    // Cadastro - Categoria do Veículo
    const postTipoCateg= () => {
        fetch(`${API_BASE_URL}/api/categoriaVeiculo`, {
            method: 'POST',
            body: JSON.stringify({ 
                "nome": tipoCateg
            }), 
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },      
        }).then((response) => response.json()).then((json) => 
        
        axios.get(`${API_BASE_URL}/api/categoriaVeiculo`).then((response) => {
            setArrayCateg(response.data);
            setLoading4(true);
        }).catch(() => {
            console.log("Erro na API dos tipos de veículo");
        })
        ).catch(
            console.log(json.message)
        );
        
        setTipoCateg('');
    };

    // Criando função para alterar elemento já cadastrado

    const putAlterVeiculo  = async (idAlter, valor) => {
        setLoading(false);
        window.scrollTo(0, 0);
        try {
          const response = await fetch(
            `${API_BASE_URL}/api/tipoVeiculo/${idAlter}`,
            {
              method: 'PUT',
              body: JSON.stringify({
                "nome": valor,
            }), 
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo")
              },
            }
          ); 
          const json = await response.json();
          console.log(json);

        axios.get(`${API_BASE_URL}/api/tipoVeiculo`).then((response) => {
            setArrayVeiculo(response.data);
            setLoading2(true);
        });
        } 
        catch(error){ 
          console.log('erro', error); 
          notify("Não foi possível realizar o cadastro do tipo de veículo");
        }
    }

    // const putAlterVeiculo = (idAlter, valor) => {
    //     fetch(`${API_BASE_URL}/api/tipoVeiculo/${idAlter}`, {
    //       method: 'PUT',
    //       body: JSON.stringify({
    //         "nome": valor,
    //     }), 
    //       headers: {
    //         'Content-type': 'application/json; charset=UTF-8',
    //       },
    //     }).then((response) => response.json()).then((json) =>  
        
    //     axios.get("${API_BASE_URL}/api/tipoVeiculo").then((response) => {
    //         setArrayVeiculo(response.data);
    //         setLoading2(true);
    //     }).catch(() => {
    //         console.log("Erro na API dos tipos de veículo");
    //     })
    //     );
    //   }

      // Alterar o tipo de uso do veículo
      const putAlterUso = (idAlter, valor) => {
        fetch(`${API_BASE_URL}/api/tipoUsoVeiculo/${idAlter}`, {
          method: 'PUT',
          body: JSON.stringify({
            "nome": valor,
        }), 
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }).then((response) => response.json()).then((json) =>  
        
        axios.get(`${API_BASE_URL}/api/tipoUsoVeiculo`).then((response) => {
            setArrayUso(response.data);
            setLoading2(true);
        }).catch(() => {
            console.log("Erro na API dos tipos de veículo");
        })
        );
      }

      // Alterar - Tipo de Proprietário do Veículo
      const putAlterProp = (idAlter, valor) => {
        fetch(`${API_BASE_URL}/api/tipoProprietarioVeiculo/${idAlter}`, {
          method: 'PUT',
          body: JSON.stringify({
            "nome": valor,
        }), 
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }).then((response) => response.json()).then((json) =>  
        
        axios.get(`${API_BASE_URL}/api/tipoProprietarioVeiculo`).then((response) => {
            setArrayProp(response.data);
            setLoading3(true);
        }).catch(() => {
            console.log("Erro na API dos tipos de veículo");
        })
        );
      }

      // Alterar - Tipo de Categoria
      const putAlterCateg = (idAlter, valor) => {
        fetch(`${API_BASE_URL}/api/categoriaVeiculo/${idAlter}`, {
          method: 'PUT',
          body: JSON.stringify({
            "nome": valor,
        }), 
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }).then((response) => response.json()).then((json) =>  
        
        axios.get(`${API_BASE_URL}/api/categoriaVeiculo`).then((response) => {
            setArrayCateg(response.data);
            setLoading2(true);
        }).catch(() => {
            console.log("Erro na API dos tipos de veículo");
        })
        );
      }

      
    const removeVeiculo = (idAlter) => {
        fetch(`${API_BASE_URL}/api/tipoVeiculo/${idAlter}`, {
            method: 'DELETE',
          }).then((response) => response.statusText === 'OK' ?
          (setLoading2(true), handleShowModalErro()) :
          atualizaVeiculo()
          );
       
        setValueRemove('')
    }

    const removeUso = (idAlter) => {
        fetch(`${API_BASE_URL}/api/tipoUsoVeiculo/${idAlter}`, {
            method: 'DELETE',
          }).then((response) => response.statusText === 'OK' ?
          (setLoading2(true), handleShowModalErro()) :
          atualizaUso()
          );
       
        setValueRemove('');
    }

    const removeProp = (idAlter) => {
        fetch(`${API_BASE_URL}/api/tipoProprietarioVeiculo/${idAlter}`, {
            method: 'DELETE',
          }).then((response) => response.statusText === 'OK' ?
          (setLoading3(true), handleShowModalErro()) :
          atualizaProp()
          );
       
        setValueRemove('');
    }

    const removeCateg = (idAlter) => {
        fetch(`${API_BASE_URL}/api/categoriaVeiculo/${idAlter}`, {
            method: 'DELETE',
          }).then((response) => response.statusText === 'OK' ?
          (setLoading4(true), handleShowModalErro()) :
          atualizaCateg()
          );
       
        setValueRemove('');
    }
      
// Criando função para dar um restart na api
 function atualizaVeiculo(){
    axios.get(`${API_BASE_URL}/api/tipoVeiculo`).then((response) => {
        setArrayVeiculo(response.data);
        setLoading2(true);
    }).catch(() => {
        console.log("Erro na API dos tipos de veículo");
    });
 }

 // Criando função para dar um restart na api
 function atualizaUso(){
    axios.get(`${API_BASE_URL}/api/tipoUsoVeiculo`).then((response) => {
        setArrayUso(response.data);
        setLoading2(true);
    }).catch(() => {
        console.log("Erro na API dos tipos de veículo");
    });
 }

  // Criando função para dar um restart na api
  function atualizaProp(){
    axios.get(`${API_BASE_URL}/api/tipoProprietarioVeiculo`).then((response) => {
        setArrayProp(response.data);
        setLoading3(true);
    }).catch(() => {
        console.log("Erro na API dos tipos de veículo");
    });
 }

   // Criando função para dar um restart na api
   function atualizaCateg(){
    axios.get(`${API_BASE_URL}/api/categoriaVeiculo`).then((response) => {
        setArrayCateg(response.data);
        setLoading4(true);
    }).catch(() => {
        console.log("Erro na API dos tipos de veículo");
    });
 }


    // Função para validar dados

    function validaVeiculo(){
        setLoading2(false);
        postTipoVeiculo() ;
        setButtonAdd(false);
    }

    function validaUso(){
        setLoading2(false);
        postTipoUso();
        setButtonAdd2(false);
    }

    function validaProp(){
        setLoading3(false);
        postTipoProp();
        setButtonAdd3(false);
    }

    function validaCateg(){
        setLoading4(false);
        postTipoCateg();
        setButtonAdd4(false);
    }

    // Funções para atualizar os elementos
    function handleAtualizar(id, nome){
        setButtonUpdate(true);
        setButtonSave(true);
        setIdAtual(id);
        setTipoVeiculoAlter(nome);

        
    }

    function handleAtualiza2(id, nome){
        setButtonUpdate2(true);
        setButtonSave2(true);
        setIdAtual(id);
        setTipoUsoAlter(nome);
    }

    function handleAtualiza3(id, nome){
        setButtonUpdate3(true);
        setButtonSave3(true);
        setIdAtual(id);
        setTipoPropAlter(nome);
    }

    function handleAtualiza4(id, nome){
        setButtonUpdate4(true);
        setButtonSave4(true);
        setIdAtual(id);
        setTipoCategAlter(nome);
    }
    
    function handleSaveAlter(id){
        setLoading2(false);
        setButtonSave(false);
        setButtonSave2(false);
        setButtonSave3(false);
        setButtonSave4(false);
        setButtonUpdate(false);
        setButtonUpdate2(false);
        setButtonUpdate3(false);
        setButtonUpdate4(false);
        putAlterVeiculo(id, tipoVeiculoAlter);
        putAlterUso(id, tipoUsoAlter);
        putAlterProp(id, tipoPropAlter);
        putAlterCateg(id, tipoCategAlter);
        
    }

    // Função para questionar se deseja remover
    function handleRemoveAlter(id){
        setValueRemove(id);
        handleShowModalQuestion();
   
    }
    function handleRemoveUso(id){
        setValueRemove(id);
        handeShowModalQuestion2();
        
   
    }

    function handleRemoveProp(id){
        setValueRemove(id);
        handleShowModalQuestion3();
   
    }

    function handleRemoveCateg(id){
        setValueRemove(id);
        handleShowModalQuestion4();
   
    }
    

    // Função para remover
    function handleRemover(){
        handleCloseModalQuestion();
        removeVeiculo(valueRemove);
        setLoading2(false);
        
    }

    function handleRemoverUso(){
        handleCloseModalQuestion2();
        removeUso(valueRemove);
        setLoading2(false);
       
    }

    function handleRemoverProp(){
        handleCloseModalQuestion3();
        removeProp(valueRemove);
        setLoading3(false);
       
    }

    function handleRemoverCateg() {
        handleCloseModalQuestion4();
        removeCateg(valueRemove);
        setLoading4(false);
    }
    const notify = (msg) => toast(msg);
 
    return (
        <section className={styles.cadastroDadosVeiculo}>
            <Header titulo="Cadastros Adicionais do Veículo" altura="240px"/>
            <Menu />
            
            <ToastContainer />
            <main className={styles.mainContent}>
                <section className={styles.formBlock}>                 
                    <section className={styles.mainTable}>
               
                        <Table className={styles.table} size="sm">
                            <thead className={styles.tableInicio}>
                                <tr className={styles.tableHeader}>
                                    <th >
                                        <div className={styles.tableName}>Tipo de Veículo</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody display="flex" align="center">
                            {arrayVeiculo.map((item, index) => (
                        
                                <tr key={index+1} className={styles.blocoTableBorder}>
                
                                    <td className={styles.valueTable}>
                                    <div className={styles.componentTable}>
                                    {
                                            buttonUpdate === false ? item.nome :
                                            item.id === idAtual && buttonSave === true ?
                                            // <Input  className={styles.inputForm2} value={tipoVeiculoAlter} onChange={(e) => setTipoVeiculoAlter(e.target.value)}/>
                                            <div className={styles.formGroup}>
                                                    <span>Tipo de Veículo</span>
                                                    <Input  className={styles.formField} value={tipoVeiculoAlter} onChange={(e) => setTipoVeiculoAlter(e.target.value)}/>
                                            </div>
                                            :
                                            item.nome
                                        }
                                    </div>
                                    </td>

                                    <td className={styles.valuesTable}>
                                        <div className={styles.buttonContainer}>
                                            {
                                                buttonUpdate === false ? 
                                            
                                                <ButtonComp tipo="button" texto="Atualizar" acao={() => handleAtualizar(item.id, item.nome)} estilo={styles.buttonUpdate}>Atualizar</ButtonComp>

                                                : 
                                                item.id === idAtual  && buttonSave === true ?
                                                <ButtonComp texto="Salvar" acao={() => handleSaveAlter(item.id)} estilo={styles.buttonUpdate}>Salvar</ButtonComp>
                                                :
                                                <ButtonComp tipo="button" texto="Atualizar" acao={() => handleAtualizar(item.id, item.nome)} estilo={styles.buttonUpdate}>Atualizar</ButtonComp>
                                            }
                                            {
                                                buttonUpdate === false ?
                                                <ButtonComp tipo="button" texto="Remover" acao={() => handleRemoveAlter(item.id)} estilo={styles.buttonRemove}>Remover</ButtonComp> :
                                                item.id === idAtual ?
                                                <ButtonComp texto="Cancelar" acao={() => setButtonUpdate(false)} estilo={styles.buttonRemove}>Cancelar</ButtonComp> :
                                                <ButtonComp tipo="button" texto="Remover" acao={() => handleRemoveAlter(item.id)} estilo={styles.buttonRemove}>Remover</ButtonComp>
                                            }

                                        </div>
                                    </td>

                                </tr>

                            ))
                            
                            
                            } 
                            </tbody>
                        </Table>
                        <div className={styles.loader2}>
                                    {!loading2  && <Loading2 />}
                        </div>

                        
                        <div className={styles.blocoAdiciona}>
                            {
                                buttonAdd === true ? 
                                <div className={styles.inputBloco}>
                                    <Input name="inputVeiculo" maxLength={25} className={styles.inputForm} type="text" placeholder="Informe o tipo de Veículo" onChange={(e) => setTipoVeiculo(e.target.value)} value={tipoVeiculo} />
                                    <label for="inputVeiculo">Tipo de Veículo</label>
                                </div>
                                :
                                <div></div>
                            }
                            
                            <div className={styles.blocoButtons}>
                                {
                                    buttonAdd === false ?
                                    <Button onClick={() => setButtonAdd(true)} className={styles.buttonUpdate3}>Novo</Button>
                                    :
                                    <Button onClick={validaVeiculo} className={styles.buttonUpdate2}>Salvar</Button>
                                }

{
                                    buttonAdd === true ?
                                    <Button onClick={() => setButtonAdd(false)}  className={styles.buttonRemove}>Cancelar</Button> 
                                    :
                                    <div></div>
                                }
                            </div>
                        </div>
         
                    </section>

                    <section className={styles.mainTable}>
                    
                            <Table className={styles.table} size="sm">
                            <thead>
                                <tr className={styles.tableHeader}>
                                <th >
                                    <div className={styles.tableName}>Tipo de Uso do Veículo</div>
                                </th>
                                <th>
                                <div className={styles.tableName}></div>
                                </th>
                                
                                </tr>
                            </thead>
                            <tbody display="flex" align="center">
                                {arrayUso.map((item, index) => (
                        
                                <tr key={index+1} className={styles.blocoTableBorder}>
                
                                    <td width="50%" className={styles.valueTable}>
                                        <div className={styles.componentTable}>
                                        {
                                            buttonUpdate2 === false ? item.nome :
                                            item.id === idAtual && buttonSave2 === true ?
                                                <Input  className={styles.inputForm2} value={tipoUsoAlter} onChange={(e) => setTipoUsoAlter(e.target.value)}/>
                                            :
                                            item.nome
                                        }
                                        </div>
                                    </td>

                                    <td className={styles.valuesTable}>
                                        <div className={styles.buttonContainer}>
                                            {
                                                buttonUpdate2 === false ? 
                                                
                                                <ButtonComp tipo="button" texto="Atualizar" acao={() => handleAtualiza2(item.id, item.nome)} estilo={styles.buttonUpdate}>Atualizar</ButtonComp>

                                                : 
                                                item.id === idAtual  && buttonSave2 === true ?
                                                <ButtonComp texto="Salvar" acao={() => handleSaveAlter(item.id)} estilo={styles.buttonUpdate}>Salvar</ButtonComp>
                                                :
                                                <ButtonComp tipo="button" texto="Atualizar" acao={() => handleAtualiza2(item.id, item.nome)} estilo={styles.buttonUpdate}>Atualizar</ButtonComp>
                                            }
                                            {
                                                buttonUpdate2 === false ?
                                                    <ButtonComp tipo="button" texto="Remover" acao={() => handleRemoveUso(item.id)} estilo={styles.buttonRemove}>Remover</ButtonComp> :
                                                    item.id === idAtual ?
                                                    <ButtonComp texto="Cancelar" acao={() => setButtonUpdate2(false)} estilo={styles.buttonRemove}>Cancelar</ButtonComp> :
                                                    <ButtonComp tipo="button" texto="Remover" acao={() => handleRemoveUso(item.id)} estilo={styles.buttonRemove}>Remover</ButtonComp>
                                            }

                                        </div>
                                    </td>

                                </tr>

                            ))
                            
                            
                            } 
                            </tbody>
                            </Table>
                            <div className={styles.loader2}>
                                        {!loading2  && <Loading />}
                            </div>

                    
                            <div className={styles.blocoAdiciona}>
                                {
                                    buttonAdd2 === true ? 
                                    <Input maxLength={25} className={styles.inputForm} type="text" placeholder="Informe o tipo de Veículo" onChange={(e) => setTipoUso(e.target.value)} value={tipoUso} />
                                    :
                                    <div></div>
                                }
                                
                                <div className={styles.blocoButtons}>
                                    {
                                        buttonAdd2 === false ?
                                        <Button onClick={() => setButtonAdd2(true)} className={styles.buttonUpdate3}>Novo</Button>
                                        :
                                        <Button onClick={validaUso} className={styles.buttonUpdate2}>Salvar</Button>
                                    }

                {
                                        buttonAdd2 === true ?
                                        <Button onClick={() => setButtonAdd2(false)}  className={styles.buttonRemove}>Cancelar</Button> 
                                        :
                                        <div></div>
                                    }
                                </div>
                            </div>

                    </section>

                    
                    <section className={styles.mainTable}>
                    
                            <Table className={styles.table} size="sm">
                            <thead>
                                <tr className={styles.tableHeader}>
                                <th >
                                    <div className={styles.tableName}>Tipo de Proprietário do Veículo</div>
                                </th>
                                <th>
                                <div className={styles.tableName}></div>
                                </th>
                                
                                </tr>
                            </thead>
                            <tbody display="flex" align="center">
                                {arrayProp.map((item, index) => (
                        
                                <tr key={index+1} className={styles.blocoTableBorder}>
                
                                    <td width="50%" className={styles.valueTable}>
                                        <div className={styles.componentTable}>
                                        {
                                            buttonUpdate3 === false ? item.nome :
                                            item.id === idAtual && buttonSave3 === true ?
                                                <Input  className={styles.inputForm2} value={tipoPropAlter} onChange={(e) => setTipoPropAlter(e.target.value)}/>
                                            :
                                            item.nome
                                        }
                                        </div>
                                    </td>

                                    <td className={styles.valuesTable}>
                                        <div className={styles.buttonContainer}>
                                            {
                                                buttonUpdate3 === false ? 
                                                
                                                <ButtonComp tipo="button" texto="Atualizar" acao={() => handleAtualiza3(item.id, item.nome)} estilo={styles.buttonUpdate}>Atualizar</ButtonComp>

                                                : 
                                                item.id === idAtual  && buttonSave3 === true ?
                                                <ButtonComp texto="Salvar" acao={() => handleSaveAlter(item.id)} estilo={styles.buttonUpdate}>Salvar</ButtonComp>
                                                :
                                                <ButtonComp tipo="button" texto="Atualizar" acao={() => handleAtualiza3(item.id, item.nome)} estilo={styles.buttonUpdate}>Atualizar</ButtonComp>
                                            }
                                            {
                                                buttonUpdate3 === false ?
                                                    <ButtonComp tipo="button" texto="Remover" acao={() => handleRemoveProp(item.id)} estilo={styles.buttonRemove}>Remover</ButtonComp> :
                                                    item.id === idAtual ?
                                                    <ButtonComp texto="Cancelar" acao={() => setButtonUpdate3(false)} estilo={styles.buttonRemove}>Cancelar</ButtonComp> :
                                                    <ButtonComp tipo="button" texto="Remover" acao={() => handleRemoveProp(item.id)} estilo={styles.buttonRemove}>Remover</ButtonComp>
                                            }

                                        </div>
                                    </td>

                                </tr>

                            ))
                            
                            
                            } 
                            </tbody>
                            </Table>
                            <div className={styles.loader3}>
                                        {!loading3  && <Loading2 />}
                            </div>

                    
                            <div className={styles.blocoAdiciona}>
                                {
                                    buttonAdd3 === true ? 
                                    <Input maxLength={25} className={styles.inputForm} type="text" placeholder="Informe o tipo de proprietário do Veículo" onChange={(e) => setTipoProp(e.target.value)} value={tipoProp} />
                                    :
                                    <div></div>
                                }
                                
                                <div className={styles.blocoButtons}>
                                    {
                                        buttonAdd3 === false ?
                                        <Button onClick={() => setButtonAdd3(true)} className={styles.buttonUpdate3}>Novo</Button>
                                        :
                                        <Button onClick={validaProp} className={styles.buttonUpdate2}>Salvar</Button>
                                    }

                {
                                        buttonAdd3 === true ?
                                        <Button onClick={() => setButtonAdd3(false)}  className={styles.buttonRemove}>Cancelar</Button> 
                                        :
                                        <div></div>
                                    }
                                </div>
                            </div>

                    </section>

                    <section className={styles.mainTable}>
                    
                    <Table className={styles.table} size="sm">
                    <thead>
                        <tr className={styles.tableHeader}>
                        <th >
                            <div className={styles.tableName}>Tipo de Categoria do Veículo</div>
                        </th>
                        <th>
                        <div className={styles.tableName}></div>
                        </th>
                        
                        </tr>
                    </thead>
                    <tbody display="flex" align="center">
                        {arrayCateg.map((item, index) => (
                
                        <tr key={index+1} className={styles.blocoTableBorder}>
        
                            <td width="50%" className={styles.valueTable}>
                                <div className={styles.componentTable}>
                                {
                                    buttonUpdate4 === false ? item.nome :
                                    item.id === idAtual && buttonSave4 === true ?
                                        <Input  className={styles.inputForm2} value={tipoCategAlter} onChange={(e) => setTipoCategAlter(e.target.value)}/>
                                    :
                                    item.nome
                                }
                                </div>
                            </td>

                            <td className={styles.valuesTable}>
                                <div className={styles.buttonContainer}>
                                    {
                                        buttonUpdate4 === false ? 
                                        
                                        <ButtonComp tipo="button" texto="Atualizar" acao={() => handleAtualiza4(item.id, item.nome)} estilo={styles.buttonUpdate}>Atualizar</ButtonComp>

                                        : 
                                        item.id === idAtual  && buttonSave4 === true ?
                                        <ButtonComp texto="Salvar" acao={() => handleSaveAlter(item.id)} estilo={styles.buttonUpdate}>Salvar</ButtonComp>
                                        :
                                        <ButtonComp tipo="button" texto="Atualizar" acao={() => handleAtualiza4(item.id, item.nome)} estilo={styles.buttonUpdate}>Atualizar</ButtonComp>
                                    }
                                    {
                                        buttonUpdate4 === false ?
                                            <ButtonComp tipo="button" texto="Remover" acao={() => handleRemoveCateg(item.id)} estilo={styles.buttonRemove}>Remover</ButtonComp> :
                                            item.id === idAtual ?
                                            <ButtonComp texto="Cancelar" acao={() => setButtonUpdate4(false)} estilo={styles.buttonRemove}>Cancelar</ButtonComp> :
                                            <ButtonComp tipo="button" texto="Remover" acao={() => handleRemoveCateg(item.id)} estilo={styles.buttonRemove}>Remover</ButtonComp>
                                    }

                                </div>
                            </td>

                        </tr>

                    ))
                    
                    
                    } 
                    </tbody>
                    </Table>
                    <div className={styles.loader3}>
                                        {!loading4  && <Loading2 />}
                    </div>

            
                    <div className={styles.blocoAdiciona}>
                        {
                            buttonAdd4 === true ? 
                            <Input maxLength={25} className={styles.inputForm} type="text" placeholder="Informe o tipo de categoria" onChange={(e) => setTipoCateg(e.target.value)} value={tipoCateg} />
                            :
                            <div></div>
                        }
                        
                        <div className={styles.blocoButtons}>
                            {
                                buttonAdd4 === false ?
                                <Button onClick={() => setButtonAdd4(true)} className={styles.buttonUpdate3}>Novo</Button>
                                :
                                <Button onClick={validaCateg} className={styles.buttonUpdate2}>Salvar</Button>
                            }

        {
                                buttonAdd4 === true ?
                                <Button onClick={() => setButtonAdd4(false)}  className={styles.buttonRemove}>Cancelar</Button> 
                                :
                                <div></div>
                            }
                        </div>
                    </div>

                    </section>
                 

                     <div className={styles.loader}>
                         {!loading  && <Loading />}
                     </div>

                    
                </section>
            </main>

            {<Modal className={styles.modalEstilo} show={modalErro} onHide={handleCloseModalErro}>
                <Modal.Header closeButton>
                  <Modal.Title>Não é possível deletar o item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                O tipo de veículo já está sendo utilizado
                </Modal.Body>
                <Modal.Footer>
                  <Button color="danger" onClick={handleCloseModalErro}>
                    FECHAR
                  </Button>
                </Modal.Footer>
              </Modal>
              }

        {<Modal className={styles.modalEstilo} show={modalQuestion} onHide={handleCloseModalQuestion}>
                        <Modal.Header closeButton>
                        <Modal.Title>Deseja realmente excluir este item?</Modal.Title>
                        </Modal.Header>
                        <Modal.Footer>
                        <Button color="success" onClick={() => handleRemover()}>
                            SIM
                        </Button>
                        <Button color="danger" onClick={handleCloseModalQuestion}>
                            FECHAR
                        </Button>
                        </Modal.Footer>
                    </Modal>
                    }

    {<Modal className={styles.modalEstilo} show={modalQuestion2} onHide={handleCloseModalQuestion2}>
                            <Modal.Header closeButton>
                            <Modal.Title>Deseja realmente excluir este item?</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer>
                            <Button color="success" onClick={() => handleRemoverUso()}>
                                SIM
                            </Button>
                            <Button color="danger" onClick={handleCloseModalQuestion2}>
                                FECHAR
                            </Button>
                            </Modal.Footer>
                        </Modal>
                        }

    {<Modal className={styles.modalEstilo} show={modalQuestion3} onHide={handleCloseModalQuestion3}>
                                <Modal.Header closeButton>
                                <Modal.Title>Deseja realmente excluir este item?</Modal.Title>
                                </Modal.Header>
                                <Modal.Footer>
                                <Button color="success" onClick={() => handleRemoverProp()}>
                                    SIM
                                </Button>
                                <Button color="danger" onClick={handleCloseModalQuestion3}>
                                    FECHAR
                                </Button>
                                </Modal.Footer>
                            </Modal>
                            }

                            
    {<Modal className={styles.modalEstilo} show={modalQuestion4} onHide={handleCloseModalQuestion4}>
                                <Modal.Header closeButton>
                                <Modal.Title>Deseja realmente excluir este item?</Modal.Title>
                                </Modal.Header>
                                <Modal.Footer>
                                <Button color="success" onClick={() => handleRemoverCateg()}>
                                    SIM
                                </Button>
                                <Button color="danger" onClick={handleCloseModalQuestion4}>
                                    FECHAR
                                </Button>
                                </Modal.Footer>
                            </Modal>
                            }
        </section>
    )
};

export default Dados;