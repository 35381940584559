import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { Button } from "reactstrap";
import { Form, Input } from "reactstrap";
import Label from "../../Components/Label/Label";
import login from "../Login/Login.module.css";
import Usuario from "../../Contexts/Token";
import Loading from "../../Components/Loader/Loading";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import IconGov from "../../assets/download.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {API_BASE_URL_LOGIN} from "../../services/ConstanteLogin";
const Login = () =>{
  useEffect(() => {
    document.title = 'ProGenTran';
  }, []);
    // criando estado para pegar os valores dos inputs
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [modal, setModal] = useState(false);
    const handleShow = () => setModal(true); 
    const handleClose = () => setModal(false);

    let token;
    let idUsuario;
    let idModule = 21;

    // Estado do loader
    const [loading, setLoading] = useState(true);

    // Definindo valor do contexto
    const {user, setUser} = useContext(Usuario);
    const [count, setCount] = useState(0);

    // Estado para mostrar ou ocultar a senha
    const [show, setShow] = useState(false);
      // Histoy
      let navigate = useNavigate();
      function handlePage(){
        setLoading(true);
        navigate('/gestor/listar-solicitacao');
         
      }

      function handlePage3(){
        setLoading(true);
        navigate('/solicitante/listar-solicitacoes');
         
      }

      function handlePage2(){
        setLoading(true);
        navigate('/motorista/listar-viagem');
         
      }
    
    const postLogin = async () => {
        setLoading(false);
        try {
          const response = await fetch(
            `${API_BASE_URL_LOGIN}/login`,
            {
              method: "POST",
              body: JSON.stringify({
                str_usuario: email,
                password: password,
              }),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
              },
            }
          );
          const json = await response.json();
          localStorage.setItem("token", json.data.token); // storing the token in local storage
          localStorage.setItem("IdUsuario", json.data.user.int_usuario_id)
          setEmail("");
          setPassword("");
          console.log(json);
        } catch (error) {
          console.log(error);
          
        };
         token = localStorage.getItem("token");
         idUsuario = localStorage.getItem("IdUsuario")
         console.log(token);
         console.log(idUsuario);
         postPerfis();
      };

    const postPerfis = async () => {
      try {
        const response2 = await fetch(
          `${API_BASE_URL_LOGIN}/modules`,
          {
            method: "POST",
            body: JSON.stringify({
              id_user: idUsuario,
              id_module: idModule,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              'Authorization': `Bearer ${token}` 
            },
          }
        );
        if(response2.ok){
          const json2 = await response2.json();
          console.log(json2);
          localStorage.setItem("nome", json2.data.User.str_nome); 
          // localStorage.setItem("tipo", (json2.data.permissions[0].perfil).toLowerCase());
          if (json2.data.permissions && json2.data.permissions.length > 0) {
            localStorage.setItem("tipo", json2.data.permissions[0].perfil.toLowerCase());
            console.log("Teste")
          } else {
       
            setLoading(true);
            setEmail("");
            setPassword("");
            notify("Erro ao acessar o sistema");
            console.clear();
          }
          localStorage.setItem("telefone", json2.data.User.str_telefone);
          localStorage.setItem("cpf", json2.data.User.int_cpf);
          localStorage.setItem("cargo", json2.data.User.str_cargo);
          localStorage.setItem("email", json2.data.User.str_email);
          localStorage.setItem("email", json2.data.User.str_email);

          if(localStorage.getItem("tipo") === 'gestor') {
            handlePage();
          } else if(localStorage.getItem("tipo") === 'solicitante'){
            handlePage3();
          }

          else if (localStorage.getItem("tipo") === 'motorista') {
            handlePage2();
          } 
          // localStorage.getItem("tipo") === 'gestor' ? 
          // handlePage() :
          // localStorage.getItem("tipo") === 'solicitante' ?
          // handlePage3()
          // : handlePage2();
        } else {
          setLoading(true);
          setEmail("");
          setPassword("");
          notify();
          console.clear();
        }
      } catch (error) {
        console.log(error);
        setLoading(true);
        notify2();
        console.log("Deu erro nesta linha");
      };
    }

    function exibirSenha(){
      setShow(!show);
    }

        // Função para validar os campos
        let validaCampos = yup.object().shape({
          email: yup.string().default(email).required("O email é obrigatório!"),
          password: yup.string().default(password).required("A senha é obrigatório!"),
  
      });

      const { register, handleSubmit, formState: { errors }, reset} = useForm({
        resolver: yupResolver(validaCampos),
    })
    const notify = () => toast("Usuário ou senha incorretos!");
    const notify2 = () => toast("Você não tem permissão para acessar o sistema!");

 

    const onSubmitHandler = (data) => {
      postLogin();
      reset({})
      
   }
    return (
      
       <section className={login.container}>
        <ToastContainer />
            <div className={login.logo}>
              <img width="375px" src={IconGov} alt="Icon do Governo do Estado do Maranhão"/>
            </div>
            <div className={login.blocoLogin}>
        
                <form  onSubmit={handleSubmit(onSubmitHandler)} >
                  <div className={login.formulario}>
                    <div className={login.blocoInput}>
                          {/* <Label texto="Usuário" estilo={login.labels}/> */}
                          <Input name="email" {...register("email")} maxLength={35} onChange={(e) => setEmail(e.target.value)} className={login.inputForm} placeholder="Usuário" value={email}/>
                          <p className={login.messagemErro}>{errors.email?.message}</p>
                      </div>
                      <div>
                          {/* <Label texto="Senha" estilo={login.labels}/> */}
                          <Input name="password" {...register("password")} maxLength={25} onChange={(e) => setPassword(e.target.value)} className={login.inputForm} placeholder="Senha" type={
                            show === false ? 'password' : 'text'
                          } value={password}/>
                          <p className={login.messagemErro}>{errors.password?.message}</p>
                      </div>
                      <div className={login.blocoMostraSenha}>
                        <Input type="checkbox" value={show} onClick={exibirSenha}/>
                        <p>Exibir senha</p>
                      </div>
                  </div>
                  <div>
                    <Button type="submit" className={login.button}>Acessar</Button>
                </div>
                </form>
              
            </div>

            
            {loading === false ?
            
            <div className={login.loader}>
                        <div className={login.loaderInterno}>
                          <Loading />
                        </div>
            </div>
            :
            <div></div>
             }

{<Modal className={login.modalEstilo} show={modal} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Usuário ou senha incorretos!</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button color="danger" onClick={handleClose}>
                    FECHAR
                  </Button>
                </Modal.Footer>
              </Modal>
              } 
       </section>
    );
};

export default Login;