import React, { useEffect, useRef } from "react";
import { gsap, Linear } from "gsap";
import styles from "../../Components/Error/Error.module.css";


const Erro = () => {

    const cog1Ref = useRef(null);
    const cog2Ref = useRef(null);
    const wrongParaRef = useRef(null);

  useEffect(() => {
    const t1 = gsap.timeline();
    const t2 = gsap.timeline();
    const t3 = gsap.timeline();

    t1.to(cog1Ref.current, {
      transformOrigin: "50% 50%",
      rotation: "+=360",
      repeat: -1,
      ease: Linear.easeNone,
      duration: 8
    });

    t2.to(cog2Ref.current, {
      transformOrigin: "50% 50%",
      rotation: "-=360",
      repeat: -1,
      ease: Linear.easeNone,
      duration: 8
    });

    t3.fromTo(
      wrongParaRef.current,
      {
        opacity: 0
      },
      {
        opacity: 1,
        duration: 1,
        stagger: {
          repeat: -1,
          yoyo: true
        }
      }
    );
  }, []);

    return (
        <section>
            <div class={styles.container}>
            <h1 class={styles.firstFour}>4</h1>
            <div class={styles.cogWheel1}>
                <div class={styles.cog1} ref={cog1Ref}>
                    <div class={styles.top}></div>
                    <div class={styles.down}></div>
                    <div class={styles.leftTop}></div>
                    <div class={styles.leftDown}></div>
                    <div class={styles.rightTop}></div>
                    <div class={styles.rightDown}></div>
                    <div class={styles.left}></div>
                    <div class={styles.right}></div>
                </div>
            </div>
            
            <div class={styles.cogWheel2}> 
                <div class={styles.cog2} ref={cog2Ref}>
                    <div class={styles.top}></div>
                    <div class={styles.down}></div>
                    <div class={styles.leftTop}></div>
                    <div class={styles.leftDown}></div>
                    <div class={styles.rightTop}></div>
                    <div class={styles.rightDown}></div>
                    <div class={styles.left}></div>
                    <div class={styles.right}></div>
                </div>
            </div>
            <h1 class={styles.secondFour}>4</h1>
            <p class={styles.wrongPara} ref={wrongParaRef}>Oh não! Página não disponível!</p>
            </div>
        </section>
    ) 
};

export default Erro;