import React, {useEffect, useState, useContext} from "react";
import styles from "../Solicitacao/ListarSolicitacoes.module.css";
import Header from "../../Components/Header/Header";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
// import Input from "../../Components/Input/Input";
import Label from "../../Components/Label/Label"; 
import Loading from "../../Components/Loader/Loading"; 
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import DataTable from "react-data-table-component";
// eslint-disable-next-line no-unused-vars
import $ from "jquery";
import ListViagens from "../../Contexts/ArrayViagens";
import {API_BASE_URL} from '../../services/Constantes';
import estilo from "../Solicitante/SetorTabel.module.scss";
import { FormGroup, Form, Input, Row, Col, Button, ModalBody } from "reactstrap";
import ButtonComp from "../../Components/Button/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import MenuSolicitante from "../../Components/Menu/MenuSolicitante";
import Menu from "../../Components/Menu/Menu";
import MenuMotorista from "../../Components/Menu/MenuMotorista";
import CodigoDaViagem from "../Viagem/CodigoViagem";
import Error from "../../Components/Error/Error";
import EstadoMenu from "../../Components/Menu/EstadoMenu";

const ListarSolicitacoes = () => {
    let idSolicitante = localStorage.getItem("idSolicitante");
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [idAtual, setIDAtual] = useState(''); 
    const [buttonPost, setButtonPost] = useState(false);
    const [newList, setNewList] = useState([]);
    let vetorAtual = [];
    const [solicitantes, setSolicitantes] = useState([])
    // Estado do botão
    const [buttonEdit, setButtonEdit] = useState(true);
    let tipoUsuario = localStorage.getItem("tipo");

    // Modal
    const [modalShow, setModalShow] = useState(false);
    // Contexto 
    const {codigo, setCodigo} = useContext(CodigoDaViagem);
    const {ativadoMenu, setAtivadoMenu} = useContext(EstadoMenu);

    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);
    let detalhar = useNavigate();
    function handlePage(id){
      setCodigo(id);
      detalhar('/solicitante/detalha-solicitacao');
      localStorage.setItem("StatusViagem", "Viagem Avulsa");
}

    // Title da página
    useEffect(() => {
        document.title = 'Lista de Solicitações';
        setAtivadoMenu(false);
      }, []);

    
    useEffect(() => {
      setLoading(false);
      window.scrollTo(0, 0);
      const vetorAtual = [];
      fetch(`${API_BASE_URL}/api/transporte/solicitacao/solicitante/cpf/${localStorage.getItem("cpf")}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json;charset=UTF-8",
          'token': localStorage.getItem("token"),
          'perfil': localStorage.getItem("tipo"),
          'Authorization': `Bearer ${localStorage.getItem("token")}` 
        }
      }).then((response) => {
        return response.json();
      }).then((data) => {
        data.forEach(function(item) {
          if (item.status === 'CRIADA') {
            const itemString = JSON.stringify(item);
            if (!list.some(existingItem => JSON.stringify(existingItem) === itemString)) {
              vetorAtual.push(item);
            }
          }
        });
        setList(vetorAtual);
        setLoading(true);
      }).catch((error) => {
        console.log(error)
        setLoading(true);
      }); 
    },[]);
    
    


    
   

    function cancelarSolicitacao(){
      handleClose();
      deleteSolicitacao(idAtual);


    }

    function validaIdSolicitacao(id){
      handleShow();
      setIDAtual(id);
    }

    const deleteSolicitacao = async (idSolicitacao) => {
      setLoading(false);
      window.scrollTo(0, 0);
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/transporte/solicitacao/cancelar/${idSolicitacao}`,
          {
            method: "DELETE",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              'token': localStorage.getItem("token"),
              'perfil': localStorage.getItem("tipo")
            },
          }
        ); 
        if(response.ok) {
          setLoading(true);
          const json = await response.json();
          console.log(json);
          notify("Solicitação Cancelada com Sucesso!");
          setButtonPost(!buttonPost);
        } else {
          const json = await response.json();
          setLoading(true);
          notify("Não é possível cancelar está solicitação, já está em andamento!")
        }
       
      } catch (error) {
        console.log(error);
     
      };
    
  }

    // Funcionalidade de Páginação
    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 5;
    const totalItems = list.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
  
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
  
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  const notify = (msg) => toast(msg);
    return (
        <section className={styles.container}>
            <Header titulo="Solicitações" altura="140px"/>
            {
              tipoUsuario === 'gestor' ?
              <Menu /> :
              tipoUsuario === 'motorista' ?
              <MenuMotorista /> :
              <MenuSolicitante />
            }
            <ToastContainer />
            <main className={
              ativadoMenu === true ? styles.mainContent2 : styles.mainContent
            }>
              
                    <FormGroup className={styles.formBlock}>
                           { list.length > 0 &&
                             <div>
                              <h2>Solicitações Cadastradas</h2>
                            </div>    
                           }  

                            <div className={styles.blocoSetor}>
                              {
                                <div>
                                  <ul className={estilo.responsiveTable}>
                                    <li className={estilo.tableHeader}>
                                      <div className={estilo.col} id={estilo.col1}>
                                        ID
                                      </div>
                                      <div className={estilo.col} id={estilo.col2}>
                                        Solicitação
                                      </div>
                                      <div className={estilo.col} id={estilo.col2}>
                                        Status
                                      </div>
                                      <div className={estilo.col} id={estilo.col3}>
                                        Opções
                                      </div>
                                    </li>
                                    {list.length > 0 ?
                                    list.slice(start, end).map((item, index) => (
                                      <li className={estilo.tableRow} key={index}>
                                        <div className={estilo.col} id={estilo.col1} data-label="ID">
                                          {item.id}
                                        </div>
                                        <div className={estilo.col} id={estilo.col2} data-label="Solicitacão">
                                          {item.finalidade}
                                        </div>
                                        <div className={estilo.col} id={estilo.col2} data-label="Status">
                                          {item.status === 'CRIADA' ? (
                                            <div className={styles.buttonsContainer}>
                                              <p className={styles.tableTextB}>Aguardando</p>
                                            </div>
                                          ) : item.status === 'CANCELADA' ? (
                                            <div className={styles.buttonsContainer}>
                                              <p className={styles.tableTextC}>Cancelada</p>
                                            </div>
                                          ) : (
                                            <div className={styles.buttonsContainer}>
                                              <p className={styles.tableTextC}>Finalizada</p>
                                            </div>
                                          )}
                                        </div>
                                        <div className={estilo.col} id={estilo.col3} data-label="Opções">
                                          {buttonEdit === true ? (
                                            <div className={styles.blocoButton}>
                              

                                              <div className={styles.buttonContainer}>
                                              <ButtonComp
                                                acao={() => handlePage(item.id)}
                                                tipo="button"
                                                estilo={styles.buttonEdit2} 
                                                texto="Detalhar"
                                              />
                                              </div>
                                            </div>
                                          ) : (
                                            console.log('')
                                          )}
                                        </div>
                                      </li>
                                    ))
                                  
                                  : <li  className={estilo.tableRow} >
                                  <p className={estilo.col}>Não há solicitações cadastradas</p>
                                  </li>
                                  }
                                  </ul>

                                  <div className={styles.pagination}>
                                    <ul className={styles.paginationList}>
                                      {Array.from({ length: totalPages }, (_, i) => (
                                        <li
                                          key={i}
                                          className={`${styles.paginationItem} ${
                                            i + 1 === currentPage ? styles.active : ''
                                          }`}
                                          onClick={() => handlePageChange(i + 1)}
                                        >
                                          {
                                            list.length > 5 ? i + 1 : ''
                                          }
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              }
                            </div>
                    </FormGroup> 
       
            </main>

            {loading === false ?
                
                <div className={styles.loader}>
                            <div className={styles.loaderInterno}>
                              <Loading />
                            </div>
                </div>
                :
                <div></div>
                }

  {<Modal className={styles.modalEstilo} show={modalShow} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Deseja realmente cancelar a solicitacao?</Modal.Title>
                  </Modal.Header>
                  <Modal.Footer>
                    <Button color="success" onClick={cancelarSolicitacao}>
                      SIM
                    </Button>
                    <Button color="danger" onClick={handleClose}>
                      FECHAR
                    </Button>
                  </Modal.Footer>
                </Modal>
                }
        </section>
    )
};

export default ListarSolicitacoes;