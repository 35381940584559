/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import { FormGroup, Form, Input, Row, Col, Button } from "reactstrap";
import Label from "../../Components/Label/Label";
import styles from "../Motorista/Motorista.module.css";
import ButtonComp from "../../Components/Button/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Formik } from "formik";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {API_BASE_URL} from '../../services/Constantes';
import Loading from "../../Components/Loader/Loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Table from "react-bootstrap/Table";
import { IMaskInput } from "react-imask";
import { json } from "react-router-dom";
import EstadoMenu from "../../Components/Menu/EstadoMenu";

const Motorista  = () =>{   
  window.scrollTo(0, 0);
    useEffect(() => {
      document.title = 'Cadastro de Motorista';
      setAtivadoMenu(false)
    }, []);
 
    const [showCadastro, setShowCadastro] = useState(false);
    const [showMotorista, setShowMotorista] = useState(false);
    const [showPositivo, setShowPositivo] = useState(false);
    const [motorista, setMotorista] = useState([]);
    const handleShow = () => setShowCadastro(true);
    const handleClose = () => setShowCadastro(false);
    const handleShowMotorista = () => setShowMotorista(true);
    const handleCloseMotorista = () => setShowMotorista(false);
    const handleShowPositivo = () => setShowPositivo(true);
    const handleClosePositivo = () => setShowPositivo(false);
    const [loading, setLoading] = useState(true);

    // Estados para os campos
    const [cpf, setCpf] = useState('');
    const [nome, setNome] = useState('');
    const [cnh, setCnh] = useState('');
    const [validade, setValidade] = useState('');
    const [email, setEmail] = useState('');
    const [categorias, setCategorias] = useState('');
    const [telefone, setTelefone] = useState('');
    const [nomeEmergencia, setNomeEmergencia] = useState('');
    const [telefoneEmergencia, setTelefoneEmergencia] = useState('');
    const [parentesco, setParentesco] = useState('');
    const [count, setCount] = useState(1);
    const [list, setList] = useState([]);
    const [estadoInput, setEstadoInput] = useState(false);
    const [editButton, setEditButton] = useState(true);
    const [cancelButton, setCancelButton] = useState(false);
    const [saveButton, setSaveButton] = useState(false); 
    const [closeButton, setCloseButton] = useState(true);

     // Contexto do Estado do Menu
    const { ativadoMenu, setAtivadoMenu } = useContext(EstadoMenu);

    useEffect(() => {
        // Defautl Data
        let dataAtual = new Date();
        dataAtual.setDate(dataAtual.getDate());
        let dateFormatado = dataAtual.toLocaleDateString('en-CA');
        setValidade(dateFormatado);
      }, [])

    

    const buscaCPF = async () => {
      setLoading(false);
      window.scrollTo(0, 0);
      console.log(cpf)
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/transporte/usuario/cpf/${cpf.match(/\d/g).join("")}`,
          {
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              'token': localStorage.getItem("token"),
              'perfil': localStorage.getItem("tipo")
            },
          }
        ); 
        if(response.ok) {
          const json = await response.json();
          console.log(json);
          setNome(json.str_nome);
          setEmail(json.str_email);
          setTelefone(json.str_telefone);
          notify("Usuário encontrado!");
          setLoading(true); 
        } else {
          const json = await response.json();
          console.log(json);
          notify(json.msg)
          setLoading(true);
        }
       
      } catch (error) {
        notify('Não autorizado');
        console.log(error)
        setLoading(true);
        // console.clear();
     
      };
    
  }
  function testFunction(){
    console.log(cpf)
  }


     // Requisição para o metódo POST
     const postMotorista  = async (data) => {
      setLoading(false);
      window.scrollTo(0, 0);
      console.log()
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/transporte/motorista`,
          {
            method: "POST",
            body: JSON.stringify({
              "cpf": (data.cpf).match(/\d/g).join(""),
              "nome": data.nome,
              "telefone": (data.telefone).match(/\d/g).join(""),
              "email": data.email,
              "cnh": data.cnh,
              "categoria_cnh": categorias,
              "validade_cnh": data.validade,
              "is_ativo": true,
              "contato_emergencia": list
          }), 
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              'token': localStorage.getItem("token"),
              'perfil': localStorage.getItem("tipo")
            },
          }
        ); 
        const json = await response.json();
        console.log(json.msg);
        setLoading(true)
        if(json.msg !== undefined && json.msg.includes("SQLSTATE[23505]")){
          json.msg.includes('"unique_cpf"') ? notify("CPF já cadastrado!") : 
          json.msg.includes('"unique_email"') ? notify("O email informado já está cadastrado!") : 
          json.msg.includes('"unique_cnh"') ? notify("A CNH informada já está cadastrada!") : console.log("Teste")
        } 
  
       else {
        notify("Motorista cadastrado com sucesso!");
        setNome('');
        setCpf('');
        setEmail('');
        setCnh('');
        setTelefone(''); 
        setList('');
       }
      } 
      catch(error){ 
        console.log('erro', error ); 
        notify("Não foi possível realizar o cadastro do motorista");
      }
  }


  
    // Função para validar os campos
    let validaCampos = yup.object().shape({
      cpf: yup.string().default(cpf).required("O CPF é obrigatório!"),
      nome: yup.string().default(nome).required("O Nome é obrigatório!"),
      cnh: yup.string().default(cnh).required("A CNH é obrigatória!"),
      email: yup.string().email('Informe um e-mail válido').default(email).required("O e-mail é obrigatório!"),
      validade: yup.string().default(validade).required("Informe a validade da CNH!"),
      telefone: yup.string().default(telefone).required("O telefone é obrigatório!"),
      categorias: yup.string().default(categorias).required("Informe uma categoria!"),
  });
  
  // Register
  const { register, handleSubmit, formState: { errors }, reset} = useForm({
      resolver: yupResolver(validaCampos),
  })

  // OnSubmit
  const onSubmitHandler = (data) => {

    postMotorista(data);
 }

 const [vetorContatos, setVetorContatos] = useState([]);



 const notify = (msg) => toast(msg);

 function handleSaveList() {
  let newList = [...list];
    if(nomeEmergencia !== '' && telefoneEmergencia !== '' && parentesco !== ''){
      newList.push({nome: nomeEmergencia,parentesco: parentesco, telefone: telefoneEmergencia});
      setList(newList);
      setNomeEmergencia("");
      setTelefoneEmergencia("");
      setParentesco("");
    } else{
      notify("Informe todos os dados do contato")
    }
   
  

} 
 function validaContato(){
  nomeEmergencia === '' && telefoneEmergencia === '' && parentesco === '' ? notify("Informe os dados do contato") : handleSaveList()
 }
 useEffect(() => {
    console.log(count)
 }, [count]);


 useEffect(() => {
  // Defautl Data
  let dataAtual = new Date();
  let dataAtualFormatada = dataAtual.toLocaleDateString('pt');
  let dataAtualFinal = dataAtualFormatada.split('/').reverse().join('-');
  setValidade(dataAtualFinal);
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

const EmailMask = /^\S*@?\S*$/;
    return (
        <section className={styles.cadastro}>
           <Header titulo="Cadastro de Motorista" altura="240px"/>
            <Menu />
            <ToastContainer />
           <main className={
            ativadoMenu === true ? styles.mainContent2 : styles.mainContent
           }> 
   
 
                <form onSubmit={handleSubmit(onSubmitHandler)} className={styles.form}>
                    <FormGroup className={styles.formBlock}>
                    
                    <div>
                    <h2>Dados Pessoais</h2>
                        <div className={styles.formComponents2}>
                            <div className={styles.formItems}>
                                <div className={styles.formGroup}>
                                    <span>CPF</span>
                                    <IMaskInput
                                      className={styles.formField}
                                      mask="000.000.000-00"
                                      placeholder="Digite o seu CPF"
                                      name="cpf"
                                      value={cpf}
                                      {...register("cpf", { required: true })} 
                                      onChange={(e) => setCpf(e.target.value)}
                                      id="cpf"
                                      type="text"
                                    />
                                </div>
                                <p className={styles.messagemErro}>{errors.cpf?.message}</p>
                            </div>

                           <div className={styles.formItems}>
                             <ButtonComp tipo="button" acao={buscaCPF} texto="Buscar" estilo={styles.formButton}/>
                           </div>
                        </div>

                        <div className={styles.formComponents}>
                            <div className={styles.formItems}>
                                <div className={styles.formGroup}>
                                        <span>Nome</span>
                                        <IMaskInput className={styles.formField} name="Nome" {...register("Nome")}  onChange={(e) => setNome(e.target.value)} value={nome} type="text"  placeholder="Nome Completo do Motorista"/>
                                </div>
                                <p className={styles.messagemErro}>{errors.nome?.message}</p>
                           </div>

                            <div className={styles.formItems}>
                                <div className={styles.formGroup}>
                                        <span>CNH</span>
                                        <IMaskInput className={styles.formField} mask="00000000000" name="CNH" {...register("CNH")}  onChange={(e) => setCnh(e.target.value)} value={cnh} type="text" placeholder="00000000000"/>
                                </div>
                                <p className={styles.messagemErro}>{errors.cnh?.message}</p>
                            </div>
                        </div>

                        <div className={styles.formComponents}>
                            <div className={styles.formItems}>
                                <div className={styles.formGroup}>
                                        <span>Categorias</span>
                                        <Input name="Categorias" {...register("Categorias")}  onChange={(e) => setCategorias(e.target.value)} value={categorias} type="select" placeholder="Selecione uma categoria" className={styles.formField}>
                                            <option value='A'>A</option>
                                            <option value='B'>B</option>
                                            <option value='C'>C</option>
                                            <option value='D'>D</option>
                                        </Input>
                                </div>
                                <p className={styles.messagemErro}>{errors.categorias?.message}</p>
                            </div> 

                            <div className={styles.formItems}>
                                <div className={styles.formGroup}>
                                        <span>Email</span>
                                        <IMaskInput onAccept={(value, mask) => console.log(value, mask)} className={styles.formField} mask={EmailMask} name="Email" {...register("Email")}  onChange={(e) => setEmail(e.target.value)} value={email} type="text"  placeholder="exemplo@stc.ma.gov.br"/>
                                </div>
                                <p className={styles.messagemErro}>{errors.email?.message}</p>
                            </div>
                       </div>
                     <div className={styles.formComponents}>
                        <div className={styles.formItems}>
                              <div className={styles.formGroup}>
                                          <span>Validade da CNH</span>
                                          <IMaskInput  className={styles.formField}   name="Validade" {...register("validade")}  onChange={(e) => setValidade(e.target.value)} value={validade} type="date"/>
                              </div>
                            <p className={styles.messagemErro}>{errors.validade?.message}</p>
                        </div>

                        <div className={styles.formItems}>
                            <div className={styles.formGroup}>
                                        <span>Telefone</span>
                                        <IMaskInput  className={styles.formField} mask="(00) 00000-0000"  name="Telefone" {...register("Telefone")} onChange={(e) => setTelefone(e.target.value)} value={telefone}  type="tel"  required="" data-js="phone" placeholder="(XX) X XXXX XXXX"/>
                            </div>
                            <p className={styles.messagemErro}>{errors.telefone?.message}</p>
                        </div>
                       </div>
                       </div>
    
                    </FormGroup> 
          
                    <FormGroup className={styles.formBlock}>
                        <div className={styles.components}>
                            <h2>Contato de Emergência</h2>  
                          
                            <div className={styles.componentsEmergencia}>
                 
                                <div className={styles.containerEmergencia2}>
        
                                     
                                          <div  className={styles.blocoEmergencia2}>
                                              <div className={styles.blocoEmergencia}>
                                                  <div className={styles.formItems}>
                                                      <div className={styles.formGroup}>
                                                                  <span>Nome</span>
                                                                  <IMaskInput  className={styles.formField}  name="nomeEmergencia" value={nomeEmergencia} onChange={(e) => setNomeEmergencia(e.target.value)} placeholder="Nome Completo" type="text"/>
                                                      </div>
                                                  </div>

                                                  <div className={styles.formItems}>
                                                      <div className={styles.formGroup}>
                                                                      <span>Telefone</span>
                                                                      <IMaskInput mask="(00) 00000-0000"  className={styles.formField}  name="telefoneEmergencia" value={telefoneEmergencia} onChange={(e) => setTelefoneEmergencia(e.target.value)} placeholder="(XX) X XXXX-XXXX" type="text"/>
                                                      </div>
                                                  </div>

                                                  <div className={styles.formItems}>
                                                      <div className={styles.formGroup}>
                                                                          <span>Parentesco</span>
                                                                          <IMaskInput  className={styles.formField}  name="parentesco" value={parentesco} onChange={(e) => setParentesco(e.target.value)}  placeholder="Pai, mãe ou irmão"  type="text"/>
                                                      </div>
                                                  </div>
                                              </div>
                                                
                                              <div className={styles.contentButton}>
                                                    <ButtonComp acao={() => validaContato()} texto="+" estilo={styles.formButton2} tipo="button"/>
                                              </div>
                                          </div>
                                     
                                </div>
                            {(list.length > 0) &&
                                <section className={styles.mainTable}>
                                      <h3 className={styles.blocoTitleTable}>Lista de Contatos de Emergência</h3>
                                   
                                      <Table className={styles.table} size="sm">
                                      <thead>
                                        <tr className={styles.tableHeader}>
                                          <th>
                                            <div className={styles.tableName}>Nome</div>
                                          </th>
                                          <th>
                                          <div className={styles.tableName}>Telefone</div></th>
                                          <th>
                                          <div className={styles.tableName}>Parentesco</div>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody display="flex" align="center">
                                      {list.map((item, index) => (
                                  
                                          <tr key={index+1}>                
                                          <td width="30%">
                                            <div className={styles.valueTable}>{
                                              
                                              item.nome
                                            }</div>
                                          </td>

                                          <td width="15%">
                                            <div className={styles.valueTable}>{
                                              item.telefone
                                            }</div>
                                          </td>
                                          <td width="15%">
                                            <div className={styles.valueTable}>{
                                              item.parentesco
                                            }</div>
                                          </td>

                                          {/* { <td width="25%">
                                            <div className={styles.itemsTD}>
                                              
                                              
                                              { 
                                                editButton === true?
                                                item.id === idTeste ?
                                                <ButtonComp  
                                           
                                                estilo={styles.itemButtonEdit}
                                                texto="Editar"
                                              /> 
                                              :
                                              <ButtonComp  
                                              
                                                estilo={styles.itemButtonEdit}
                                                texto="Editar"
                                              /> 
                                              
                                              :
                                              item.id === idTeste?
                                          
                                            <ButtonComp 
                              
                                            estilo={styles.itemButtonEdit}
                                            texto="Editar"
                                          />
                                                
                                            :
                                  
                                              }

                                              {(saveButton === true && item.id === idInformado) &&
                                                  <ButtonComp                                                  
                                                  estilo={styles.itemButtonSave}
                                                  texto="Salvar"
                                                />
                                              }

                                              {
                                              editButton === true ?
                                                <ButtonComp
                                                estilo={styles.itemButtonExc}
                                                texto="Excluir"
                                              /> 
                                            }

                                                {
                                                  cancelButton === true && item.id === idInformado ?
                                                  <ButtonComp
                                                  estilo={styles.itemButtonExc}
                                                  texto="Cancelar"
                                                /> 
                                                }
                                            </div>
                                          </td>} */}

                                        </tr>

                                      ))} 
                                      </tbody>
                                   </Table>
                        
                                  
                                </section>
}
                     
                            </div>
                          
                           
                            <div className={styles.formButtonCadastro}>
                                    <ButtonComp tipo="submit" texto="Cadastrar" estilo={styles.formButton3}/>
                             </div>
                        </div>

                               
                    </FormGroup>
                </form>
           </main>
           {<Modal className={styles.modalEstilo} show={showCadastro} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Informe os dados do Motorista!</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button color="danger" onClick={handleClose}>
                    FECHAR
                  </Button>
                </Modal.Footer>
              </Modal>
              }

    {<Modal className={styles.modalEstilo} show={showMotorista} onHide={handleCloseMotorista}>
                <Modal.Header closeButton>
                  <Modal.Title>Motorista já Cadastrado!</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button color="danger" onClick={handleCloseMotorista}>
                    FECHAR
                  </Button>
                </Modal.Footer>
              </Modal>
              } 

{<Modal className={styles.modalEstilo} show={showPositivo} onHide={handleClosePositivo}>
                <Modal.Header closeButton>
                  <Modal.Title>Motorista Cadastrado com Sucesso!</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button color="danger" onClick={handleClosePositivo}>
                    FECHAR
                  </Button>
                </Modal.Footer>
              </Modal>
              } 

  {loading === false ?
                  
                  <div className={styles.loader}>
                              <div className={styles.loaderInterno}>
                                <Loading />
                              </div>
                  </div>
                  :
                  <div></div>
                  }
        </section>



    );
};

export default Motorista;