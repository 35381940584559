import React, { useState, useEffect, useContext } from "react";
import styles from "../../pages/Solicitante/ListarSolicitantes.module.css"
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import MenuSolicitante from "../../Components/Menu/MenuSolicitante";
import MenuMotorista from "../../Components/Menu/MenuMotorista";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { FormGroup, Form, Input, Row, Col, Button } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import Table from "react-bootstrap/Table";
import { IMaskInput } from "react-imask";
import ButtonComp from "../../Components/Button/Button";
import {API_BASE_URL} from '../../services/Constantes';
import Loading from "../../Components/Loader/Loading";
import estilo from "../Solicitante/SetorTabel.module.scss";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Error from "../../Components/Error/Error";
import EstadoMenu from "../../Components/Menu/EstadoMenu";

const ListarPassageiros = () => {
    useEffect(() => {
        document.title = 'Passageiros';
        setAtivadoMenu(false);
      }, []);

      // Estado que vai guardar todos os solicitantes cadastrados
      const [list, setList] = useState([]);
      const [idAtual, setIdAtual] = useState('');
      const [loading, setLoading] = useState(true);
      const [update, setUpdate] = useState(false);
      let tipoUsuario = localStorage.getItem("tipo");
      // Modal
      const [show, setShow] = useState(false);
      const handleShow = () => setShow(true);
      const handleClose = () => setShow(false);
 
      // Contexto para o estado do Menu
      const { ativadoMenu, setAtivadoMenu } = useContext(EstadoMenu);

      let navigate = useNavigate();
      function handlePage(){
        setLoading(true);
        navigate('/');
         
      }

      // useEffect que pega os setores cadastrados
useEffect(() => {
  setLoading(false);
  window.scrollTo(0, 0);

  const token = localStorage.getItem("token");

  if (token) {
    fetch(`${API_BASE_URL}/api/transporte/passageiro`, {
      method: "GET",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        'token': token,
        'perfil': localStorage.getItem("tipo"),
        'Authorization': `Bearer ${token}` 
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Não foi possível realizar a requisição!");
        }
        return response.json();
      })
      .then((data) => {
        setList(data);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });
  } else {
    handlePage();
  }
}, [update]);






      const notify = (msg) => toast(msg);

      function pegaID(id) {
        nextPage(id);
      }

 
     // History 
     let editar = useNavigate();

    // eslint-disable-next-line no-unused-vars
    const [estadoID, setEstadoID] = useState('');


     function nextPage(id) {
        localStorage.setItem("idPassageiro", id)
        editar('/gestor/editar-passageiro');
     }

     function removeItem(id){
        setIdAtual(id);
        handleShow();
     }
     

     const postDelPassageiro = async () => {
      setLoading(false);
      handleClose();
      window.scrollTo(0, 0);
      try {
        const response2 = await fetch(
          `${API_BASE_URL}/api/transporte/passageiro/${idAtual}`,
          {
            method: "DELETE",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              'token': localStorage.getItem("token"),
              'perfil': localStorage.getItem("tipo"),
            },
          }
        );
    
        if (response2.ok) {
          // Verifica se a resposta HTTP está OK
          const json2 = await response2.json();
          console.log(json2);
          setLoading(true);
          notify("Passageiro removido com sucesso!");
          setUpdate(!update);
        } else {
          const json2 = await response2.json();
          // Caso contrário, trata o erro de resposta
          throw new Error(json2.msg);
        }
      } catch (error) {
        console.log(error);
        notify(error);
        setLoading(true);
        notify("Não foi possível remover o passageiro");
      }
    };
    

   return (
    <section className={styles.container}>
            <Header titulo="Passageiros" altura="140px"/>
            {
              tipoUsuario === 'gestor' ?
              <Menu /> :
              tipoUsuario === 'motorista' ?
              <MenuMotorista /> :
              <MenuSolicitante />
            }
            <ToastContainer />

            <main className={
              ativadoMenu === true ? styles.mainContent2 : styles.mainContent
            }>
                <form className={styles.form}>
                    <FormGroup className={styles.formBlock}>
                            {
                              list.length > 0 &&
                              <div>
                                <h2>Passageiros cadastrados</h2>
                              </div>
                            }       

                            <div className={styles.blocoSetor}>
                  
                               
                              {
                            
                              
                                 <ul className={estilo.responsiveTable}>
                                        <li className={estilo.tableHeader}>
                                          <div className={`${estilo.col} ${styles.status}`} id={estilo.col1}>ID</div>
                                          <div className={estilo.col} id={estilo.col2}>Passageiro</div>
                                          <div className={estilo.col} id={estilo.col3}>Opções</div>
                                        </li>
                                        {
                                           list.length > 0 ? list.map((item, index) => (
                                            <li  className={estilo.tableRow} key={index}>
                                                <div className={`${estilo.col} ${styles.status}`} id={estilo.col1} data-label="ID">{index+1}</div>
                                                <div className={estilo.col} id={estilo.col2} data-label="Setor">{
                                                    item.nome
                                                }</div>
                                                <div className={`${estilo.col} ${styles.blocoButtons}`} id={estilo.col3} data-label="Opções">
                                                    <ButtonComp acao={() => pegaID(item.id)} tipo="button" estilo={styles.buttonEdit} texto="Editar"/>
                                                    <ButtonComp acao={() => removeItem(item.id)} tipo="button" estilo={styles.buttonRemover} texto="Remover"/>
                                                </div>
                                            </li>
                                            )) 
                                            :
                                            <li  className={estilo.tableRow} >
                                      <p className={estilo.col}>Não há passageiros cadastrados</p>
                                      </li>
                                              }
                                        
                                       
                              </ul> 
                              }
                                
                          </div> 
                    </FormGroup> 
                </form>
            </main>

            {loading === false ?
                
                <div className={styles.loader}>
                            <div className={styles.loaderInterno}>
                              <Loading />
                            </div>
                </div>
                :
                <div></div>
                }

                
{<Modal className={styles.modalEstilo} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Deseja realmente o passageiro?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                <Button color="success" onClick={postDelPassageiro}>
                    SIM
                </Button>
                  <Button color="danger" onClick={handleClose}>
                    NÃO
                  </Button>
                </Modal.Footer>
              </Modal>
              }
    </section>
   )
};

export default ListarPassageiros;