// export const API_BASE_URL = "http://homologacao.stc.ma.gov.br:8080/transporte";
// export const API_BASE_URL = "http://127.0.0.1:8000"; 
// export const API_BASE_URL = "http://10.32.128:8000/transporte";
export const API_BASE_URL = "https://appv2.stc.ma.gov.br:8443/api-frotas";





// http://homologacao.stc.ma.gov.br:8080/transporte - Homologação
// http://127.0.0.1:8000 - Local
// http://172.20.0.3:8080/transporte - Produçao

