import React, { useEffect, useState, useRef, useContext } from "react";
import icon from '../../assets/user.png';
import styles from '../Header/Header.module.css';
import Label from "../Label/Label";
import Modal from "react-bootstrap/Modal";
import { Button } from "reactstrap";
import Loading from "../Loader/Loading";
import { useNavigate } from "react-router-dom";
import iconClose from "../../assets/sair.png"
import iconMenu from "../../assets/dots.png";
import { IMaskInput } from "react-imask";
import { API_BASE_URL } from "../../services/Constantes";
import { API_BASE_URL_LOGIN } from "../../services/ConstanteLogin";
import EstadoMenu from "../Menu/EstadoMenu";

const Header = ({titulo, altura}) =>{
  const [showElement, setShowElement] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [mouseOver, setMouseOver] = useState(false);
  const [cpf, setCpf] = useState('');
  const headerRef = useRef(null);
  let token;
  let timerId;

  // Contexto do Estado do Menu
  const { ativadoMenu } = useContext(EstadoMenu);

  useEffect(() => {
    token = localStorage.getItem("token");
    
  }, []);

  useEffect(() => {
    const cpfArmazenado = localStorage.getItem('cpf');
    if (cpfArmazenado) {
      setCpf(cpfArmazenado);
    }
  }, []);

  let navigate = useNavigate();

  function handlePage() {
    setLoading(true);
    navigate('/');
  }

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  function handleClick() {
    if (showElement) {
      setShowElement(false); 
    } else {
      setShowElement(true);
      timerId = setTimeout(() => {
        setShowElement(false);
      }, 15000);
    }
  }

  function handleSair() {
    setShowElement(false);
    handleShow();
  }

  const postLogout = async () => {
    setLoading(true); // Indicar que o logout está em andamento
    try {
      const response = await fetch(
        `${API_BASE_URL_LOGIN}/logout`,
        {
          method: "POST",
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          },
        }
      );
      
      if (!response.ok) {
        throw new Error('Logout falhou. Por favor, tente novamente.'); // Tratar erro de resposta do servidor
      }
      localStorage.clear();
      handlePage();
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
  
      handleClose();
    }
  };
  

  function handleMouseEnter() {
    clearTimeout(timerId);
  }

  function handleMouseLeave() {
    timerId = setTimeout(() => {
      setShowElement(false);
    }, 15000);
  }

  return ( 
    <header className={
      ativadoMenu === true ? styles.header2 : styles.header
    } style={{height: altura}} onClick={(e) => {
          if (headerRef.current && !headerRef.current.contains(e.target)) {
              setShowElement(false);
          }
      }} ref={headerRef}>

      <div className={styles.blocoHeader}>
          <div className={styles.tituloPagina}>
              <h1 className={styles.tituloEstilo}>{titulo}</h1>  
            </div>

          <div className={styles.headerContent}>
          <span className={styles.title}>{localStorage.getItem("nome")}</span>
          <i id={styles.iconUser} className="fa-solid fa-user"></i>
            <div className={styles.userAction} onClick={handleClick}
              onMouseOver={() => setMouseOver(true)}
              onMouseLeave={() => setMouseOver(false)}>
                <img width="30" height="30" src={iconMenu} alt="Icon de logout"/>
            </div>
        {
          showElement === true ?
            <>
            {/* <div className={styles.arrow}></div>  */}
          <div className={styles.blocoInfo}>
            <span className={styles.span}>Dados do usuário</span>
            <div className={styles.borda}></div>
            <div className={styles.blocoDados}>
              <div className={styles.blocoItem}>
                <Label estilo={styles.blocoItemText} texto="Nome:"/>
                <p>{localStorage.getItem("nome")}</p>
              </div>

            

              <div className={styles.blocoItem}>
              <Label estilo={styles.blocoItemText} texto="Telefone:"/>
              <IMaskInput className={styles.mascara} mask="(00) 00000-0000" value={localStorage.getItem("telefone")} disabled={true} />
            </div>

              <div className={styles.blocoItem}>
              <Label estilo={styles.blocoItemText} texto="CPF:"/>
              <IMaskInput className={styles.mascara} mask="999.999.999-99" value={localStorage.getItem("cpf")} disabled={true} />
            </div>
              
            {/* <p>{localStorage.getItem("cpf")}</p> */}
            </div>

            <div className={styles.blocoButtons}>
              {/* <button className={styles.buttonClose}>Fechar</button> */}
              <button onClick={handleSair}  className={styles.buttonClose}>Logout</button>
            </div>
          </div>
            
            </>
          : null
        }

      
          </div>
      </div> 


      

      {<Modal className={styles.modalEstilo} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Deseja realmente sair?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button color="danger" onClick={handleClose}>
                    FECHAR
                  </Button>
                  <Button color="success" onClick={postLogout}>
                    SIM
                  </Button>
                </Modal.Footer>
              </Modal>
              }

{loading === false ?
                  
                  <div className={styles.loader}>
                              <div className={styles.loaderInterno}>
                                <Loading />
                              </div>
                  </div>
                  :
                <di></di>
                  }
    </header> 



  );
}

export default Header;