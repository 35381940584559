/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext} from "react";
import { FormGroup, Form, Input, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import Title from "../../Components/Title";
import * as C from "@chakra-ui/react";
import { Switch } from '@chakra-ui/react'
import styles from "../Viagem/ViagemAvulsa.module.css"; 
import iconCalender from "../../assets/calender.png";
import Table from "react-bootstrap/Table";
import Label from "../../Components/Label/Label";
// import Input from "../../Components/Input/Input";
import ButtonComp from "../../Components/Button/Button"; 
import axios from "axios";
import { useDisclosure } from "@chakra-ui/react";
import ModalComp from "../../Components/Modal/ModalComp";
import Modal from "react-bootstrap/Modal";
import { Button } from "reactstrap";
import ListMotorista from "../../Contexts/ArrayMotoristas";
import {API_BASE_URL} from '../../services/Constantes';
import Loading from "../../Components/Loader/Loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IMaskInput } from "react-imask";
import EstadoMenu from "../../Components/Menu/EstadoMenu";

const CriarViagem = () => {

  useEffect(() => {
    document.title = 'Viagem Avulsa';
    setAtivadoMenu(false);
  }, []);

  // Histoy
    let navigate = useNavigate();
    function handlePage(){
      navigate('/gestor/listarViagem'); 
    } 
  

  // Estados para o Modal
  const { isOpen, onOpen, onClose} = useDisclosure();
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState({});

  // Estado para o segundo Modal
  const [show, setShow] = useState(false);
  const [showHour, setShowHour] = useState(false);
  const [showData, setShowData] = useState(false);
  const [showParadas, setShowParadas] =useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseHour = () => setShowHour(false);
  const handleShowHour = () => setShowHour(true);
  const handleCloseData = () => setShowData(false);
  const handleShowData = () => setShowData(true);
  const handleCloseParadas = () => setShowParadas(false);
  const handleShowParadas = () => setShowParadas(true);

  // Estados para os veiculos e motoristas
  const [veiculo, setVeiculo] = useState([]);
  const [motorista, setMotorista] = useState([]);

  // Estados para os campos
  const [drive, setDrive] = useState('');
  const [car, setCar] = useState('');
  const [details, setDetails] = useState('');
  const [day, setDay] = useState('');
  const [hour, setHour] = useState('');
  const [paradas, setParadas] = useState('');
  const [origem, setOrigem] = useState('');
  const [dataParada, setDataParada] = useState('');
  const [horaParada, setHoraParada] = useState('');
  const [status, setStatus] = useState(true);
  const [editButton, setEditButton] = useState(true);
  const [closeButton, setCloseButton] = useState(true);
  const [cancelButton, setCancelButton] = useState(false);
  const [saveButton, setSaveButton] = useState(false); 
  const [paradaLocal, setParadaLocal] = useState('');
  const [localAlter, setLocalAlter] = useState('');
  const [dataAlter, setDataAlter] = useState('');
  const [horaAlter, setHoraAlter] = useState('');
  const [cont, setCont] = useState(2);
  const [idInformado, setIdInformado] = useState('');
  const [firtsData, setFirstData] = useState('');
  const [percursoID, setPercursoID] = useState({});
  const [buttonValue, setButtonValue] = useState('');
  const [loading, setLoading] = useState(true);
  let viagemID;
  let idTeste;

  // Estado par a listagem de paradas
  const [list, setList] = useState([]);

  // Contexto
  const {motoristas, setMotoristas} = useContext(ListMotorista);
  const [valorAtual, setValorAtual] = useState('');
  let idGestor;
  
  // Contexto para o Estado do Menu
  const { ativadoMenu, setAtivadoMenu } = useContext(EstadoMenu);


  function detalhaParada(id){
    setEditButton(false);
    viagemID = id;
    setSaveButton(true);
  }
 
  // Função para alterar a lista de paradas
  function handleAlterList(id){
      let newList = [...list]; 
      newList.map((item, index) => (
        item.id === id ? (item.local = localAlter, item.data = formatData(dataAlter)) : console.log("Não é igual")
      ))
    setList(newList) 
  }

  function formatData(data) {
    var date = new Date(data+'T12:00:00.00-0200');
    let dataFinal = (new Intl.DateTimeFormat('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'}).format(date))
    return dataFinal
  }
 

  function handleSaveList(dado, dataLocal, origem, hora) {
    let newList = [...list];
    var date = new Date(dataLocal+'T12:00:00.00-0200');
    let formatData = (new Intl.DateTimeFormat('pt-BR', {day: 'numeric', month: 'numeric', year: 'numeric'}).format(date))

    if(newList.indexOf(dado) === -1){
      if(dado !== '' && dataLocal !== ''){
        setCont(cont+1)
        newList.push({id: cont, local: dado, data: formatData, dataOriginal: dataLocal, localOrigem: origem, status: 'Não', hora: hora});
        setList(newList)
        setParadas('');
        setDataParada(day);
 
      } else{
        alert("Informe uma parada e uma data");
      }
     
    } else {
      alert("O local de parada já foi informado");
    }


  } 

  function handleRemoveList(dado){

    let newList = [...list];
    newList.splice(dado.index, 1)
    setList(newList);
   
  }

  function handleRemoveTotalList(dado){
    let newList = [...list];
    dado.map((item, index) => (
      newList.splice(item.index, 1)
    ));
    setList(newList);
  }



  useEffect(() => {
    setLoading(false);
    window.scrollTo(0, 0);
    fetch(`${API_BASE_URL}/api/transporte/veiculo`, {
        method: "GET",
        headers: {
            "Content-type": "application/json;charset=UTF-8",
            'token': localStorage.getItem("token"),
            'perfil': localStorage.getItem("tipo")
        }
        }).then((response) => {
            return response.json();
          })
          .then((data) => {
            setVeiculo(data);
            setLoading(true);
          })
          .catch((error) => {
            console.log(error);
            setLoading(true);
          });
    
},[])

  // // Requisição para API do Motorista
  useEffect(() => {
    fetch(`${API_BASE_URL}/api/transporte/motorista`, {
        method: "GET",
        headers: {
            "Content-type": "application/json;charset=UTF-8",
            'token': localStorage.getItem("token"),
            'perfil': localStorage.getItem("tipo")
        }
        }).then((response) => {
            return response.json();
          })
          .then((data) => {
            setMotorista(data);
          })
          .catch((error) => {
            console.log(error)
          });
    
},[])
  
  // Modificar os switchs de status das paradas para a nova versão
  // Preparação para o metódo POST
  const validaDados = () =>{
    

    if (list.length > 0 && car !== '' && drive !== '' && origem !== '') {
      const obj = list?.map((item, index) =>(
        {
          "local": item.local,
          "data_prevista": item.dataOriginal, 
          "hora_prevista": item.hora,
          "aguardar": status
        } 
      ));
       
      postViagem(obj); 
    } 
      else {
        handleShowParadas()
      }
    } 

       // Cadastro já está funcionando - Alteração feita com sucesso

    const postViagem = async (paradas) => {
      setLoading(false);
      window.scrollTo(0, 0);
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/transporte/viagemAvulsa/criar`,
          {
            method: "POST",
            body: JSON.stringify({
              "gestor_id": localStorage.getItem("IdUsuario"),
              "motorista_id": drive,
              "veiculo_id": car,
              "observacao": details,
              "data_prevista": day,
              "hora_prevista": hour,
              "ponto_origem": origem,
              "paradas": paradas
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              'token': localStorage.getItem("token"),
              'perfil': localStorage.getItem("tipo")
            },
          }
        ); 
        if(response.ok) {
          setLoading(true);
          const json = await response.json();
          console.log(json);
          setPercursoID(json);
          handleShow();
          handleRemoveTotalList(list);
        } else {
          setLoading(true);
        }
       
      } catch (error) {
        console.log(error);
     
      };
    
  }



  function validaData (data) {
 
      let dataInformada = data;
      let dataAtual = new Date();
      let dataAtualFormatada = dataAtual.toLocaleDateString('pt');
      let dataAtualFinal = dataAtualFormatada.split('/').reverse().join('-');
      dataInformada >= dataAtualFinal ? setDay(dataInformada) || setDataParada(dataInformada) || setDataAlter(dataInformada)  : notify("Informe uma data válida!")
  }

  function validaData2 (data) {

    let dataInformada = data;

    let dataAtual = new Date();
    dataAtual.setDate(dataAtual.getDate());
    let dateFormatado = dataAtual.toLocaleDateString('en-CA');
    let horaAtual = new Date().toLocaleTimeString();
    let horaAtualFormatada = horaAtual.slice(0, -3);
    dataInformada >= dateFormatado ? setDay(dataInformada) || setDataParada(dataInformada) || setDataAlter(dataInformada)  : handleShowData()
    setHour(horaAtualFormatada);
  
}



  function validaHora (hora){
    let dataAtual = new Date();
    dataAtual.setDate(dataAtual.getDate());
    let dateFormatado = dataAtual.toLocaleDateString('en-CA');
    let horaAtual = new Date().toLocaleTimeString();
    let horaAtualFormatada = horaAtual.slice(0, -3);
    let horaInformada = hora;
    (horaInformada > horaAtualFormatada || horaInformada === horaAtualFormatada) ? setHour(horaInformada) : day > dateFormatado ? setHour(horaInformada) : notify("Informe uma hora válida!")
    
  }

  function validaHora2 (hora){
    let dataAtual = new Date();
    dataAtual.setDate(dataAtual.getDate());
    let dateFormatado = dataAtual.toLocaleDateString('en-CA');
    let horaAtual = new Date().toLocaleTimeString();
    let horaAtualFormatada = horaAtual.slice(0, -3);
    let horaInformada = hora;
    (horaInformada > horaAtualFormatada || horaInformada === horaAtualFormatada) ? setHoraParada(horaInformada) : dataParada > dateFormatado ? setHoraParada(horaInformada) : notify("Informe uma hora válida!")
    
  }

  function validaHora3 (hora){
    let dataAtual = new Date();
    dataAtual.setDate(dataAtual.getDate());
    let dateFormatado = dataAtual.toLocaleDateString('en-CA');
    let horaAtual = new Date().toLocaleTimeString();
    let horaAtualFormatada = horaAtual.slice(0, -3);
    let horaInformada = hora;
    (horaInformada > horaAtualFormatada || horaInformada === horaAtualFormatada) ? setHoraAlter(horaInformada) : dataParada > dateFormatado ? setHoraAlter(horaInformada) : handleShowHour();
    
  }
  function validaParadas () {
    // paradas !== '' && origem !== '' ? 
    handleSaveList(paradas, dataParada, origem, horaParada)
    // :
    // handleShowParadas()
  }



 useEffect(() => {
   // Defautl Data
   let dataAtual = new Date();
   let dataAtualFormatada = dataAtual.toLocaleDateString('pt');
   let dataAtualFinal = dataAtualFormatada.split('/').reverse().join('-');
   let horaAtual = new Date().toLocaleTimeString();
   setHour(horaAtual.slice(0, -3))
   setDay(dataAtualFinal);
   setDataParada(dataAtualFinal);
   setFirstData(dataAtualFinal)
   setHoraParada(horaAtual.slice(0, -3))
  idGestor = localStorage.getItem("IdUsuario");
  console.log(idGestor);
  setHoraAlter(horaParada);

 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);
 


 function botaoAction() {
  setEditButton(false);
 setSaveButton(true);
 setCancelButton(true);
 }

 function pegaID(id){
  setIdInformado(id);
  idTeste = id;
  setButtonValue(id);

  let datAlter;
  let datSplit;
  let datReverse;
  let datFinal;
  list.map((item, index) => (
    item.id === idTeste &&  ((setLocalAlter(item.local)), datAlter = (item.data).replace(/\//g, '-'), (datSplit = datAlter.split("-")), (datReverse = datSplit.reverse()), (datFinal = datReverse.join("-")), (setDataAlter(datFinal)))
     
  ))

  list.map((item, index) => (
    item.id === idTeste && (setEditButton(false), setSaveButton(true), setCancelButton(true), setCloseButton(false)) 
    
  ));
} 

const notify = (msg) => toast(msg);
 
  return (
    <section className={styles.viagem}>
      <Header titulo="Viagem Avulsa" altura="240px"/>
      <Menu />

      <ToastContainer />
      {loading === false ?
            
            <div className={styles.loader}>
                        <div className={styles.loaderInterno}>
                          <Loading />
                        </div>
            </div>
            :
            <div></div>
       }

      <main className={
        ativadoMenu === true ? styles.mainContent2 : styles.mainContent
      }>
 
 
        <FormGroup className={styles.mainForm}>
        <h2>Dados da Viagem</h2>
          <div className={styles.mainFormItemA}>
            <div className={styles.inputForm}>
              <div className={styles.formGroup2}>
                        <span>Saída</span>
                        <IMaskInput className={styles.formField} type="date" onChange={(e) => validaData(e.target.value)} value={day}/>
              </div>
            </div>

            <div className={styles.inputForm}>
               <div className={styles.formGroup3}>
                          <span>Hora</span>
                          <IMaskInput className={styles.formField} type="time" onChange={(e) => validaHora(e.target.value)} value={hour}/>
                </div>
            </div> 

            <div className={styles.inputForm}>
               <div className={styles.formGroup4}>
                          <span>Origem</span>
                          <IMaskInput className={styles.formField} placeholder="Informe o local de Origem" type="text" onChange={(e) => setOrigem(e.target.value)} value={origem}/>
                </div>
            </div>
          </div>

          <div className={styles.mainFormItemB}>
            <div className={styles.inputForm}>
                    <div className={styles.formGroup}>
                                  <span>Veículo</span>
                                  <Input className={styles.formField}  type="select" onChange={(e) => setCar(e.target.value)} value={car}>
                                    <option value="" disabled>Selecione um carro</option>
                                       {Array.isArray(veiculo) && Array.from(veiculo).map((carro, key) => {
                                                      return (
                                                        <option value={carro.id}
                                                          className={styles.selectOption}
                                                          key={key}
                                                        >{`${carro.modelo} - ${carro.placa}`}</option>
                                                      );
                                                  })}
                                    </Input>
                    </div>
         </div>
 

          <div className={styles.inputForm}>
                <div className={styles.formGroup}>
                                <span>Motorista</span>
                                <Input className={styles.formField} type="select" onChange={(e) => setDrive(e.target.value)} value={drive}>
                                  <option value="" disabled>Selecione um motorista</option>
                                  {motorista && motorista.length > 0 ? (
                                    motorista.map((pessoa, index) => (
                                      <option value={pessoa.id} key={index}>{pessoa.nome}</option>
                                    ))
                                  ) : (
                                    <option value="" disabled>Nenhum motorista encontrado</option>
                                  )}
                                </Input>
                </div>
            </div>


            
          </div> 

          <div className={styles.formBlockLocal}>
            <div className={styles.formBlockLocalOne}>
              <div className={styles.inputForm}>
                  <div className={styles.formGroup}>
                              <span>Local</span>
                              <IMaskInput className={styles.formField} placeholder="Informe um local de parada" type="text" onChange={(e) => setParadas(e.target.value)} value={paradas}/>
                  </div>
              </div>

              <div className={styles.inputForm}>
                  <div className={styles.formGroup2}>
                                  <span>Data</span>
                                  <IMaskInput className={styles.formField} type="date" onChange={(e) => validaData(e.target.value)} value={dataParada}/>
                  </div>

              </div>

              <div className={styles.inputForm}>
                <div className={styles.formGroup3}>
                                    <span>Hora</span>
                                    <IMaskInput className={styles.formField} type="time" onChange={(e) => validaHora2(e.target.value)} value={horaParada}/>
                </div>
            </div>
            </div>
              
              <div className={styles.formBlockLocalTwo}>
                <div className={styles.inputFormCheck}>

                    <Switch defaultChecked  onChange={(e) => setStatus(!status)}  value={status} colorScheme='green' size='md'></Switch>
                    <p>Aguardar</p>
                </div>
              
                <ButtonComp 
                  acao={() => [
                    validaParadas()
                  ]}
                  estilo={styles.btn}
                  texto="+"
                >
                  +
                </ButtonComp>
                </div>
          </div>
        </FormGroup>

        <section className={styles.mainTable}>
        {
          (list.length > 0) &&
          <Table className={styles.table} size="sm">
          <thead>
            <tr className={styles.tableHeader}>
              <th className={styles.tableHidden}>
                <div className={styles.tableName}>ID</div>
              </th>
              <th>
              <div className={styles.tableName}>Destino</div></th>
              <th className={styles.tableHidden2}>
              <div className={styles.tableName}>Data</div>
              </th>
              <th className={styles.tableHidden2}>
              <div className={styles.tableName}>Hora</div>
              </th>
              <th>
              <div className={styles.tableName}>Ações</div>
              </th>
            </tr>
          </thead>
          <tbody display="flex" align="center">
          {list.map((item, index) => (
      
              <tr key={index+1}>
              <td width="10%" className={styles.tableHidden}>
                <div className={styles.numbers}>{index+1}</div>
              </td>
      
              <td width="30%">
                <div className={styles.valueTable}>{
                  
                    idInformado === '' ? item.local :
                    item.id === idInformado ?
                        editButton === false ? <Input onChange={(e) => setLocalAlter(e.target.value)} value={localAlter} className={styles.inputAlter} /> : item.local
                    : item.local
                }</div>
              </td>

              <td width="15%" className={styles.tableHidden2}>
                <div className={styles.valueTable}>{
                  idInformado === '' ? item.data:
                  item.id === idInformado ?
                      editButton === false ? <Input onChange={(e) => validaData(e.target.value)} className={styles.input} type="date" value={dataAlter}/> : item.data
                  : item.data
               
                }</div>
              </td>
              <td width="15%" className={styles.tableHidden2}>
                <div className={styles.valueTable}>{
                  idInformado === '' ? item.hora:
                  item.id === idInformado ?
                      editButton === false ? <Input onChange={(e) => validaHora3(e.target.value)} className={styles.input} type="time" value={horaAlter}/> : item.hora
                  : item.hora
               
                }</div>
              </td>

              <td width="25%">
                <div className={styles.itemsTD}>
                  
                   
                   { 
                    editButton === true?
                    item.id === idTeste ?
                     <ButtonComp  
                     acao={() => [pegaID(item.id)]}
                     estilo={styles.itemButtonEdit}
                     texto="Editar"
                   /> 
                   :
                   <ButtonComp  
                    acao={() => [pegaID(item.id)]}
                    estilo={styles.itemButtonEdit}
                    texto="Editar"
                  /> 
                   
                   :
                  item.id === idTeste?
               
                <ButtonComp 
                 acao={() => [pegaID(item.id)]}
                 estilo={styles.itemButtonEdit}
                 texto="Editar"
               />
                    
                 :
                <div></div>
                   }

                   {(saveButton === true && item.id === idInformado) &&
                      <ButtonComp
                      acao={() => [setSaveButton(false), setEditButton(true), handleAlterList(item.id), setCancelButton(false)]}
                      estilo={styles.itemButtonSave}
                      texto="Salvar"
                    />
                   }

                   {
                   editButton === true ?
                    <ButtonComp
                    acao={() => [handleRemoveList({item, index})]}
                    estilo={styles.itemButtonExc}
                    texto="Excluir"
                  /> : <div></div>
                }

                    {
                      cancelButton === true && item.id === idInformado ?
                      <ButtonComp
                      acao={() => [setCancelButton(false), setEditButton(true), setSaveButton(false)]}
                      estilo={styles.itemButtonExc}
                      texto="Cancelar"
                    /> : <div></div>
                    }
                </div>
              </td>

            </tr>

          ))}
          </tbody>
        </Table>
       
        }
        </section>

        {isOpen && (
          <ModalComp
            isOpen={isOpen}
            onClose={onClose}
            data={data}
            setData={setData}
            dataEdit={dataEdit}
            setDataEdit={setDataEdit}
            arrayList={list}
            handleSave={handleAlterList}
          />
        )}

        <section className={styles.modalList}>
            <Modal className={styles.modalEstilo} show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Viagem Cadastrada Com Sucesso!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className={styles.viagemEstilo}>
                 Código da Viagem: AV-{percursoID.id} 
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button color="danger" type="button" onClick={handleClose}>
                  CADASTRAR NOVA VIAGEM
                </Button>
                <Button color="success" type="button" onClick={handlePage}>
                  LISTA DE VIAGENS
                </Button>
              </Modal.Footer>
            </Modal>
        </section>

        <section className={styles.contentArea}>
          <h2>Observação:</h2>
          <C.Textarea className={styles.textArea} placeholder="Digite aqui" onChange={(e) => setDetails(e.target.value)} value={details}/>
          <ButtonComp acao={validaDados}   estilo={styles.contentAreaBtn} texto="Cadastrar" tipo="submit"/>
 
  
        </section>
      </main>

      { <Modal className={styles.modalEstilo} show={showHour} onHide={handleCloseHour}>
              <Modal.Header closeButton>
                <Modal.Title>Informe uma hora valida!</Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button color="danger" onClick={handleCloseHour}>
                  FECHAR
                </Button>
              </Modal.Footer>
            </Modal>
            }

  {<Modal className={styles.modalEstilo} show={showData} onHide={handleCloseData}>
                <Modal.Header closeButton>
                  <Modal.Title>Informe uma data valida!</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button color="danger" onClick={handleCloseData}>
                    FECHAR
                  </Button>
                </Modal.Footer>
              </Modal>
              }

{<Modal className={styles.modalEstilo} show={showParadas} onHide={handleCloseParadas}>
                <Modal.Header closeButton>
                  <Modal.Title>Informe os dados da parada/viagem!</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button color="danger" onClick={handleCloseParadas}>
                    FECHAR
                  </Button>
                </Modal.Footer>
              </Modal>
              }


    </section>
  );
}; 

export default CriarViagem;
