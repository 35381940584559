/* eslint-disable no-unused-vars */
import React, { useContext} from "react";
import styles from "./IniciarViagem.module.css"
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import MenuMotorista from "../../Components/Menu/MenuMotorista";
import { Switch } from '@chakra-ui/react' 
// import Table from 'react-bootstrap/Table';
import ButtonComp from "../../Components/Button/Button";
// import Input from "../../Components/Input/Input";
import Label from "../../Components/Label/Label";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";  
import Modal from "react-bootstrap/Modal";
import Loading from "../../Components/Loader/Loading";
import ButtonModal from "react-bootstrap/Button";
import { FormGroup, Input,  Button, Table } from "reactstrap";
import CodigoDaViagem from "../Viagem/CodigoViagem";
import {API_BASE_URL} from '../../services/Constantes';
import { IMaskInput } from "react-imask";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import estilo from "../Solicitante/SetorTabel.module.scss";
import EstadoMenu from "../../Components/Menu/EstadoMenu";

//Teste
const IniciarViagemComum = () => {

  useEffect(() => {
    document.title = 'Iniciar Viagem';
    setAtivadoMenu(false);
  }, []);

  // Contexto com o ID da Viagem
  const {codigo, setCodigo} = useContext(CodigoDaViagem);
  let cpf = localStorage.getItem("cpf")
  const [viagens, setViagens] = useState([]);
  const [local, setLocal] = useState(''); 
  const [status, setStatus] = useState(false); 
  const [data, setData] = useState('');
  const [estadoBotao, setEstadoBotao] = useState(false);
  const [kmFinal, setKmFinal] = useState('');
  const [loading, setLoading] = useState(false);
  const [kmInicial, setKmInicial] = useState('');
  const [horaFinal, setHoraFinal] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [kmAtual, setKmAtual] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [buttonEstado, setButtonEstado] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [day, setDay] = useState('');
  const [hour, setHour] = useState('');
  const [showData, setShowData] = useState(false);
  const [iniciaBotao, setIniciaBotao] = useState(false);
  const [closeB, setCloseB] = useState(false);
  const [saveButton, setSaveButton] = useState(false);
  const [finalizaButton, setFinalizaButton] = useState(true);
  const [finalizaButton2, setFinalizaButton2] = useState(true);
  const [closeButton, setCloseButton] = useState(false);
  const [checkButton, setCheckButton] = useState(false);
  const [dataFinal, setDataFinal] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [pontoInicial, setPontoInicial] = useState('');
  const [horaViagem, setHoraViagem] = useState('');
  const [dataViagem, setDataViagem] = useState('');
  const [motorista, setMotorista] = useState([]);
  const [nome, setNome] = useState('');
  const [contSave, setContSave] = useState(false);
  const [contFinal, setContFinal] = useState(false);
  const [vetorParadas, setVetorParadas] = useState([])
  let totalKM = 0;
  const {ativadoMenu, setAtivadoMenu} = useContext(EstadoMenu);
  const [showPagination, setShowPagination] = useState(true);
  const [idParada, setIdParada] = useState('');
  let paradas;
  let paradaID = parseInt(idParada);


    // Estado para o segundo Modal
    const [show, setShow] = useState(false);
    const [showHour, setShowHour] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const handleShowAlert = () => setShowAlert(true);
    const handleCloseAlert = () => setShowAlert(false);
    const handleCloseHour = () => setShowHour(false);
    const handleShowHour = () => setShowHour(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShowData = () => setShowData(true);
    const handleCloseData = () => setShowData(false);
    const [showKM, setShowKM] = useState(false);
    const handleShowKM = () => setShowKM(true);
    const handleCloseKM = () => setShowKM(false);
    const [modalFim, setModalFim] = useState(false);
    const handleShowModal = () => setModalFim(true);
    const handleCloseModal = () => setModalFim(false);

  // Funcionalidade de Páginação
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 5;
  const totalItems = (paradas)?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage); 

  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  const [applyFilter, setApplyFilter] = useState(false);
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    function pegaParada (id) {
      
      setIdParada(id);
      console.log(typeof(idParada));
      setFinalizaButton2(false);
      setCheckButton(true);
      setCloseButton(true);
     
    }

    function validaCheck(){
      setCloseButton(false);
      setCheckButton(false);
      setFinalizaButton2(true);
      setFinalizaButton(true);

    }

       // History
       let navigate = useNavigate();
       function previewsPage(){
             
              navigate('/motorista/listar-viagemComum')
       
       }


       console.log("Quilometragem inicial: "+kmAtual);
    // eslint-disable-next-line no-unused-vars
    function validaParada () {
      let dataAtual = new Date();
      let newDate = dataAtual.setDate(dataAtual.getDate());
      adicionaParada();
      setLocal('');
      setData(newDate);

    }

    function validaData(data) {
      let dataAtual = new Date();
      let dateFormatado = dataAtual.getFullYear() + '-' + ('0' + (dataAtual.getMonth()+1)).slice(-2) + '-' + ('0' + dataAtual.getDate()).slice(-2);
      let dataInformada = data;
      
      if (dataInformada >= dateFormatado) {
        setDataViagem(dataInformada);
        setData(dataInformada);
      } else {
        notify('Informe uma data válida');
      }
    }
    

    function validaHora (hora){
      let dataAtual = new Date();
      dataAtual.setDate(dataAtual.getDate());
      let dateFormatado = dataAtual.toLocaleDateString('en-CA');
      let horaAtual = new Date().toLocaleTimeString();
      let horaAtualFormatada = horaAtual.slice(0, -3);
      let horaInformada = hora;
      (horaInformada > horaAtualFormatada || horaInformada === horaAtualFormatada) ? setHour(horaInformada) : day > dateFormatado ? setHour(horaInformada) : handleShowHour();
      (horaInformada > horaAtualFormatada || horaInformada === horaAtualFormatada) ? setHoraFinal(horaInformada) : day > dateFormatado ? setHoraFinal(horaInformada) : handleShowHour();

      
    }
  
  // Requisição POST para adicionar parada
  const adicionaParada = async () => {
    setLoading(false);
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/transporte/viagemComum/adicionar-parada/${localStorage.getItem("idViagemAtual")}`,
        {
          method: "POST",
          body: JSON.stringify({
            "paradas": [
                {
                    "local": local,
                    "data_prevista": data,
                    "hora_prevista": hour,
                    "aguardar": status
                }
            ]
        }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            'token': localStorage.getItem("token"),
            'perfil': localStorage.getItem("tipo"),
          },
        }
      );
      if(response.ok) {
        const json = await response.json();
        notify4();
        setLoading(true);
        window.scroll(0, 0);
      } else {
        const json = await response.json();
        console.log(json.msg);
        setLoading(true)
      }
      setEstadoBotao(!estadoBotao)
    } catch (error) {
      console.log(error);
    };
  };
   
  

 // Requisição POST para iniciar Viagem
 const viagemIniciar = async () => {
  setLoading(false);
  setIniciaBotao(false);
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/transporte/viagemComum/iniciar/${localStorage.getItem("idViagemAtual")}`,
      {
        method: "POST",
        body: JSON.stringify({
          "km_inicial": kmInicial,
          "data_inicio": dataViagem,
          "hora_inicio": horaViagem,
      }), 
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'token': localStorage.getItem("token"),
          'perfil': localStorage.getItem("tipo"),
        },

      }
    );
    if(response.ok) {
      const json = await response.json();
      setContSave(true)
      setSaveButton(false);
      setLoading(true);
      window.scroll(0, 0);
      notify("Viagem Iniciada");
      
    } else {
      const json = await response.json();
      console.log(json.msg);
      setSaveButton(false);
      setLoading(true);
      notify5();
    }


  } catch (error) {
    console.log(error);
  };
};


  function postViagem (){
    if (kmInicial === '' || isNaN(kmInicial)) {
      handleShowKM();
    } else {
      viagemIniciar()

    }

    
  }

  // // Requisição POST para finalizar uma parada
  const finalizaParada = async () => {
    setLoading(false);
    window.scroll(0, 0);
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/transporte/viagemComum/parada/finalizar/${idParada}`,
        {
          method: "POST",
          body: JSON.stringify({
            "kmfinal": kmFinal,
            "data_final": dataFinal,
            "hora_final": horaFinal
        }), 
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            'token': localStorage.getItem("token"),
            'perfil': localStorage.getItem("tipo"),
          },
        }
      ); 
      if(response.ok){
        const json = await response.json();
        setContFinal(true);
        setLoading(true);
        notify2();
      } else {
        const json = await response.json();
        setContFinal(true);
        setLoading(true);
      }
     
    } catch (error) {
      console.log(error);
    };
  };


  // Requisição para finalizar Viagem
  const finalizaViagem = async () => {
    setLoading(false);
    setFinalizaButton(true);

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/transporte/viagemComum/finalizar/${localStorage.getItem("idViagemAtual")}`,
        {
          method: "POST",
          body: JSON.stringify({ 
            "kmfinal": kmFinal,
            "data_final": dataFinal,
            "hora_final": horaFinal
        }), 
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            'token': localStorage.getItem("token"),
            'perfil': localStorage.getItem("tipo"),
          },
        }
      ); if (response.ok){
        const json = await response.json();
        setContFinal(true);
        setLoading(true);
        handleShowModal();
        notify3();
      } else {
        const json = await response.json();
        setContFinal(true);
        setLoading(true);
        handleShowModal();
      }
 
      
    } catch (error) {
      console.log(error);
    };
  };


  function postParada (){
   
    kmFinal === '' || isNaN(kmFinal) ?
    handleShowKM() :
    finalizaParada();
    setCheckButton(false);
    setCloseButton(false);
    setFinalizaButton(true);
    setFinalizaButton2(true);


  }


  // Fazendo requisição GET para o id da Viagem
  useEffect(() => {

    fetch(`${API_BASE_URL}/api/transporte/viagemComum/buscarPorId/${localStorage.getItem("idViagemAtual")}`, {
        method: "GET",
        headers: { 
            "Content-type": "application/json;charset=UTF-8",
            'token': localStorage.getItem("token"),
            'perfil': localStorage.getItem("tipo"),
            'Authorization': `Bearer ${localStorage.getItem("token")}` 
        }
        }).then((response) => {
            return response.json();
          })
          .then((data) => {
            setViagens(data);
            setEstadoBotao(false);
            setLoading(true);
            setContSave(false);
            setContFinal(false);
            localStorage.setItem("status", data.status);
            
          })
          .catch((error) => {
            console.log(error)
          });
    
},[iniciaBotao, finalizaButton, loading])

    function pegaParadas (dado) {
      Object.keys(dado).forEach((item) => {
        item === 'paradas' ? paradas = dado[item]: console.log("Erro")
      })
    }

    function finalizarViagem(){
      setFinalizaButton(false)
    }

    const formatDataAtual = (data) => {
      var date = new Date(data),
      dia  = date.getDate().toString(),
      diaF = (dia.length === 1) ? '0'+dia : dia,
      mes  = (date.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length === 1) ? '0'+mes : mes,
      anoF = date.getFullYear();
     return diaF+"/"+mesF+"/"+anoF;
     }

     const formatHoraAtual = (dado) => {
   
      let formatHora = String (dado)
      return formatHora.replace(':00', '');
     }
    
    pegaParadas(viagens)
    
    useEffect(() => {
      setVetorParadas(viagens.paradas);
      console.log(vetorParadas)
    }, [viagens])

    useEffect(() => {
      // Defautl Data

      let horaAtual = new Date().toLocaleTimeString();
      setHour(horaAtual.slice(0, -3))
      let dataAtual = new Date();
      let dateFormatado = dataAtual.getFullYear() + '-' + ('0' + (dataAtual.getMonth()+1)).slice(-2) + '-' + ('0' + dataAtual.getDate()).slice(-2);
  
      setData(dateFormatado);
      setHoraFinal(dateFormatado);
      setDataFinal(dateFormatado);
      setHoraFinal(horaAtual.slice(0, -3));
      setPontoInicial(viagens.ponto_origem);
      setHoraViagem(horaAtual.slice(0, -3));
      setDataViagem(dateFormatado)
    }, [viagens.ponto_origem])


    const notify = (msg) => toast(msg);
   const notify2 = () => toast("Parada Finalizada!");
   const notify3 = () => toast("Viagem encerrada!");
   const notify4 = () => toast("Parada adicionada!");
   const notify5 = () => toast("Você já tem viagens em andamento!");



   useEffect(() => {
    const intervalId = setInterval(() => {
      let dataAtual = new Date();
      let horaAtual = dataAtual.toLocaleTimeString().slice(0, -3);
      setHoraFinal(horaAtual);
      dataAtual.setDate(dataAtual.getDate());
      let dateFormatado = dataAtual.toLocaleDateString('en-CA');
      setData(dateFormatado);
    }, 1000);
  
    return () => clearInterval(intervalId);
  });

  const tamanhoList = vetorParadas ? vetorParadas.length : 0;
  
  return (
    <section className={styles.avulsaViagem}>
       <Header titulo="Iniciar Viagem Comum" altura="240px"/>
       {
            localStorage.getItem("tipo") === 'gestor' ?
            <Menu />
            :
            <MenuMotorista />
           }

      <ToastContainer />
      <main className={
        ativadoMenu === true ? styles.mainContent2 : styles.mainContent
      }>
                  

                    <section className={styles.campoViagem}>
                        <div className={styles.viagemBloco1}>
                            <h1 className={styles.viagemTitle}>Dados da Viagem</h1>
                            <div className={styles.viagemBloco3}>
                              <div className={styles.blocoInputDetails}>
                                  <Label estilo={styles.labelBlocos} texto="Origem:"/>
                                  <Label texto={viagens.ponto_origem} />
                                
                              </div>

                              <div className={styles.blocoInputDetails}>
                                  <Label estilo={styles.labelBlocos} texto="Motorista:"/>
                                  <Label texto={localStorage.getItem("nome")}/>
                                
                              </div>

                              <div className={styles.contentArea}>
                                <h3 className={styles.labelBlocos}>Observação:</h3>
                                <div className={styles.textArea}>{viagens['observacao']}</div>
                            </div>
                            </div>
                        </div>

                       

                    
                      
                    </section>

                    <section className={styles.campoIntinerario}>

                    {
                    viagens.status === 'EM_ANDAMENTO' || viagens.status === 'CRIADA' ?
                      <div className={styles.contentParadas}>
                          <h2 className={styles.viagemTitleParadas}>Parada não Planejada:</h2>
                          <div className={styles.viagemBloco2}>

                              <div className={styles.formItems}>
                                        <div className={styles.formGroup}>
                                                <span>Local</span>
                                                <IMaskInput className={styles.formField} name="parada"   onChange={(e) => setLocal(e.target.value)} value={local} type="text" placeholder="EGMA - Escola de Governo do Maranhão"/>
                                        </div>
                              </div>

                              <div className={styles.formItems}>
                                <div className={styles.formGroup2}>
                                  <span>Data</span>
                                  <IMaskInput
                                    className={styles.formField}
                                    name="data"
                                    onChange={(e) => validaData(e.target.value)}
                                    value={data}
                                    required
                                    type="date"
                                  />
                                </div>
                              </div>

                              <div className={styles.formItems}>
                                        <div className={styles.formGroup2}>
                                                <span>Hora</span>
                                                <IMaskInput className={styles.formField} name="hora"   onChange={(e) => validaHora(e.target.value)} value={horaFinal} required type="time" />
                                        </div>
                              </div>

                              <div className={styles.formItems}>
                                
                                <Switch value={status} onClick={() => setStatus(!status)} colorScheme='green' size='md'></Switch>
                              </div>

                              <div className={styles.formItems}>
                                      <ButtonComp acao={adicionaParada} estilo={styles.btn}  valor={estadoBotao}  texto="+" />
                              </div>
                          </div>          
                       </div>
                      : "" }
                   <FormGroup className={styles.formIniciar}>
                       {
                          viagens.status === "CRIADA" ?
                         <h3 className={styles.viagemTitle2}>Iniciar Viagem</h3> 
                         : viagens.status === 'EM_ANDAMENTO' ?
                         <h3 className={styles.viagemTitle2}>Finalizar Viagem</h3> 
                         :
                          viagens.status === 'FINALIZADA' ?
                         <h3 className={styles.viagemTitle2}>Viagem Concluida</h3> :
                         <h3 className={styles.viagemTitle2}>Finalizar Viagem</h3>
                       }
                      
                      {
                        
  
                    <section className={styles.mainTable}>
                        <div className={styles.blocoSetor} id="tabelaViagens">
                             {
                               <div>
                                 <ul className={estilo.responsiveTable}>
                                   <li className={estilo.tableHeader}>
                                     <div className={estilo.col} id={estilo.col1}>
                                       ID
                                     </div>
                                     <div className={estilo.col} id={estilo.col2}>
                                       Data
                                     </div>
                                     <div className={estilo.col} id={estilo.col2}>
                                       Hora
                                     </div>
                                     <div className={estilo.col} id={estilo.col2}>
                                       {
                                        viagens.status === "CRIADA" ? 
                                        'KM Inicial' : 'KM Final'
                                       }
                                     </div>
                                     <div className={estilo.col} id={estilo.col2}>
                                       Opções
                                     </div>
                                   </li>
                                   { 
                                     <li className={estilo.tableRow}>
                                       <div className={estilo.col} id={estilo.col1} data-label="ID">
                                         VA - 1
                                       </div>
                                       <div className={estilo.col} id={estilo.col2} data-label="Data">
                                              {
                                            localStorage.getItem("status") !== 'FINALIZADA' ?
                                            iniciaBotao === true  ? <Input type="date" onChange={(e) => validaData(e.target.value)} value={dataViagem} className={styles.inputViagem}/> :
                                          
                                            <div className={styles.tableTextB}>
                                                <p>{ formatDataAtual(viagens.data_prevista)}</p>
                                            </div>
                                            : <div className={styles.tableTextB}>
                                                <p>{ formatDataAtual(viagens.data_prevista)}</p>
                                            </div>
                                          
                                          }
                                       </div>
                                       <div className={estilo.col} id={estilo.col2} data-label="Hora">
                                              {
                                            localStorage.getItem("status") !== 'FINALIZADA' ?
                                            iniciaBotao === true ? <Input type="time" onChange={(e) => setHoraViagem(e.target.value)} value={horaViagem} className={styles.inputViagem}/> :
                                            
                                            <div className={styles.tableTextB}>
                                                <p>{ formatHoraAtual(viagens.hora_prevista)}</p>
                                            </div>
                                            :    <div className={styles.tableTextB}>
                                                    <p>{ formatHoraAtual(viagens.hora_prevista)}</p>
                                                </div>
                                        
                                          }
                                       </div>
                                       <div className={estilo.col} id={estilo.col2} data-label="KM Inicial">
                                            { 
                                            viagens.km_inicial === null ? 
                                            iniciaBotao === true  ? <Input placeholder="Informe o KM Inicial" onChange={(e) => setKmInicial(e.target.value)} value={kmInicial} className={styles.inputViagem}/> :
                                            <div className={styles.tableTextB}>
                                                  <p>00 KM</p>
                                              </div>
                                            : 
                                            <div className={styles.tableTextB}>
                                              <p>{viagens.km_inicial}</p>
                                            </div>
                                          }
                                       </div>

                                       <div className={estilo.col} id={estilo.col2} data-label="Opções">
                                            
                                            { 
                                            localStorage.getItem("status") === 'CRIADA' ? 
                                            iniciaBotao === false ?
                                            <div className={styles.divButton}>
                                              <ButtonComp valor={iniciaBotao} acao={() => [setIniciaBotao(true), setSaveButton(true), setCloseB(true)]}   estilo={styles.buttonInicia} texto="Iniciar" /> 
                                            </div> 
                                            : saveButton === true && closeB === true ?
                                            <div className={styles.divButton}>
                                              <ButtonComp valor={saveButton} acao={postViagem}   estilo={styles.buttonInicia} texto="Salvar" />
                                              <ButtonComp valor={closeB} acao={() => [setSaveButton(false), setCloseB(false), setIniciaBotao(false)]}  estilo={styles.buttonClose} texto="Fechar" />
                                            </div> 
                                            : console.log("Teste")
                                            :
                                            localStorage.getItem("status") === 'EM_ANDAMENTO' ?
                                            <div className={styles.divButton}>
                                            <ButtonComp estilo={styles.buttonInicia} texto="Iniciada" /></div>
                                          
                                            :
                                            <div className={styles.divButton}>
                                            <ButtonComp estilo={styles.buttonClose2} texto="Finalizada" /></div> 
                                          } 
                                        
                              
                                      </div>
                                     </li>
                                   }
                                   {viagens.status === 'EM_ANDAMENTO' ? 
                                   

                                    <li className={estilo.tableRow}>
                                    <div className={estilo.col} id={estilo.col1} data-label="ID">
                                      FA - 1
                                    </div>
                                
                                    <div className={`${estilo.col} ${styles.alterTable}`} id={estilo.col2} data-label="Data">
                                        {
                                        viagens.status !== 'FINALIZADA' ?
                                        finalizaButton === false  ? <Input type="date" onChange={(e) => validaData(e.target.value)} value={dataViagem} className={styles.inputViagem}/> :
                                      
                                        <div className={styles.tableTextB}>
                                            <p>{ formatDataAtual(viagens.data_prevista)}</p>
                                        </div>
                                        : <div className={styles.tableTextB}>
                                            <p>{ formatDataAtual(viagens.data_prevista)}</p>
                                        </div>
                                      
                                      }
                                    </div>
                                    <div className={estilo.col} id={estilo.col2} data-label="Hora">
                                    {
                                    viagens.status !== 'FINALIZADA' ?
                                    finalizaButton === false ? <Input type="time" onChang={(e) => setHoraViagem(e.target.value)} value={horaViagem} className={styles.inputViagem}/> :
                                    
                                    <div className={styles.tableTextB}>
                                        <p>{ formatHoraAtual(viagens.hora_prevista)}</p>
                                    </div>
                                    :    <div className={styles.tableTextB}>
                                            <p>{ formatHoraAtual(viagens.hora_prevista)}</p>
                                        </div>
                                 
                                  }
                                    </div>
                                    <div className={estilo.col} id={estilo.col2} data-label="KM Inicial">
                                          {
                                      viagens.kmFinal === null ? 
                                      <div className={styles.tableTextB}>
                                        <p>00 KM</p>
                                    </div>
                                      : 
                                      finalizaButton === false ?
                                      <Input className={styles.inputParadas} onChange={(e) => setKmFinal(e.target.value)} value={kmFinal} placeholder="Informe o KM Final"/>:
                                      viagens.kmfinal !== null ?
                                      
                                        <div className={styles.tableTextB}>
                                        <p>{viagens.kmfinal} KM</p>
                                      </div>
                                      :
                                    

                                      <div className={styles.tableTextB}>
                                        <p>00 KM</p>
                                        </div>
                                      }
                                    </div>

                                    <div className={estilo.col} id={estilo.col2} data-label="Opções">
                                         
                                    {
                                  viagens.status === 'CRIADA' ?  
                                  <div className={styles.divButton}>
                                  <button id="buttonInicia3" className={styles.buttonClose}>Encerrar</button>
                                </div> :
                                              viagens.km_final === null  ?
                                              <div className={styles.divButton}>
                                                  { finalizaButton === true ?
                                                    <div className={styles.divButton}>
                                                    <button value={finalizaButton} onClick={finalizarViagem} id="buttonInicia3" className={styles.buttonClose}>Encerrar</button>
                                                  </div>
                                                  :
                                                  <div></div>
                                       
                                                  }
            
                                                  {finalizaButton === false ?
                                                    <svg onClick={finalizaViagem} className={styles.iniciaBotao2} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                    width="30" height="30"
                                                    viewBox="0 0 26 26">
                                                    <path d="M 13 1 C 6.382813 1 1 6.382813 1 13 C 1 19.617188 6.382813 25 13 25 C 19.617188 25 25 19.617188 25 13 C 25 6.382813 19.617188 1 13 1 Z M 13 3 C 18.535156 3 23 7.464844 23 13 C 23 18.535156 18.535156 23 13 23 C 7.464844 23 3 18.535156 3 13 C 3 7.464844 7.464844 3 13 3 Z M 17.1875 7.0625 C 17.039063 7.085938 16.914063 7.164063 16.8125 7.3125 L 11.90625 14.59375 L 9.59375 12.3125 C 9.394531 12.011719 9.011719 11.988281 8.8125 12.1875 L 7.90625 13.09375 C 7.707031 13.394531 7.707031 13.800781 7.90625 14 L 11.40625 17.5 C 11.605469 17.601563 11.886719 17.8125 12.1875 17.8125 C 12.386719 17.8125 12.707031 17.707031 12.90625 17.40625 L 18.90625 8.59375 C 19.105469 8.292969 18.992188 8.011719 18.59375 7.8125 L 17.59375 7.09375 C 17.492188 7.042969 17.335938 7.039063 17.1875 7.0625 Z"></path>
                                                   </svg>
                                                  : 
                                                    console.log("Teste")
                                                  }
            
                                                  {finalizaButton === false?
                                                    <svg onClick={validaCheck}  className={styles.iniciaBotao2} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                    width="32" height="32"
                                                    viewBox="0 0 24 24">
                                                    <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 8.7070312 7.2929688 L 7.2929688 8.7070312 L 10.585938 12 L 7.2929688 15.292969 L 8.7070312 16.707031 L 12 13.414062 L 15.292969 16.707031 L 16.707031 15.292969 L 13.414062 12 L 16.707031 8.7070312 L 15.292969 7.2929688 L 12 10.585938 L 8.7070312 7.2929688 z"></path>
                                                  </svg>
                                                  :
                                                  console.log("Teste")
                                                    }
            
            
                                                
                                              </div> : console.log("Teste")
                                }
                           
                                   </div>
                                  </li>
                                  : console.log("Teste")
                                   }
                                 </ul>

                               </div>
                              }
                        </div>
                     </section>

                      }

                    </FormGroup>
                    
                    <FormGroup className={styles.formIniciar}>

                                <h3 className={styles.viagemTitle2}>Lista de Paradas</h3> 
                                  <section className={styles.mainTable}>
                                  <div className={styles.blocoSetor} id="tabelaViagens">
                                      {
                                        <div>
                                          <ul className={estilo.responsiveTable}>
                                            <li className={estilo.tableHeader}>
                                              <div className={`${estilo.col} ${estilo.blocoID}`} id={estilo.col1}>
                                                ID
                                              </div>
                                              <div className={estilo.col} id={estilo.col2}>
                                                Local
                                              </div>
                                              <div className={estilo.col} id={estilo.col2}>
                                                Data
                                              </div>
                                              <div className={estilo.col} id={estilo.col2}>
                                                Hora
                                              </div>
                                              <div className={estilo.col} id={estilo.col2}>
                                                KM Final
                                              </div>
                                              { viagens.status === 'EM_ANDAMENTO' ?
                                                  <div className={estilo.col} id={estilo.col3}>
                                                      Opções
                                                    </div>
                                                    : <div></div>
                                                }
                                            </li>
                                            { (paradas?.sort((a, b) => a.id - b.id) || []).map((item, index) => (

                                        
                                              <li className={estilo.tableRow} key={index}>
                                                <div className={`${estilo.col} ${estilo.blocoID}`} id={estilo.col1} data-label="ID">
                                                  {item.id}
                                                </div>
                                                <div className={estilo.col} id={estilo.col2} data-label="Local">
                                                      {item.local}
                                                </div>
                                                <div className={`${estilo.col} ${styles.alterTable}`} id={estilo.col2} data-label="Data">
                                                              {
                                                                        
                                                                        item.data_prevista === null && finalizaButton2 === true ? "__/__/____ " :
                                                                        item.id === idParada && finalizaButton2 === false ?
                                                                        <Input type="date" className={styles.inputParadas} onChange={(e) => validaData(e.target.value)} value={dataFinal}/> :
                                                                        
                                                                      
                                                                            <div className={styles.tableTextB}>
                                                                              {formatDataAtual(item.data_prevista)}
                                                                            </div>
                                                          
                                                                        }
                                                </div>
                                                <div className={estilo.col} id={estilo.col2} data-label="Hora">
                                                          {
                                                                            item.kmFinal === null ? <p>__:__</p> : 
                                                                            item.id === idParada && finalizaButton2 === false ?
                                                                            <Input type="time" className={styles.inputParadas} onChange={(e) => setHoraFinal(e.target.value)} value={horaFinal}/>:
                                                                          
                                                                            item.hora_final === null ? 
                                                                            
                                                                                <div className={styles.tableTextB}>
                                                                                  <p>__:__</p>
                                                                                </div>
                                                                              : 
                                                                              <div className={styles.tableTextB}>
                                                                                {formatHoraAtual(item.hora_final)}
                                                                              </div>
                                                                            }
                                                </div>
                                                <div className={estilo.col} id={estilo.col2} data-label="KM Final">
                                                              {
                                                                            item.kmFinal === null ? <p>00 KM</p> : 
                                                                            item.id === idParada && finalizaButton2 === false ?
                                                                            <Input className={styles.inputParadas} onChange={(e) => setKmFinal(e.target.value)} value={kmFinal} placeholder="Informe o KM Final"/>:
                                                                            item.kmfinal !== null ?
                                                                            <div className={styles.tableTextB}>
                                                                              <p>{item.kmfinal} KM</p>
                                                                            </div>
                                                                            :
                                                                            <div className={styles.tableTextB}>
                                                                              <p>00 KM</p>
                                                                            </div>
                                                                            
                                                                            }
                                                </div>
                                                { viagens.status === 'EM_ANDAMENTO' ?
                                
                               
                                                <div className={estilo.col} id={estilo.col3} data-label="Opções">
                                                      
                                                      { 
                                                          viagens.status === 'EM_ANDAMENTO' ?
                                                      
                                                            viagens.status === 'CRIADA' || viagens.status === "FINALIZADA" ? 
                                                            <div className={styles.divButton}>
                                                            <ButtonComp texto="Finalizar" id="buttonInicia3" estilo={styles.buttonCloseDisable}/>
                                                          
                                                          </div>  :

                                                          finalizaButton2 === false && item.id !== idParada && item.kmfinal === null?
                                                          <div className={styles.divButton}>
                                                            <ButtonComp id="buttonInicia3" estilo={styles.buttonClose} texto="Finalizar" />
                                                          
                                                          </div>
                                                          :
                                                          item.kmfinal === null  ?
                                                          <div className={styles.divButton}>
                                                              { finalizaButton2 === true ?
                                                                <div className={styles.divButton}>
                                                                <ButtonComp valor={finalizaButton2} acao={() => pegaParada(item.id)} id="buttonInicia3" estilo={styles.buttonClose} texto="Finalizar" />
                                                              </div>
                                                              : item.id === idParada ?
                                                              console.log("Teste") :
                                                              item.is_ultima_parada === true ?  <p className={styles.tableTextC}>Finalizar</p> :
                                                              <svg value={finalizaButton2} onClick={() => pegaParada(item.id)} className={styles.iniciaBotao2} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                width="32" height="32"
                                                                viewBox="0 0 32 32">
                                                                <path d="M 16 3 C 8.832031 3 3 8.832031 3 16 C 3 23.167969 8.832031 29 16 29 C 23.167969 29 29 23.167969 29 16 C 29 8.832031 23.167969 3 16 3 Z M 16 5 C 22.085938 5 27 9.914063 27 16 C 27 22.085938 22.085938 27 16 27 C 9.914063 27 5 22.085938 5 16 C 5 9.914063 9.914063 5 16 5 Z M 15 10 L 15 15 L 10 15 L 10 17 L 15 17 L 15 22 L 17 22 L 17 17 L 22 17 L 22 15 L 17 15 L 17 10 Z"></path>
                                                              </svg>
                                                              }

                                                              {checkButton === true && item.id === idParada ?
                                                                <svg onClick={postParada} className={styles.iniciaBotao2} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                width="30" height="30"
                                                                viewBox="0 0 26 26">
                                                                <path d="M 13 1 C 6.382813 1 1 6.382813 1 13 C 1 19.617188 6.382813 25 13 25 C 19.617188 25 25 19.617188 25 13 C 25 6.382813 19.617188 1 13 1 Z M 13 3 C 18.535156 3 23 7.464844 23 13 C 23 18.535156 18.535156 23 13 23 C 7.464844 23 3 18.535156 3 13 C 3 7.464844 7.464844 3 13 3 Z M 17.1875 7.0625 C 17.039063 7.085938 16.914063 7.164063 16.8125 7.3125 L 11.90625 14.59375 L 9.59375 12.3125 C 9.394531 12.011719 9.011719 11.988281 8.8125 12.1875 L 7.90625 13.09375 C 7.707031 13.394531 7.707031 13.800781 7.90625 14 L 11.40625 17.5 C 11.605469 17.601563 11.886719 17.8125 12.1875 17.8125 C 12.386719 17.8125 12.707031 17.707031 12.90625 17.40625 L 18.90625 8.59375 C 19.105469 8.292969 18.992188 8.011719 18.59375 7.8125 L 17.59375 7.09375 C 17.492188 7.042969 17.335938 7.039063 17.1875 7.0625 Z"></path>
                                                              </svg>
                                                              : 
                                                                console.log("Teste")
                                                              }

                                                              {closeButton === true && item.id === idParada ?
                                                                <svg onClick={validaCheck}  className={styles.iniciaBotao2} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                                width="32" height="32"
                                                                viewBox="0 0 24 24">
                                                                <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 8.7070312 7.2929688 L 7.2929688 8.7070312 L 10.585938 12 L 7.2929688 15.292969 L 8.7070312 16.707031 L 12 13.414062 L 15.292969 16.707031 L 16.707031 15.292969 L 13.414062 12 L 16.707031 8.7070312 L 15.292969 7.2929688 L 12 10.585938 L 8.7070312 7.2929688 z"></path>
                                                              </svg>
                                                              :
                                                              console.log("Teste")
                                                                }

              
                                                          
                                                          </div> 
                                                          :
                                                          
                                                          <div className={styles.divButton}>
                                                          <ButtonComp estilo={styles.buttonInicia} texto="Concluida" /></div> 
                                                            
                                                    
                                                          : <div></div>
                                                        }

                                                  
                                        
                                                </div>
                                              : <div></div>
                                                      }
                                              </li>
                                                    ))
                                            }
                                            
                                          
                                          </ul>

                                        </div>
                                        }
                                  </div>
  </section>
                    </FormGroup>

                    <div className={styles.blocoFinal}>
                      <ButtonComp acao={() => previewsPage()} estilo={styles.buttonAdd} texto="Voltar"/> 
                    </div>
                    </section>

                  
      </main>

      
  {<Modal className={styles.modalEstilo} show={showData} onHide={handleCloseData}>
                <Modal.Header closeButton>
                  <Modal.Title>Informe uma data valida!</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <ButtonModal variant="danger" onClick={handleCloseData}>
                    FECHAR
                  </ButtonModal>
                </Modal.Footer>
              </Modal>
              }

{<Modal className={styles.modalEstilo} show={showKM} onHide={handleCloseKM}>
                <Modal.Header closeButton>
                  <Modal.Title>Informe um KM final para a parada!</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <ButtonModal variant="danger" onClick={handleCloseKM}>
                    FECHAR
                  </ButtonModal>
                </Modal.Footer>
              </Modal>
              }

{<Modal className={styles.modalEstilo} show={modalFim} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Viagem encerrada com sucesso!</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <ButtonModal variant="danger" onClick={previewsPage}>
                    SAIR
                  </ButtonModal>
                </Modal.Footer>
              </Modal>
              }

{<Modal className={styles.modalEstilo} show={showAlert} onHide={handleCloseAlert}>
                <Modal.Header closeButton>
                  <Modal.Title>Você possui viagens em andamento!</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <ButtonModal variant="danger" onClick={handleCloseAlert}>
                    SAIR
                  </ButtonModal>
                </Modal.Footer>
              </Modal> 
              }


              
    
    {loading === false ?
                
                <div className={styles.loader}>
                            <div className={styles.loaderInterno}>
                              <Loading />
                            </div>
                </div>
                :
                <div></div>
                }
    </section>
  );
};

export default IniciarViagemComum;