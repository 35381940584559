import React, { useState, useEffect } from "react";
import styles from "../Solicitante/CadastroSolicitante.module.css";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/Menu/Menu";
import MenuSolicitante from "../../Components/Menu/MenuSolicitante";
import MenuMotorista from "../../Components/Menu/MenuMotorista";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { FormGroup, Form, Input, Row, Col, Button } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Table from "react-bootstrap/Table";
import { IMaskInput } from "react-imask";
import ButtonComp from "../../Components/Button/Button";
import {API_BASE_URL} from '../../services/Constantes';
import Loading from "../../Components/Loader/Loading";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

const EditarPassageiro = () => {

    useEffect(() => {
        document.title = 'Editar Passageiro';
        window.scrollTo(0, 0);
      }, []);
    // Estados para os inputs
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [Cpf, setCpf] = useState('');
    const [login, setLogin] = useState('');
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [setor, setSetor] = useState([]);
    const [setorAlter, setSetorAlter] = useState('');
    const [passageiro, setPassageiro] = useState([]);
    const [setorAtual, setSetorAtual] = useState('');
    const [setorSelecionado, setSetorSelecionado] = useState(null);
    let setorFinal = 0;
    const [telefone, setTelefone] = useState('');
    const [categoria, setCategoria] = useState('');
    const [list, setList] = useState([]);
    const [alter, setAlter] = useState(true);
    const [close, setClose] = useState(false);
    const [save, setSave] = useState(false);
    const [del, setDel] = useState(true);
    const [loading, setLoading] = useState(true);
    const [editButton, setEditbutton] = useState(true);
    const [idAtual, setIdAtual] = useState('');
    const [idSetor, setIDSetor] = useState('');
    let tipoUsuario = localStorage.getItem("tipo");
    let idPassageiro = localStorage.getItem("idPassageiro");

    const notify = (msg) => toast(msg);


      useEffect(() => {
        setLoading(false);
        window.scrollTo(0, 0); 
        fetch(`${API_BASE_URL}/api/transporte/passageiro/${idPassageiro}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                'token': localStorage.getItem("token"),
                'perfil': localStorage.getItem("tipo"),
                'Authorization': `Bearer ${localStorage.getItem("token")}` 
            }
            }).then((response) => {
                return response.json();
              })
              .then((data) => {
                setPassageiro(data);
                setLoading(true);
               
              })
              .catch((error) => {
                console.log(error)
                setLoading(true);
              }); 

    },[]);

    

    // PUT Passageiro
    const postAlter = async () => {
      setLoading(false);
      window.scrollTo(0, 0);
      try {
        const response2 = await fetch(
          `${API_BASE_URL}/api/transporte/passageiro/${idPassageiro}`,
          {
            method: "PUT",
            body: JSON.stringify({ 
              "nome": nome,
              "email": email,
              "telefone": telefone,
          }), 
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              'token': localStorage.getItem("token"),
              'perfil': localStorage.getItem("tipo"),

            },
          }
        );
        const json2 = await response2.json();
       console.log(json2);
       setLoading(true);
       notify("Passageiro editado com sucesso!");
      } catch (error) {
        console.log(error);
        notify(error);
        setLoading(true);
        notify("Não foi possível atualizar o passageiro!");
      };
    }



    useEffect(() => {
        setEmail(passageiro.email);
        setNome(passageiro.nome);
        setTelefone(passageiro.telefone);
    }, [passageiro])

       // History 
       let editar = useNavigate();
   
   
        function previewsPage() {
           editar('/gestor/listar-passageiros');
           
          
        }
    return (
        <section className={styles.cadastro}>
            <Header titulo="Editar Passageiro" altura="240px"/>
            {
              tipoUsuario === 'gestor' ?
              <Menu /> :
              tipoUsuario === 'motorista' ?
              <MenuMotorista /> :
              <MenuSolicitante />
            }
            <ToastContainer />
            <main className={styles.mainContent}>
                <form className={styles.form}>
                    <FormGroup className={styles.formBlock}>
                            
                            <div>
                                <h2>Dados do Passageiro</h2>
                            </div>

                            <div className={styles.formComponents}>
                                    <div className={styles.formItems}>
                                        <div className={styles.formGroup}>
                                                <span>Nome</span>
                                                <IMaskInput className={styles.formField}  name="nome"   onChange={(e) => setNome(e.target.value)} value={nome} type="text" placeholder="Informe o nome do solicitante"/>
                                         </div>
                                            {/* <p className={styles.messagemErro}>{errors.cnh?.message}</p> */}
                                    </div>

                                    <div className={styles.formItems}>
                                        <div className={styles.formGroup}>
                                                <span>Email</span>
                                                <IMaskInput className={styles.formField} name="email"  onChange={(e) => setEmail(e.target.value)} value={email} type="text"  placeholder="email@gmail.com"/>
                                        </div>
                                        {/* <p className={styles.messagemErro}>{errors.nome?.message}</p> */}
                                     </div>

                                     <div className={styles.formItems}>
                                        <div className={styles.formGroup}>
                                                <span>Telefone</span>
                                                <IMaskInput className={styles.formField}  name="telefone"  mask="(00) 00000-0000" placeholder="(XX) X XXXX XXXX" required="" data-js="phone"  onChange={(e) => setTelefone(e.target.value)} value={telefone} type="tel"/>
                                        </div>
                                        {/* <p className={styles.messagemErro}>{errors.cnh?.message}</p> */}
                                    </div>

                                   
                            </div>

                            <div className={styles.blocoButton2}>
                                        <ButtonComp acao={() =>  previewsPage()} texto="Voltar" estilo={styles.formButton} tipo="button"/>
                                        <ButtonComp acao={() => postAlter()} texto="Salvar" estilo={styles.formButton} tipo="button"/>
                                    </div>
            
                    </FormGroup> 



                </form>
            </main>

            {loading === false ?
                
                <div className={styles.loader}>
                            <div className={styles.loaderInterno}>
                              <Loading />
                            </div>
                </div>
                :
                <div></div>
                }

        </section>
    );
};

export default EditarPassageiro;